<template>
  <div class="my-1">
    <v-card flat :key="documentUpdateKey">
      <v-card-text v-if="item.originCountryId && item.destinationCountryId" :key="documentUpdateKey">
        <v-row justify="end" class="mb-2">
          <v-col cols="12" md="6" class="text-right">
            <span v-if="!getFilterProduct">**Please filter a specific product to see the relevant applicable
              documents</span>
            <v-chip v-if="getFilterProduct">Active Filter: <v-icon>filter_alt</v-icon>{{ getFilterProduct.name }} <v-icon
                small @click="filterProduct = null, item.filterProductId = null, getDocuments()">close</v-icon></v-chip>
          </v-col>
          <v-col cols="12" md="4">
            <el-input ref="searchBar" suffix-icon="el-icon-search" v-model="search" clearable placeholder="Search"
              id="search-documents">
              <template slot="prepend">
                <el-button @click="addManualDocument()" id="add-documents"><i class="el-icon-plus"></i></el-button>
              </template>
              <template slot="append">
                <el-button @click="filterDialog = true" el-tooltip="Advanced Filter" id="filter-documents">
                  <v-icon>filter_alt</v-icon>
                </el-button>
              </template>
            </el-input>
          </v-col>
        </v-row>
        <v-data-table id="documents-table" :key="documentUpdateKey" :items="filterDocuments" :headers="headers"
          :search="search" :loading="loading" disable-pagination hide-default-footer fixed-header flat dense height="70vh"
          @click:row="adjustSettings" class="elevation-0" :footer-props="{ 'items-per-page-options': [30, 50, 100] }"
          style="cursor: pointer;box-shadow: none !important;">
          <template v-slot:[`item.name`]="{ item }">
            <!-- <span v-if="manuallyAdded(item)">Manual</span> -->
            <v-row>
              <v-tooltip top v-if="manuallyAdded(item)" :key="documentUpdateKey">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small color="blue">verified</v-icon>
                </template>
                <span style="font-size: 12px">Force Document Inclusion</span>
              </v-tooltip>
              <v-tooltip top v-if="documentIgnored(item)" :key="documentUpdateKey">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small color="red">cancel</v-icon>
                </template>
                <span style="font-size: 12px">Ignore this document for shipments</span>
              </v-tooltip>
              <v-tooltip top v-if="attest(item)" :key="documentUpdateKey">
                <template v-slot:activator="{ on }">
                  <v-icon small color="orange" v-on="on">rule</v-icon>
                </template>
                <span style="font-size: 12px">
                  Attest
                </span>
              </v-tooltip>
              {{ item.name }}
              <v-tooltip top v-if="attestRequired(item)" :key="documentUpdateKey">
                <template v-slot:activator="{ on, attrs }">
                  <i v-bind="attrs" v-on="on" style="color: red">(Attest Required)</i>
                </template>
                <span style="font-size: 12px">
                  {{ attestRequiredFiles(item) }}
                </span>
              </v-tooltip>
            </v-row>
          </template>
          <template v-slot:[`item.regulatoryBody.name`]="{ item }">
            <v-chip v-if="item.regulatoryBody" pill outlined small>
              <v-avatar left size="32">
                <v-img contain :src="item.regulatoryBody.logo"></v-img>
              </v-avatar>
              {{ item.regulatoryBody.name }}
            </v-chip>
          </template>
          <template v-slot:[`item.responsiblePartyType`]="{ item }">
            <v-tooltip top v-if="findResponsibleParty(item)" :key="documentUpdateKey">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" icon outlined small class="mx-1" v-on="on">
                  <v-icon small>{{
                    getIcon(findResponsibleParty(item))
                  }}</v-icon>
                </v-btn>
              </template>
              <span style="font-size: 12px; text-transform: capitalize">{{
                findResponsibleParty(item)
              }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.quantityType`]="{ item }">
            <v-chip outlined small class="primaryText--text" :key="documentUpdateKey"
              v-if="findQuantityType(item).quantityType == 'Shipment'">
              <v-icon left>directions_boat</v-icon>
              {{ findQuantityType(item).quantityType }}
            </v-chip>
            <v-chip outlined small class="primaryText--text"
              v-else-if="findQuantityType(item).quantityType == 'Container'">
              <v-icon left color="orange">widgets</v-icon>
              {{ findQuantityType(item).quantityType }}
            </v-chip>
            <v-chip outlined small class="primaryText--text" v-else-if="findQuantityType(item).quantityType == 'CountContainer'
              ">
              <v-icon left color="blue">view_module</v-icon>
              Per {{ findQuantityType(item).count }} Containers
            </v-chip>
          </template>

          <template v-slot:[`item.courier`]="{ item }">
            <v-icon v-if="findCourierDocument(item)" color="success" :key="documentUpdateKey">check</v-icon>
          </template>
          <template v-slot:[`item.comment`]="{ item }">
            <span v-if="findDocumentNote(item)" :key="documentUpdateKey">{{
              findDocumentNote(item)
            }}</span>
          </template>
          <template v-slot:[`item.specialInstruction`]="{ item }">
            <span v-if="findDocumentSpecialInstruction(item)" :key="documentUpdateKey">{{
              findDocumentSpecialInstruction(item)
            }}</span>
          </template>
          <template v-slot:[`item.share`]="{ item }">
            <v-tooltip top v-for="shared in findShared(item)" :key="shared">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" icon outlined small class="mx-1" v-on="on">
                  <v-icon small>{{ getIcon(shared) }}</v-icon>
                </v-btn>
              </template>
              <span style="font-size: 12px">{{ shared }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-text v-else style="height: 70vh">
        <v-row justify="center" align="center" style="height: 90%" class="text-center">
          <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3" class="d-flex justify-center align-center">
            <span style="color: grey; font-size: 18px">Please assign both origin & destination country details to
              complete this section.</span>
          </v-col>
        </v-row>
        <!-- </v-container> -->
      </v-card-text>
    </v-card>

    <v-dialog v-model="modal" persistent width="1200px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card v-if="documentItem">
        <v-card-title>
          <v-tooltip top
            v-if="documentItem.consigneeProfileDocument && documentItem.consigneeProfileDocument.id && documentItem.consigneeProfileDocument.manuallyAdded">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon color="red" class="mr-1"
                @click="deleteDocument(documentItem)"><v-icon>delete</v-icon></v-btn>
            </template>
            <span style="font-size: 12px">Remove manually added document</span>
          </v-tooltip>
          {{ documentItem.name }}
          <v-tooltip top v-if="documentItem.attestRequired">
            <template v-slot:activator="{ on, attrs }">
              <i v-bind="attrs" v-on="on" style="color: red">(Attest Required)</i>
            </template>
            <span style="font-size: 12px">{{
              documentItem.attestRegulatoryBody.name
            }}</span>
          </v-tooltip>

          <v-spacer></v-spacer>
          <v-btn :key="validateKey" v-if="documentItem.consigneeProfileDocument" text color="primary"
            @click="saveConsigneeProfileDocument" :loading="saving">Save</v-btn>
          <v-btn text @click="(modal = false), (documentItem = {})">X</v-btn>
        </v-card-title>
        <v-card-text>
          <p style="color: grey">{{ documentItem.description }}</p>
          <v-row>
            <v-col cols="12" sm="6">
              <v-list dense subheader v-if="documentItem.consigneeProfileDocument" :key="documentKey">
                <v-select v-model="documentItem.consigneeProfileDocument.responsiblePartyType
                  " outlined dense label="Responsible Party*" :items="['shipper', 'forwarder', 'buyer', 'consignee']"
                  item-text="name" item-value="id" style="text-transform: capitalize">
                  <template v-slot:selection="data">
                    <span style="text-transform: capitalize">{{ data.item }}
                    </span>
                  </template>
                  <template v-slot:item="data">
                    <span style="text-transform: capitalize">{{ data.item }}
                    </span>
                  </template>
                </v-select>

                <v-switch class="my-0 py-0" label="Force Document Inclusion"
                  v-model="documentItem.consigneeProfileDocument.manuallyAdded"
                  hint="Only tick if you would like to override the rules engine"></v-switch>
                <v-checkbox class="my-0 py-0"
                  :label="'Add document to ' + (!item.courierParty ? 'Copy To Pack' : 'Courier Pack')"
                  v-model="documentItem.consigneeProfileDocument.courier"></v-checkbox>
                <v-row align="center">
                  <v-col cols="12" sm="6" class="mb-0 pb-0">
                    <v-checkbox class="my-0 py-0" label="Draft Approval Required"
                      v-model="documentItem.consigneeProfileDocument.approvalRequired"
                      @change="approvalKey++"></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6" :key="approvalKey" class="mb-0 pb-0">
                    <v-select :key="approvalKey"
                      v-if="documentItem.consigneeProfileDocument && documentItem.consigneeProfileDocument.approvalRequired"
                      v-model="documentItem.consigneeProfileDocument.approvalParty
                        " outlined dense label="Approval Party" :items="['shipper', 'forwarder', 'buyer', 'consignee']"
                      item-text="name" item-value="id" style="text-transform: capitalize">
                      <template v-slot:selection="data">
                        <span style="text-transform: capitalize">{{ data.item }}
                        </span>
                      </template>
                      <template v-slot:item="data">
                        <span style="text-transform: capitalize">{{ data.item }}
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" class="my-0 py-0">
                    <v-switch hide-details label="Ignore Document"
                      v-model="documentItem.consigneeProfileDocument.ignore"></v-switch>

                  </v-col>
                  <v-col cols="12" sm="6" class="my-0 py-0">
                    <v-switch hide-details label="Attest Document" color="success"
                      v-model="documentItem.consigneeProfileDocument.attest"></v-switch>
                  </v-col>
                </v-row>


                <v-subheader>Who is allowed to see this document?</v-subheader>
                <v-chip class="mx-1" @click="
                (documentItem.consigneeProfileDocument.consignee =
                  !documentItem.consigneeProfileDocument.consignee),
                  documentKey++
                  " outlined small :color="documentItem.consigneeProfileDocument.consignee
    ? 'green'
    : 'grey'
    ">
                  <v-icon v-if="documentItem.consigneeProfileDocument.consignee" small color="green"
                    class="mr-1">check</v-icon>
                  Consignee
                </v-chip>
                <v-chip class="mx-1" @click="
                (documentItem.consigneeProfileDocument.buyer =
                  !documentItem.consigneeProfileDocument.buyer),
                  documentKey++
                  " outlined small :color="documentItem.consigneeProfileDocument.buyer
    ? 'green'
    : 'grey'
    ">
                  <v-icon v-if="documentItem.consigneeProfileDocument.buyer" small color="green"
                    class="mr-1">check</v-icon>
                  Buyer
                </v-chip>
                <v-chip class="mx-1" @click="
                (documentItem.consigneeProfileDocument.shipper =
                  !documentItem.consigneeProfileDocument.shipper),
                  documentKey++
                  " outlined small :color="documentItem.consigneeProfileDocument.shipper
    ? 'green'
    : 'grey'
    ">
                  <v-icon v-if="documentItem.consigneeProfileDocument.shipper" small color="green"
                    class="mr-1">check</v-icon>
                  Shipper
                </v-chip>
                <v-chip class="mx-1" @click="
                (documentItem.consigneeProfileDocument.forwarder =
                  !documentItem.consigneeProfileDocument.forwarder),
                  documentKey++
                  " outlined small :color="documentItem.consigneeProfileDocument.forwarder
    ? 'green'
    : 'grey'
    ">
                  <v-icon v-if="documentItem.consigneeProfileDocument.forwarder" small color="green"
                    class="mr-1">check</v-icon>
                  Forwarder
                </v-chip>
                <v-chip class="mx-1" @click="
                (documentItem.consigneeProfileDocument.notifyParty =
                  !documentItem.consigneeProfileDocument.notifyParty),
                  documentKey++
                  " outlined small :color="documentItem.consigneeProfileDocument.notifyParty
    ? 'green'
    : 'grey'
    ">
                  <v-icon v-if="documentItem.consigneeProfileDocument.notifyParty" small color="green"
                    class="mr-1">check</v-icon>
                  Notify Party
                </v-chip>
                <v-subheader>How would you like the documents to be generated?</v-subheader>

                <v-list-item v-for="quantity in quantityTypes" :key="quantity.value" @click="
                  documentItem.consigneeProfileDocument.quantityType =
                  quantity.value;
                quantityKey++;
                ">
                  <v-list-item-action :key="quantityKey">
                    <v-icon color="success" v-if="documentItem.consigneeProfileDocument.quantityType ==
                      quantity.value
                      ">check</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ quantity.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-wrap">
                      {{ quantity.description }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <el-input-number :min="1" v-if="quantity.value == 'CountContainer' &&
                      documentItem.consigneeProfileDocument.quantityType ==
                      'CountContainer'
                      " size="mini" v-model="documentItem.consigneeProfileDocument.count"></el-input-number>
                  </v-list-item-action>
                </v-list-item>
                <!-- <v-divider></v-divider>
                <v-subheader>How would you like the documents to be invoiced?</v-subheader>
                  <div v-if="documentItem.consigneeProfileDocument">
                    <v-list-item v-for="invoice in invoiceTypes" :key="invoice.value" @click="
                      documentItem.consigneeProfileDocument.invoiceType =
                      invoice.value;
                    invoiceKey++;
                    ">
                      <v-list-item-action :key="invoiceKey">
                        <v-icon color="success" v-if="documentItem.consigneeProfileDocument.invoiceType ==
                          invoice.value
                          ">check</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ invoice.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-wrap">
                          {{ invoice.description }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <el-input-number :min="1" v-if="invoice.value == 'CountDocument' &&
                          documentItem.consigneeProfileDocument.invoiceType == 'CountDocument'
                          " size="mini" v-model="documentItem.consigneeProfileDocument.invoiceCount"></el-input-number>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                  </div> -->
              </v-list>
            </v-col>
            <v-col cols="12" sm="6">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-list dense subheader :key="variationKey" style="max-height: 35vh;overflow-y:auto">
                    <v-subheader style="font-size: 16px; background: var(--v-component-base)">Variations</v-subheader>
                    <div v-for="variation in documentItem.variations" :key="variation.id">
                      <v-list-item v-if="variation.id !== 66 && variation.id !== 136">
                        <v-list-item-content>
                          <v-list-item-title>{{ variation.name }}
                            <v-tooltip top v-if="variation.attestRequired">
                              <template v-slot:activator="{ on, attrs }">
                                <i v-bind="attrs" v-on="on" style="color: red">(Attest Required)</i>
                              </template>
                              <span style="font-size: 12px">{{
                                variation.attestRegulatoryBody.name
                              }}</span>
                            </v-tooltip></v-list-item-title>
                          <v-list-item-subtitle style="font-size: 12px" class="text-wrap">{{ variation.description
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" icon @click="setPreferredVariation(variation.id)"><v-icon
                                  v-if="documentItem.consigneeProfileDocument.preferredVariationId == variation.id
                                    " color="secondary">star</v-icon><v-icon v-else>star_border</v-icon></v-btn>
                            </template>
                            <span style="font-size: 12px">Set as preferred document variation</span>
                          </v-tooltip>
                        </v-list-item-action>
                      </v-list-item>
                    </div>
                    <v-list-item v-if="!documentItem.variations ||
                      documentItem.variations.length == 0
                      ">
                      <v-list-item-content class="text-center">
                        <span style="font-size: 14px; color: grey">No Variations for this document.</span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-list dense subheader style="max-height: 35vh;overflow-y:auto">
                    <v-subheader style="font-size: 16px; background: var(--v-component-base)">Supporting
                      Documents</v-subheader>
                    <v-list-item v-for="supporting in documentItem.supportingDocuments" :key="supporting.id">
                      <v-list-item-content>
                        <v-list-item-title>{{ supporting.name }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="!documentItem.supportingDocuments ||
                      documentItem.supportingDocuments.length == 0
                      ">
                      <v-list-item-content class="text-center">
                        <span style="font-size: 14px; color: grey">No supporting documents required.</span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" sm="6" v-if="documentItem.consigneeProfileDocument && item.onBehalfShipper">
                  <v-list dense>
                    <v-subheader>Shipper Detail Settings</v-subheader>
                    <v-select label="Shipper Document Address"
                      v-model="documentItem.consigneeProfileDocument.shipperAddress" item-value="value" item-text="name"
                      :items="availableShipperAddresses" outlined dense></v-select>
                    <v-checkbox label="Display on behalf shipper name"
                      v-model="documentItem.consigneeProfileDocument.displayOnBehalfShipperName"></v-checkbox>
                  </v-list>
                </v-col>
                <v-col cols="12" sm="6" v-if="documentItem.consigneeProfileDocument && item.onBehalfConsignee">
                  <v-list dense>
                    <v-subheader>Consignee Detail Settings</v-subheader>
                    <v-select label="Consignee Document Address"
                      v-model="documentItem.consigneeProfileDocument.consigneeAddress" item-value="value" item-text="name"
                      :items="availableConsigneeAddresses" outlined dense></v-select>
                    <v-checkbox label="Display on behalf consignee name"
                      v-model="documentItem.consigneeProfileDocument.displayOnBehalfConsigneeName"></v-checkbox>
                  </v-list>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" v-if="documentItem.consigneeProfileDocument">
              <v-textarea v-model="documentItem.consigneeProfileDocument.specialInstruction
                " persistent-placeholder label="Special Instructions for this document"
                placeholder="This is will be seen on the processed document" outlined rows="3">
              </v-textarea>
            </v-col>

            <v-col cols="12" sm="6" v-if="documentItem.consigneeProfileDocument">
              <v-textarea v-model="documentItem.consigneeProfileDocument.comment" persistent-placeholder
                label="Additional Notes"
                placeholder="These notes are for reference only and will not be placed on the document" outlined rows="3">
              </v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addDocumentModal" width="1200px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title v-if="!documentItem || !documentItem.id">Add Document</v-toolbar-title>
          <v-toolbar-title v-else>{{ documentItem.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="addDocumentModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text v-if="!documentItem || !documentItem.id">
          <v-row justify="end">
            <v-col cols="12" md="6">
              <v-text-field label="Search" prepend-inner-icon="search" v-model="searchAdditionalDocuments" outlined dense
                clearable></v-text-field>
            </v-col>
          </v-row>
          <v-data-table style="cursor: pointer" :loading="loadingAdditionalDocuments" :items="filterCountryDocuments"
            :headers="additionalDocumentHeaders" :search="searchAdditionalDocuments"
            @click:row="setNewDocument"></v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="filterDialog" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Filter</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="filterDialog = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-subheader>Product Lookup</v-subheader>
            <v-select label="Type" :items="['Product Group', 'Product', 'Variety']" v-model="filterProductType" outlined
              dense></v-select>
            <v-autocomplete hide-no-data hide-selected label="Product" dense outlined :items="filterProducts" clearable
              item-text="name" item-value="id" :menu-props="{ closeOnContentClick: true }" v-model="filterProduct"
              return-object :loading="loadingProduct">
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ data.item.type }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="data.item.parentProduct" style="font-size: 12px">
                    Parent: {{ data.item.parentProduct.name }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <v-divider></v-divider>
            <v-select label="Filter Share Settings" outlined dense clearable v-model="filterShare" item-text="value"
              item-value="key" :items="[
                'Buyer',
                'Consignee',
                'Shipper',
                'Forwarder',
                'Notify Party',
              ]"></v-select>
            <v-row justify="center" class="mt-2">
              <v-btn @click="getDocuments" text color="primary">Apply Filter</v-btn>
            </v-row>
          </v-list>
        </v-card-text>
      </v-card></v-dialog>

    <v-dialog v-model="infoStart" max-width="31vw">
      <v-card>
        <v-btn @click="infoStartGuide" icon style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0;"><v-icon
            color="primary" size="30">close</v-icon></v-btn>
        <v-card-title class="d-flex justify-center">
          FRIKO Welcome to Company Profiles!
        </v-card-title>
        <v-card-text>
          <span>
            <p>FRIKO</p>
            <p>FRIKO
              <br>• Map out routes for transporters
              <br>• Add GeoJSON data to locations used determine if a vehicle is within a certain area
              <br>• Calculate accurate waiting times at loading points
              <br>• Calculate distances for billing purposes
              <br>• Pin-point locations for the purpose of reporting and analysis
              <br>• Map out farms that aren't found on Google Maps
            </p>
            If you would like learn how to load POI's into the system, click the button below to start the
            tutorial.
          </span>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="startGuide" color="edit" class="mb-3">Start!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn color="grey" class="infoButton" elevation="2" fab fixed bottom right style="text-transform: none;"
      @click="infoStartGuide">
      <v-icon>help</v-icon>
    </v-btn>

  </div>
</template>
<script>
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
// import steps from '../../Customer/Steps/Documents'
export default {
  props: ["item"],
  data: () => ({
    steps: null,
    driver: null,
    infoStart: false,
    addDocumentModal: false,
    additionalDocumentHeaders: [
      {
        text: "Name",
        value: "name",
        align: "left",
      },
      {
        text: "Regulatory Body",
        value: "regulatoryBody.name",
        align: "left",
      },
      {
        text: "Description",
        value: "description",
        align: "left",
      },
    ],
    approvalKey: 0,
    countryDocuments: [],
    documents: [],
    documentKey: 0,
    filterShare: null,
    headers: [
      // {
      //   text: "Action",
      //   value: "action",
      //   align: "center",
      //   width: "20px",
      //   sortable: false,
      // },
      {
        text: "Type",
        value: "type",
        align: "left",
      },
      {
        text: "Rank",
        value: "rank",
        align: "center",
      },
      {
        text: "Name",
        value: "name",
        align: "left",
        width: "250px",
      },
      {
        text: "Regulatory Body",
        value: "regulatoryBody.name",
        align: "left",
      },

      {
        text: "Responsible Party",
        value: "responsiblePartyType",
        align: "center",
      },
      {
        text: "Quantity",
        value: "quantityType",
        align: "left",
      },
      {
        text: "Courier",
        value: "courier",
        align: "center",
      },
      {
        text: "Share Settings",
        value: "share",
        width: "200px",
        align: "center",
        sortable: false,
      },
      {
        text: "Notes",
        value: "comment",
        align: "center",
        sortable: false,
      },
      {
        text: "Special Instruction",
        value: "specialInstruction",
        align: "center",
        sortable: false,
      },
    ],
    documentItem: {},
    documentUpdateKey: 0,
    filterDialog: false,
    filterProductType: "Product",
    filterProduct: null,
    loading: false,
    loadingAdditionalDocuments: false,
    loadingProduct: false,
    modal: false,
    products: {
      total: 0,
      data: [],
    },
    quantityKey: 0,
    quantityTypes: [
      {
        name: "Per Shipment",
        value: "Shipment",
        description: "Generate one document per shipment.",
      },
      {
        name: "Per Container",
        value: "Container",
        description: "Generate one document per container.",
      },
      {
        name: "Per x Containers",
        value: "CountContainer",
        description: "Generate one document per specified number of containers.",
      },
    ],
    invoiceKey: 0,
    invoiceTypes: [
      {
        name: "Per Shipment",
        value: "Shipment",
        description: "Invoice one document per shipment.",
      },
      {
        name: "Per Document",
        value: "Document",
        description: "Invoice each document.",
      },
      {
        name: "Per x Documents",
        value: "CountDocument",
        description: "Only invoice the specified number of documents.",
      },
      {
        name: "Not Invoiced",
        value: "NoCharge",
        description: "Document should not be invoiced.",
      },
    ],
    saving: false,
    search: null,
    searchAdditionalDocuments: null,
    validateKey: 0,
    variationKey: 0,
  }),
  async mounted() {
    this.driver = new Driver({
      animate: false
    })
    console.log('item', this.item)
  },
  watch: {
    'item.courierParty': {
      immediate: true,
      handler(val) {
        if (!val) {
          let headerItem = this.headers.findIndex(x => x.value == 'courier')
          this.headers[headerItem].text = '"Copy To" Document'
        } else {
          let headerItem = this.headers.findIndex(x => x.value == 'courier')
          this.headers[headerItem].text = 'Courier Document'
        }
        this.documentUpdateKey++
      }
    },
    "documentItem.consigneeProfileDocument.quantityType": {
      immediate: true,
      handler(val) {
        this.quantityKey++;
      },
    },
    "documentItem.consigneeProfileDocument.responsiblePartyId": {
      immediate: true,
      handler() {
        this.validateKey++;
      },
    },
    // filterProduct(){
    //   this.getDocuments()
    // }
  },
  computed: {
    availableConsigneeAddresses() {
      let result = []
      if (this.item.consignee) {
        result.push({
          name: this.item.consignee.name,
          value: 'consignee'
        })
      }
      if (this.item.onBehalfConsignee) {
        result.push({
          name: this.item.onBehalfConsignee.name,
          value: 'onBehalfConsignee'
        })
      }
      return result
    },
    availableShipperAddresses() {
      let result = []
      if (this.item.shipper) {
        result.push({
          name: this.item.shipper.name,
          value: 'shipper'
        })
      }
      if (this.item.onBehalfShipper) {
        result.push({
          name: this.item.onBehalfShipper.name,
          value: 'onBehalfShipper'
        })
      }
      return result
    },
    filterCountryDocuments() {
      let result = this.countryDocuments
      if (result) {
        result = result.filter(
          (x) => !this.documents.map((y) => y.id).includes(x.id)
        );
      }

      return result
    },
    filterDocuments() {
      let result = this.documents;
      if (this.filterShare) {
        result = result.filter(
          (x) => this.findShared(x).includes(this.filterShare)
          // x.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return result;
    },
    filterProducts() {
      let result = this.products.data;
      if (result && this.filterProductType) {
        result = result.filter((x) => x.type == this.filterProductType);
      }
      return result;
    },
    freightParties() {
      let keys = ["buyer", "consignee", "shipper", "forwarder"];
      let result = [];
      keys.forEach((x) => {
        if (this.item[x] && !result.find((y) => y.id == this.item[x + "Id"])) {
          if (!this.item[x].types) {
            this.item[x].types = [];
          }
          this.item[x].types.push(x);
          result.push(this.item[x]);
        }
      });

      return result;
    },
    getFilterProduct() {
      let result = null
      if (this.item.filterProductId && this.products.data.length > 0) {
        result = this.products.data.find(x => x.id == this.item.filterProductId)

      }
      return result
    },
  },
  async created() {
    this.getDocuments();
    this.loadProducts();
    await this.getSteps()
  },
  methods: {
    async getSteps() {
        let steps = await this.$API.getGuideSteps({
            guideId: 'ConsigneeProfile/Document/Steps',
        });
        this.steps = steps.steps
    },

    addManualDocument() {
      this.addDocumentModal = true;
    },
    adjustSettings(item) {
      this.documentItem = item;
      let findConsigneeDocument = this.item.consigneeProfileDocuments.find(
        (x) => x.locationDocumentId == this.documentItem.id
      );
      this.documentItem.consigneeProfileDocument = findConsigneeDocument
        ? findConsigneeDocument
        : {
          locationDocumentId: this.documentItem.id,
          quantityType: this.documentItem.quantityType,
          count: 1,
          courier: this.documentItem.courier,
          shipper: this.documentItem.shipper,
          forwarder: this.documentItem.forwarder,
          consignee: this.documentItem.consignee,
          buyer: this.documentItem.buyer,
          notifyParty: this.documentItem.notifyParty,
          responsiblePartyType: this.documentItem.responsiblePartyType,
          approvalParty: 'shipper',
          ignore: false
        };
      this.modal = true;
    },
    attestRequired(item) {
      return item.attest
        ? item.variations.filter((x) => x.attestRequired).length > 0
        : false;
    },
    attest(item) {
      let find = this.item.consigneeProfileDocuments.find(
        (x) => x.locationDocumentId == item.id
      );
      return find ? find.attest : false;
    },
    attestRequiredFiles(item) {
      let result = "";
      if (item.attestRequired) {
        result = item.name;
      } else {
        let filteredVariations = item.variations.filter(
          (x) => x.attestRequired
        );
        for (let i = 0; i < filteredVariations.length; i++) {
          result += filteredVariations[i].name;
          if (i < filteredVariations.length - 1) {
            result += ", ";
          }
        }
      }
      return result;
    },
    deleteDocument(item) {
      this.$confirm(
        "Are you sure you want to remove this document?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          await this.$API.updateConsigneeProfileDocument({
            id: item.consigneeProfileDocument.id,
            isActive: false,
            isDeleted: true,
          });
          // this.profiles.splice(this.profiles.indexOf(item), 1)
          this.getDocuments();
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
          this.modal = false
          this.documentItem = {}
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    findCourierDocument(document) {
      let find = this.item.consigneeProfileDocuments.find(
        (x) => x.locationDocumentId == document.id
      );
      return find ? find.courier : false;
    },
    findDocumentNote(document) {
      let find = this.item.consigneeProfileDocuments.find(
        (x) => x.locationDocumentId == document.id
      );
      return find ? find.comment : false;
    },
    findDocumentSpecialInstruction(document) {
      let find = this.item.consigneeProfileDocuments.find(
        (x) => x.locationDocumentId == document.id
      );
      return find ? find.specialInstruction : false;
    },
    findResponsibleParty(item) {
      let find = this.item.consigneeProfileDocuments.find(
        (x) => x.locationDocumentId == item.id
      );
      return find && find.responsiblePartyType
        ? find.responsiblePartyType
        : item.responsiblePartyType;
    },
    findShared(document) {
      let find = this.item.consigneeProfileDocuments.find(
        (x) => x.locationDocumentId == document.id
      );
      let result = [];
      if (find) {
        if (find.buyer) result.push("Buyer");
        if (find.consignee) result.push("Consignee");
        if (find.shipper) result.push("Shipper");
        if (find.forwarder) result.push("Forwarder");
        if (find.notifyParty) result.push("Notify Party");
      } else {
        if (document.buyer) result.push("Buyer");
        if (document.consignee) result.push("Consignee");
        if (document.shipper) result.push("Shipper");
        if (document.forwarder) result.push("Forwarder");
        if (document.notifyParty) result.push("Notify Party");
      }
      return result;
    },
    findQuantityType(document) {
      let find = this.item.consigneeProfileDocuments.find(
        (x) => x.locationDocumentId == document.id
      );
      return find ? find : document;
    },
    async getDocuments() {
      this.filterDialog = false;
      if (this.item.originCountryId && this.item.destinationCountryId) {
        if (!this.countryDocuments || this.countryDocuments.length == 0) {
          this.loadOtherDocuments();
        }
        this.loading = true;
        let obj = {
          originCountryId: this.item.originCountryId,
          destinationCountryId: this.item.destinationCountryId,
        };
        if (this.item.filterProductId) {
          obj.productId = this.item.filterProductId;
        }
        if (!obj.productId && this.filterProduct) {
          obj.productId = this.filterProduct.id;
          this.item.filterProductId = this.filterProduct.id;
        }

        this.$API.updateConsigneeProfile({ id: this.item.id, filterProductId: this.item.filterProductId })
        if (this.item.id) {
          obj.consigneeProfileId = this.item.id
        }
        this.documents = await this.$API.documentLookup(obj);

        this.loading = false;


      }
    },
    async loadOtherDocuments() {
      if (this.item.originCountryId) {
        this.loadingAdditionalDocuments = true
        this.countryDocuments = await this.$API.getCountryDocuments(
          this.item.originCountryId
        );
        this.loadingAdditionalDocuments = false
      }
    },
    documentIgnored(document) {
      let find = this.item.consigneeProfileDocuments.find(
        (x) => x.locationDocumentId == document.id
      );
      return find ? find.ignore : false;
    },
    manuallyAdded(document) {
      let find = this.item.consigneeProfileDocuments.find(
        (x) => x.locationDocumentId == document.id
      );
      return find ? find.manuallyAdded : false;
    },
    getIcon(type) {
      switch (type.toLowerCase()) {
        case "buyer":
          return "shopping_cart";
        case "consignee":
          return "call_received";
        case "shipper":
          return "directions_boat";
        case "forwarder":
          return "fast_forward";
        case "notify party":
          return "notifications";
      }
    },
    async loadProducts() {
      this.loadingProduct = true;
      // this.productGroups = await this.$API.listCountryProductGroups(
      //   this.item.originCountry.iso2
      // );
      // let products = await this.$API.listCountryProducts(
      //   this.item.originCountry.iso2,
      //   {}
      // );
      this.products = await this.$API.getProducts({});
      if (this.item.filterProfuctId) {
        this.filterProduct = this.products.data.find(x => x.id == this.item.filterProductId)
      }
      // this.products = products.data;
      this.loadingProduct = false;
    },
    async saveConsigneeProfileDocument() {
      this.saving = true;


      if (this.documentItem.consigneeProfileDocument.responsiblePartyId) {
        this.documentItem.consigneeProfileDocument.responsibleParty =
          this.freightParties.find(
            (x) =>
              x.id ==
              this.documentItem.consigneeProfileDocument.responsiblePartyId
          );
      }
      if (this.documentItem.consigneeProfileDocument.id) {
        await this.$API.updateConsigneeProfileDocument(
          this.documentItem.consigneeProfileDocument
        );
        let index = this.item.consigneeProfileDocuments.findIndex(
          (x) => x.id == this.documentItem.consigneeProfileDocument.id
        );
        this.item.consigneeProfileDocuments[index] =
          this.documentItem.consigneeProfileDocument;
        this.documentUpdateKey++;
      } else {
        if (this.item.id) {
          this.documentItem.consigneeProfileDocument.consigneeProfileId =
            this.item.id;
          let result = await this.$API.createConsigneeProfileDocument(
            this.documentItem.consigneeProfileDocument
          );
          this.item.consigneeProfileDocuments.push(result);
          this.documentUpdateKey++;
          this.$emit('updateProfileDocument', result)
        } else {
          this.documentItem.consigneeProfileDocument.responsibleParty =
            this.freightParties.find(
              (x) =>
                x.id ==
                this.documentItem.consigneeProfileDocument.responsiblePartyId
            );

          this.documentUpdateKey++;
          this.$emit('updateProfileDocument', this.documentItem.consigneeProfileDocument)
        }
      }
      if (!this.documents.map(x => x.id).includes(this.documentItem.id)) {
        this.getDocuments()
      }

      this.documentUpdateKey++;
      this.modal = false;
      this.documentItem = {};
      // this.getDocuments()
      this.saving = false;
    },
    setNewDocument(item) {
      this.documentItem = item;
      let findConsigneeDocument = this.item.consigneeProfileDocuments.find(
        (x) => x.locationDocumentId == this.documentItem.id
      );
      this.documentItem.consigneeProfileDocument = findConsigneeDocument
        ? findConsigneeDocument
        : {
          locationDocumentId: this.documentItem.id,
          quantityType: this.documentItem.quantityType,
          count: 1,
          courier: this.documentItem.courier,
          shipper: this.documentItem.shipper,
          forwarder: this.documentItem.forwarder,
          consignee: this.documentItem.consignee,
          buyer: this.documentItem.buyer,
          notifyParty: this.documentItem.notifyParty,
          responsiblePartyType: this.documentItem.responsiblePartyType,
          manuallyAdded: true
        };
      this.documentItem.consigneeProfileDocument.manuallyAdded = true
      this.addDocumentModal = false
      this.modal = true;
    },
    setPreferredVariation(id) {
      if (this.documentItem.consigneeProfileDocument.preferredVariationId == id) {
        this.documentItem.consigneeProfileDocument.preferredVariationId = null;
      } else {
        this.documentItem.consigneeProfileDocument.preferredVariationId = id;
      }
      this.variationKey++;
    },
    infoStartGuide() {
      if (this.infoStart) {
        this.infoStart = false;
      } else {
        this.infoStart = true;
      }
    },
    startGuide(e) {
      this.infoStart = false
      e.stopPropagation();
      this.driver.defineSteps(this.steps)
      this.driver.start()
    },

  },
};
</script>
<style scoped>
.ellipsis-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
}

.v-tooltip__content {
  opacity: 1;
}

.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.infoButton {
  /* position: relative; */
  right: 280px
}
</style>
