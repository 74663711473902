<template>
    <div>
        <v-btn-toggle
                borderless
                style="background: transparent !important"
              >
              <div>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      id="edit-profile-button"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="editProfile()"
                      small
                    >
                      <v-icon small>edit</v-icon>
                    </v-btn>
                  </template>
                  <span style="font-size: 12px">Edit Profile</span>
                </v-tooltip>
              </div>
             
                <div v-if="
                    (bookingScope && bookingScope.type == 'paid') ||
                    (shipmentScope && shipmentScope.type == 'paid')
                  ">
                    <v-tooltip top >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      id="copy-profile-button"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="cloneProfile()"
                      small
                    >
                      <v-icon small color="orange">content_copy</v-icon>
                    </v-btn>
                  </template>
                  <span style="font-size: 12px">Copy/Clone Profile</span>
                </v-tooltip>
              
                <v-tooltip
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      id="delete-profile-button"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="removeProfile()"
                      small
                    >
                      <v-icon small color="red">delete</v-icon>
                    </v-btn>
                  </template>
                  <span style="font-size: 12px">Delete Profile</span>
                </v-tooltip>
                </div>
              
              </v-btn-toggle>
    </div>
</template>
<script>
export default {
    data: ()=>({
        bookingScope: null,
        shipmentScope: null,
        item: null
    }),
    mounted() {
        this.item = this.params.data;
        this.shipmentScope = this.params.shipmentScope;
        this.bookingScope = this.params.bookingScope;
    },
    methods: {
        cloneProfile() {
            this.params.cloneProfile(this.item);
        },
        editProfile() {
            this.params.editProfile(this.item);
        },
        removeProfile() {
            this.params.removeProfile(this.item);

        }
    }

}
</script>