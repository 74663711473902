<template>
  <div>
    <v-card flat id="assigned-parties">
      <v-card-text class="mx-0 px-0">
        <v-row>
          <v-col cols="12" sm="12" class="my-0 py-0 mx-0 px-0">
            <v-list
              dense
              subheader
              :class="$vuetify.breakpoint.mobile ? 'px-0 mx-0' : 'px-3'"
            >
              <v-subheader
                class="mt-0 pt-0 primaryText--text"
                style="font-size: 16px"
              >
                <v-icon left color="grey" class="mr-2"
                  >currency_exchange</v-icon
                >
                Transactional Links
              </v-subheader>
              <v-divider></v-divider>

              <v-list-item
                v-if="
                  availableCustomerRoles.length > 0 &&
                  !showCustomers &&
                  customers.length === 0
                "
                @click="organisationDialog = true"
                id="add-customer"
              >
                <v-list-item-content class="text-center">
                  <span
                    >
                    <v-icon color="primary" class="mr-2">add_circle_outline</v-icon>
                    Add Link
                   </span
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-for="(customer, index) in customers"
                :key="index"
                @click="
                  (organisationDialog = true),
                    (updateCustomer = true),
                    (currentIndex = index)
                "
              >
                <v-list-item-action>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" v-if="customer.organisationRelationship && customer.organisationRelationship.clientStatus" :color="getClientStatusColor(customer.organisationRelationship.clientStatus)">fiber_manual_record</v-icon>
                            <v-icon v-on="on" v-else color="grey">fiber_manual_record</v-icon>
                        </template>
                        <span v-if="customer.organisationRelationship && customer.organisationRelationship.clientStatus">Client Status: {{ customer.organisationRelationship.clientStatus }}</span>
                        <span v-else>Client Status: Unkown</span>
                    </v-tooltip>
                  <!-- <v-icon
                    v-if="customer.organisationRelationship && customer.organisationRelationship.clientStatus"
                    :color="getClientStatusColor(customer.organisationRelationship.clientStatus)"
                    >fiber_manual_record</v-icon
                  >
                  <v-icon
                    v-else
                    color="grey"
                    >fiber_manual_record</v-icon
                  > -->
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    v-if="customer.organisation && index === 0"
                  >
                    {{ customer.organisation.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    v-if="
                      customer.organisationRelationship &&
                      customer.organisationRelationship.accountReference
                    "
                  >
                    <v-icon small class="mr-2">tag</v-icon>
                    {{ customer.organisationRelationship.accountReference }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-subheader
                class="mt-0 pt-0 primaryText--text"
                style="font-size: 16px"
              >
                <v-icon left color="grey" class="mr-2"
                  >share</v-icon
                >
               Share Settings
              </v-subheader>
              <v-divider></v-divider>
              <v-list-item v-if="profile.owner">
                <v-list-item-action>
                <v-avatar size="36" :color="profile.owner.logo?'white':'secondary'">
                  <v-img v-if="profile.owner.logo" :src="profile.owner.logo" contain></v-img>
                  <h3 v-else>
                    {{ profile.owner.name.charAt(0) }}
                  </h3>
                </v-avatar>
               </v-list-item-action>
               <v-list-item-content>
                <v-list-item-title>
                  {{ profile.owner.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Profile Owner
                </v-list-item-subtitle>
               </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="uniqueOrganisations.length == 0">
                <v-list-item-content class="text-center"> 
                  <span style="color: grey">No selectable organisations</span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
               v-for="org in uniqueOrganisations" :key="org.organisationId">
               <v-list-item-action>
                <v-avatar size="36" :color="org.organisation.logo?'white':'secondary'">
                  <v-img v-if="org.organisation.logo" :src="org.organisation.logo" contain></v-img>
                  <h3 v-else>
                    {{ org.organisation.name.charAt(0) }}
                  </h3>
                </v-avatar>
               </v-list-item-action>
               <v-list-item-content>
                <v-list-item-title>
                  {{ org.organisation.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ org.role }}
                </v-list-item-subtitle>
               </v-list-item-content>
               <v-list-item-action>
                <v-switch :readonly="!profile.editable" :error="!org.share" dense color="success" inset v-model="org.share" hide-details @change="editShareSetting(org)"></v-switch>
               </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="organisationDialog"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="(organisationDialog = false), (searchOrganisation = null)"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-text-field
            outlined
            label="Search"
            v-model="searchOrganisation"
            dense
            clearable
          ></v-text-field>
          <v-progress-linear
            v-if="savingCustomer"
            indeterminate
            color="primary"
          ></v-progress-linear>
          <v-list dense style="max-height: 60vh; overflow-y: auto">
            <v-list-item
              v-for="(org, index) in filterOrganisations"
              :key="index"
              @click="addCustomer(org.id, org.relatedOrganisation.id)"
            >
              <v-list-item-content>
                <v-list-item-title v-if="org.relatedOrganisation">
                  {{ org.relatedOrganisation.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="filterOrganisations.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey">No organisations found</span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["profile", "contacts"],
  data: () => ({
    loading: false,
    items: [],
    organisationDialog: false,
    roles: [
    { name: "Bank", value: "bank" },
      { name: "Shipper", value: "shipper" },
      { name: "On behalf of Shipper", value: "onBehalfShipper" },
      { name: "Forwarder", value: "forwarder" },
      { name: "On behalf of Forwarder", value: "onBehalfForwarder" },
      { name: "Consignee", value: "consignee" },
      { name: "On behalf of Consignee", value: "onBehalfConsignee" },
      { name: "Buyer", value: "buyer" },
      { name: "On behalf of Buyer", value: "onBehalfBuyer" },
      { name: "Notify", value: "firstNotify" },
      { name: "2nd Notify Party", value: "secondNotify" },
      { name: "Courier", value: "courierParty" },
    ],
    refreshCustomerKey: 2000,
    savingCustomer: false,
    searchOrganisation: null,
    showCustomers: false,
    updateCustomer: false,
    currentIndex: null,
    uniqueOrganisations: []
  }),
  watch: {
    "profile.consigneeProfileRelationships.length": {
      handler: function (val) {
        this.refreshCustomerKey++;
        this.setUniqueOrganisations()
        // console.log(this.refreshCustomerKey, val);
      },
      immediate: true,
    },
    // profile: {
    //     handler: function (val) {
    //         this.loading = true
    //         this.$nextTick(() => {
    //             this.loading = false
    //         })
    //     },
    //     deep: true
    // }
  },
  computed: {
    availableCustomerRoles() {
      let result = [];
      this.roles.forEach((role) => {
        if (
          this.profile[role.value] &&
          !this.profile.consigneeProfileRelationships
            .map((x) => x.customerId)
            .includes(this.profile[role.value + "Id"])
        ) {
          result.push({
            role: role.name,
            organisationId: this.profile[role.value + "Id"],
            organisation: this.profile[role.value],
          });
        }
      });
      return result.filter(
        (x) => x.organisationId != this.$store.state.currentOrg.id
      );
    },
    
    customers() {
      let organisations = this.contacts.map((x) => x.relatedOrganisation);
      let result = this.profile.consigneeProfileRelationships.map((x) => ({
        ...x,
        organisation: organisations.find((y) => y.id == x.customerId),
        organisationId: x.customerId,
      }));
      return result;
    },
    filterOrganisations() {
      let result = this.contacts;
      if (this.searchOrganisation) {
        result = result.filter((x) => {
          return x.relatedOrganisation.name
            .toLowerCase()
            .includes(this.searchOrganisation.toLowerCase());
        });
      }
      return result;
    },
  },
  methods: {
    async addCustomer(relationshipId, organisationId) {
      this.savingCustomer = true;
      if (this.updateCustomer) {
        let find =
          this.profile.consigneeProfileRelationships[this.currentIndex];
        let result = await this.$API.updateConsigneeProfileRelationship({
          customerId: organisationId,
          id: find.id,
        });
        this.$emit("refreshCustomer", result);
      } else {
        if (this.profile.id) {
          let result = await this.$API.createConsigneeProfileRelationship({
            customerId: organisationId,
            consigneeProfileId: this.profile.id,
            organisationRelationshipId: relationshipId,
          });
          this.$emit("refreshCustomer", result);
        } else {
          this.profile.consigneeProfileRelationships.push({
            customerId: organisationId,
            consigneeProfileId: this.profile.id,
          });
          this.$emit("addCustomer", {
            customerId: organisationId,
            consigneeProfileId: this.profile.id,
          });
        }
      }
      this.refreshCustomerKey++;
      this.organisationDialog = false;
      this.updateCustomer = false;
      this.searchOrganisation = null;
      this.savingCustomer = false;
    },
    async editShareSetting(item){
      let findExisting = item.shareItem ? item.shareItem : this.profile.consigneeProfileShares.find(x => x.sharedPartyId == item.organisationId)
      if(this.profile.id){
        if(findExisting){
        let result = await this.$API.updateConsigneeProfileShareSetting(this.profile.id,{
          id: findExisting.id,
          share: item.share
        })
        if(result){
          this.profile.consigneeProfileShares.push(result)
        }
      } else {
        let result = await this.$API.createConsigneeProfileShareSetting({
          consigneeProfileId: this.profile.id,
          sharedPartyId: item.organisationId,
          share: item.share
        })
        if(result){
          this.profile.consigneeProfileShares.push(result)
        }
      }
      }
      
    },
    getClientStatusColor(status) {
      switch (status) {
        case "GOOD STANDING":
          return "green";
        case "INACTIVE":
          return "red";
        case "ON HOLD":
          return "orange";
        case "COD":
          return "blue";
      }
    },
    async removeCustomer(index) {
      let find = this.profile.consigneeProfileRelationships[index];
      if (find && find.id) {
        await this.$API.updateConsigneeProfileRelationship({
          id: find.id,
          isActive: false,
          isDeleted: true,
        });
        this.$emit("refreshCustomer", find.id);
        this.refreshCustomerKey++;
      } else {
        this.profile.consigneeProfileRelationships.splice(index, 1);
        this.refreshCustomerKey++;
      }
      // console.log(this.profile.consigneeProfileRelationships)
    },
    setUniqueOrganisations() {
      let result = [];
      this.roles.forEach((role) => {
        if (
          this.profile[role.value]
        ) {
          result.push({
            role: role.name,
            organisationId: this.profile[role.value + "Id"],
            organisation: this.profile[role.value],
          });
        }
      });
      let orgs = [...new Set(result.map((x) => x.organisationId))].filter(x=> x!= this.profile.ownerId)
      result = orgs.map((x) => result.find((y) => y.organisationId == x))
      result.forEach(x=>{
        x.shareItem = this.profile.consigneeProfileShares ? this.profile.consigneeProfileShares.find(y=>y.sharedPartyId == x.organisationId):null
        x.share = x.shareItem?x.shareItem.share:false
      })
      result.sort((a, b) => a.organisation.name.localeCompare(b.organisation.name))
     this.uniqueOrganisations = result
    },
  },
};
</script>