<template>
  <div>
    <v-card :loading="loadingBilling" flat>
      <v-card-text class="my-0 py-0">
        <v-list subheader dense>
          <v-subheader style="font-size: 16px">
            <v-icon color="grey" class="mr-2">payment</v-icon>
            Costing Sheet
          </v-subheader>
          <v-divider></v-divider>
          <CostingSheet
            :financeChargeItems="financeChargeItems"
            :baseCurrency="'USD'"
            :consigneeProfileId="consigneeProfileId"
            :billingItems="billingItems"
            :disableDate="true"
            :organisations="organisations"
          />
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import CostingSheet from "../../Finance/components/CostingSheet.vue";
export default {
  props: ["consigneeProfileId"],
  components: {
    CostingSheet,
  },
  data() {
    return {
      loadingBilling: false,
      financeChargeItems: [],
      billingItems: [],
      organisations: {data: []},
    };
  },
  watch: {
    consigneeProfileId: {
      handler: function (val) {
        if (val) {
          this.loadBillingItems(val);
        }
      },
      immediate: true,
    },
  },
  created() {
    this.getFinanceChargeItems();
    this.getRelatedOrganisations();
  },
  methods: {
    async getFinanceChargeItems() {
      this.financeChargeItems = await this.$API.getFinanceChargeItems();
    },
    async getRelatedOrganisations() {
      this.organisations = await this.$API.getRelationBasic({});
    },
    async loadBillingItems(id) {
      this.loadingBilling = true;
      this.billingItems = await this.$API.getConsigneeProfileBillingItems(id);
      this.loadingBilling = false;
    },
  },
};
</script>