<template>
  <div>
    <table
      style="
        table-layout: auto;
        width: 100%;
        max-width: 100%;
        font-size: 12px;
        border: solid 0.5px;
        border-color: grey;
        border-collapse: collapse;
      "
      cellpadding="10"
    >
      <thead style="border-bottom: 0.5px solid grey">
        <th style="border-right: 0.25px solid grey; width: 28%;">Item</th>
        <th style="border-right: 0.25px solid grey; width: 11%;">Note</th>
        <th style="border-right: 0.25px solid grey; width: 11%;">Supplier</th>
        <th style="border-right: 0.25px solid grey; width: 11%;">Reference</th>
        <th
          style="border-right: 0.25px solid grey; width: 8%;"
        >
          Date
        </th>
        <th style="border-right: 0.25px solid grey; width: 6%;">Level</th>
        <th style="border-right: 0.25px solid grey; width: 6%;">Currency</th>
        <th style="border-right: 0.25px solid grey; width: 11%;">Amount</th>
        <th style="width: 8%;">Base Amount</th>
      </thead>
      <tbody v-for="(category, index) in categories" :key="index">
        <tr
          style="
            width: 100%;
            background-color: var(--v-toolbar-lighten3) !important;
          "
        >
          <td style="font-weight: bold; font-size: 14px">
              <v-chip
                class="my-1"
                outlined
                style="border: none"
                small
                @click="addBillingItem(category, null)"
              >
                {{ category}}
              </v-chip>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>

          <td></td>
          <td></td>
          <td class="text-right">
            <!-- {{ getSymbol("EUR") }} {{ categoryTotal("Origin", category) }} -->
          </td>
        </tr>
        <tr>
          <td :colspan="9">
            <table
              v-for="(subcategory, index) in filterCategories(category)"
              style="width: 100%; table-layout: fixed; font-size: 12px"
              :key="index"
            >
              <tr style="width: 100%; margin-top: 5px">
                <td
                  style="
                    font-weight: bold;
                    font-size: 14px;
                    border-top: 0.25px solid grey;
                  "
                >
                  <span>
                    <v-chip
                      class="mt-1"
                      outlined
                      style="border: none"
                      small
                      @click="addBillingItem(category, subcategory)"
                    >
                      <v-icon small left color="primary">add</v-icon>
                      {{ subcategory.name }} 
                    </v-chip>
                  </span>
                </td>
              </tr>
              <table            
                v-for="(cost, index) in filterCosts(
                  category,
                  subcategory.id,
                  null
                )"
                :key="index"
                style="width: 100%; table-layout: fixed; overflow-x: auto;"
              >
                <tr style="font-size: 11px">
                  <td class="text-left px-1 ml-2" style="width: 28%;">
                    <span>
                      <v-tooltip top v-if="!organisationRelationshipId && cost.organisationRelationshipId">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            
                            color="teal"
                            small
                            class="mr-1"
                            v-on="on"
                          >
                            handshake
                          </v-icon>
                        </template>
                        <span>
                          Inherited from Relationship Settings
                        </span>
                      </v-tooltip>
                      <v-tooltip top v-else-if="!consigneeProfileId&& cost.consigneeProfileId">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            
                            color="orange"
                            small
                            class="mr-1"
                            v-on="on"
                          >
                            groups
                          </v-icon>
                        </template>
                        <span>
                          Inherited from Shipment Profile
                        </span>
                      </v-tooltip>
                      <v-chip
                        small
                        outlined
                        style="border: none"
                        @click="editBillingItem(cost)"
                      >
                      {{ cost.finChargeItem.name }}
                      {{ cost.calculationType === 'PERCENTAGE' && cost.calculationValue ? ` @ ${cost.calculationValue}%` : '' }}
                      </v-chip>
                      <v-chip
                        x-small
                        class="mx-1"
                        v-if="
                          !cost.parentBillingItemId && cost.subItemCount > 0
                        "
                      >
                        {{ cost.subItemCount }}
                      </v-chip>
                      <v-btn
                        icon
                        v-if="
                          !cost.parentBillingItemId && cost.subItemCount > 0
                        "
                        small
                        class="ml-1"
                        @click="expandSubcategory(cost.id)"
                      >
                        <v-icon v-if="!expanded.includes(cost.id)" x-small
                          >expand_more</v-icon
                        >
                        <v-icon v-else x-small>expand_less</v-icon>
                      </v-btn>
                    </span>
                  </td>
                  <td class="text-left px-1 ml-2" style="width: 11%;">
                    <span>
                        {{ cost.reason }}
                    </span>
                  </td>
                  <td class="text-left px-1" style="width: 11%;">
                    <v-chip
                      small
                      outlined
                      style="border: none"
                      v-if="cost.supplier"
                    >
                      <v-avatar
                        left
                        :color="cost.supplier.logo ? 'white' : 'secondary'"
                        size="24"
                      >
                        <v-img
                          v-if="cost.supplier.logo"
                          :src="cost.supplier.logo"
                          contain
                        ></v-img>
                        <h3 v-else>
                          {{ cost.supplier.name.charAt(0) }}
                        </h3>
                      </v-avatar>
                      {{ cost.supplier.alias ? cost.supplier.alias : cost.supplier.name }}
                    </v-chip>
                  </td>
                  <td class="text-center px-1" style="width: 11%;">
                    {{ cost.reference }}
                  </td>
                  <td class="text-center px-1" style="width: 8%;">
                    {{ cost.date }}
                  </td>
                  <td class="text-center px-1" style="width: 6%;">
                    <v-tooltip top>
                      <template v-slot:activator="{on}">
                        <v-chip v-on="on" outlined small class="primaryText--text text-center" v-if="cost.level === 'SHIPMENT'">
                              <v-icon >directions_boat</v-icon>
                        </v-chip>
                        <v-chip v-on="on" outlined small class="primaryText--text text-center" v-else-if="cost.level === 'CONTAINER'">
                              <v-icon color="orange">widgets</v-icon>
                        </v-chip>
                        <v-chip v-on="on" outlined small class="primaryText--text text-center" v-else-if="cost.level === 'DOCUMENT'">
                              <v-icon color="blue">assignment</v-icon>
                        </v-chip>
                        <v-chip v-on="on" outlined small class="primaryText--text text-center" v-else-if="cost.level === 'PALLET'">
                              <v-icon color="blue">pallet</v-icon>
                        </v-chip>
                        <v-chip v-on="on" outlined small class="primaryText--text text-center" v-else>
                          {{ truncatedLevel(cost.level) }}
                        </v-chip>
                      </template>
                      <span>{{cost.level}}</span>
                    </v-tooltip>
                  </td>
                  <td class="text-center px-1" style="width: 6%;">
                    {{ cost.currency }}
                  </td>
                  <td class="text-right px-1" style="width: 11%;">
                    {{ getSymbol(cost.currency) }}
                    {{ formatNumber(cost.amount) }}
                  </td>
                  <td class="text-right mr-1 pr-1" style="width: 8%;">
                    {{ getSymbol(cost.baseCurrency) }}
                    {{ formatNumber(cost.baseAmount) }}
                  </td>
                </tr>
                <tr>
                  <td :colspan="9">
                    <table
                      style="width: 100%; table-layout: auto"
                      :key="itemsKey"
                      v-if="expanded.includes(cost.id)"
                    >
                      <tr
                        v-for="(subCost, index) in filterCosts(
                          category,
                          subcategory.id,
                          cost.id
                        )"
                        :key="index"
                        style="font-size: 11px"
                        
                      >
                        <td class="text-right px-1 ml-2" style="width: 28%;">                       
                          <span>
                            •
                            {{ subCost.bookingContainer.ctoNo }}
                            <span v-if="subCost.bookingContainer.containerNo"
                              >:
                              {{ subCost.bookingContainer.containerNo }}</span
                            >
                          </span>
                        </td>
                        <td class="text-left px-1 ml-2" style="width: 11%;">
                          <span>
                              {{ subCost.reason }}
                          </span>
                        </td>
                        <td class="text-center px-1" style="width: 11%;">
                          <v-chip
                            small
                            outlined
                            style="border: none"
                            v-if="subCost.supplier"
                          >
                            <v-avatar
                              left
                              :color="subCost.supplier.logo ? 'white' : 'secondary'"
                              size="24"
                            >
                              <v-img
                                v-if="subCost.supplier.logo"
                                :src="subCost.supplier.logo"
                                contain
                              ></v-img>
                              <h3 v-else>
                                {{ subCost.supplier.name.charAt(0) }}
                              </h3>
                            </v-avatar>
                            {{ subCost.supplier.alias ? subCost.supplier.alias : subCost.supplier.name }}
                          </v-chip>
                        </td>
                        <td class="text-center px-1" style="width: 11%;">
                          {{ subCost.reference }}
                        </td>
                        <td class="text-center px-1" style="width: 8%;" v-if="!disableDate"
                        >
                          {{ subCost.date }}
                        </td>
                        <td class="text-center px-1" style="width: 8%;" v-if="disableDate"
                        ></td>
                        <td class="text-center px-1" style="width: 6%;">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-chip v-on="on" outlined small class="primaryText--text text-center" v-if="subCost.level === 'SHIPMENT'">
                                <v-icon>directions_boat</v-icon>
                              </v-chip>
                              <v-chip v-on="on" outlined small class="primaryText--text text-center" v-else-if="subCost.level === 'CONTAINER'">
                                <v-icon color="orange">widgets</v-icon>
                              </v-chip>
                              <v-chip v-on="on" outlined small class="primaryText--text text-center" v-else-if="subCost.level === 'DOCUMENT'">
                                <v-icon color="blue">assignment</v-icon>
                              </v-chip>
                              <v-chip v-on="on" outlined small class="primaryText--text text-center" v-else-if="subCost.level === 'PALLET'">
                                <v-icon color="blue">pallet</v-icon>
                              </v-chip>
                              <v-chip v-on="on" outlined small class="primaryText--text text-center" v-else>{{ truncatedLevel(subCost.level) }}
                              </v-chip>
                            </template>
                            <span>{{ subCost.level }}</span>
                          </v-tooltip>
                        </td>
                        <td class="text-center px-1" style="width: 6%;">
                          {{ subCost.currency }}
                        </td>
                        <td class="text-right px-1" style="width: 11%;">
                          {{ getSymbol(subCost.currency) }}
                          {{ formatNumber(subCost.amount) }}
                        </td>
                        <td class="text-right mr-1 pr-1" style="width: 8%;">
                          {{ getSymbol(subCost.baseCurrency) }}
                          {{ formatNumber(subCost.baseAmount) }}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <!-- <tr style="width: 100%">
                            <td style="border-top: 0.25px solid grey" colspan="5"></td>
                            </tr> -->
              </table>
            </table>
          </td>
        </tr>
      </tbody>
    </table>

    <v-dialog v-model="billingItemModal" width="58%" :content-class="documentViewModal ? 'dialogRight' : ''">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            <span v-if="billingItem.id">Edit</span>
            <span v-else>Add</span>
            Billing Item</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="primary"
            @click="saveBillingItem"
            :loading="savingBillingItem"
            :disabled="invalidBillingItem(billingItem)"
          >
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn
            color="red"
            icon
            v-if="billingItem.id"
            @click="confirmDeleteBilling = true"
          >
            <v-icon>delete</v-icon>
          </v-btn>
          <v-btn @click="billingItemModal = false" text>X</v-btn>
        </v-toolbar>
        <v-card-subtitle>
          {{ billingItem.category }} - 
          <v-btn v-if="billingItem.subcategory" text small style="text-transform: none" @click="clearCategory()">{{ billingItem.subcategory }} x</v-btn>
          
        </v-card-subtitle>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" md="9">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Reference"
                    v-model="billingItem.reference"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" v-if="orderDocuments">
                  <v-autocomplete
                    :items="orderDocuments"
                    label="Document"
                    outlined
                    dense
                    hide-details
                    item-text="name"
                    item-value="id"
                    v-model="billingItem.shipmentDocumentId"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="mb-0 pb-0">
                  <v-menu
                    v-if="!disableDate"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-list-item
                        v-on="on"
                        class="text-left"
                        style="height: 40px"
                      >
                        <v-list-item-action>
                          <v-icon color="grey" small>today</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 12px">
                            {{ formatDate(billingItem.date) }}
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 12px">
                            Date
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <v-date-picker
                      v-model="billingItem.date"
                      no-title
                      scrollable
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" v-if="organisations">
                  <v-autocomplete
                    label="Supplier"
                    class="mb-0 pb-0"
                    :items="organisations.data"
                    item-text="relatedOrganisation.name"
                    item-value="relatedOrganisation.id"
                    hide-details
                    outlined
                    v-model="billingItem.supplierId"
                    clearable
                    dense
                  >
                    <template v-slot:selection="data">
                      <v-row align="center">
                        <v-avatar
                          size="32"
                          :color="
                            data.item && data.item.relatedOrganisation.logo
                              ? 'white'
                              : 'secondary'
                          "
                          class="mr-2"
                          v-if="data.item"
                        >
                          <v-img
                            v-if="data.item.relatedOrganisation.logo"
                            contain
                            :src="data.item.relatedOrganisation.logo"
                          ></v-img>
                          <h3 v-else>
                            {{ data.item.relatedOrganisation.name.charAt(0) }}
                          </h3>
                        </v-avatar>
                        <span v-if="data.item.relatedOrganisation">
                          {{ data.item.relatedOrganisation.name }}
                        </span>
                      </v-row>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-action>
                        <v-avatar
                          size="32"
                          :color="
                            data.item.relatedOrganisation.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            contain
                            v-if="data.item.relatedOrganisation.logo"
                            :src="data.item.relatedOrganisation.logo"
                          ></v-img>
                          <h3 v-else>
                            {{ data.item.relatedOrganisation.name.charAt(0) }}
                          </h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          data.item.relatedOrganisation.name
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          v-if="data.item.relatedOrganisation.alias"
                          >{{
                            data.item.relatedOrganisation.alias
                          }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    :items="
                      billingItem.finChargeCategoryId ? financeChargeItems.filter(
                        (x) =>
                          x.finChargeCategoryId ==
                          billingItem.finChargeCategoryId
                      ) : financeChargeItems
                    "
                    label="Finance Item*"
                    outlined
                    dense
                    hide-details
                    item-text="name"
                    item-value="id"
                    v-model="billingItem.finChargeItemId"
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>{{
                          data.item.name
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >{{ data.item.finChargeCategory.category }}
                          <span v-if="data.item.finChargeSubCategory"
                            >: {{ data.item.finChargeSubCategory.subCategory }}
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-row>
                    <v-col cols="12" sm="4" class="mr-0 pr-0">
                      <v-autocomplete
                        label="Currency"
                        outlined
                        dense
                        :disabled="billingItem.calculationType === 'PERCENTAGE'"
                        hide-details
                        :items="currencies"
                        v-model="billingItem.currency"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" :sm="billingItem.currency !== billingItem.baseCurrency ? 4 : 8" class="ml-0 pl-0">
                      <v-text-field 
                        v-if="billingItem.level !== 'SHIPMENT'"
                        label="Amount*"
                        outlined
                        :disabled="billingItem.calculationType === 'PERCENTAGE'"
                        dense
                        hide-details
                        number
                        :key="unKey"
                        v-model="billingItem.unitAmount"
                      ></v-text-field>
                      
                      <v-text-field 
                        v-if="billingItem.level === 'SHIPMENT'"
                        label="Amount*"
                        outlined
                        :disabled="billingItem.calculationType === 'PERCENTAGE'"
                        dense
                        hide-details
                        number
                        v-model="billingItem.amount"
                      ></v-text-field>
                     
                    </v-col>
                    <v-col v-if="billingItem.currency !== billingItem.baseCurrency" cols="12" sm="4" class="ml-0 pl-0">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            disabled
                            label="Current Rate"
                            outlined
                            dense
                            v-on="on"
                            hide-details
                            :loading="loadingCurrency"
                            v-model.number="currentExchangeRate"
                          ></v-text-field>
                        </template>
                        <span>Live rate for: {{this.billingItem.baseCurrency}}/{{this.billingItem.currency}} (+/- 5 min delay)</span>
                      </v-tooltip>
                      
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-row
                    v-if="billingItem.currency != billingItem.baseCurrency"
                  >
                    <v-col cols="12" sm="4" class="mr-0 pr-0">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-on="on"
                            label="Rate"
                            outlined
                            dense
                            hide-details
                            v-model.number="factoredRate"
                          >
                          <template v-slot:prepend-inner>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="applyCurrentRate">arrow_forward</v-icon>
                              </template>
                              <span>Apply Current rate</span>
                            </v-tooltip>
                          </template>
                        </v-text-field>
                        </template>
                        <span>Apply your factored rate here</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12" sm="4" class="mr-0 pr-0">
                      <v-autocomplete
                        disabled
                        label="Currency"
                        outlined
                        dense
                        hide-details
                        :items="currencies"
                        v-model="billingItem.baseCurrency"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4" class="ml-0 pl-0">
                      <v-text-field 
                        v-if="billingItem.level === 'SHIPMENT'"
                        label="Base Amount*"
                        outlined
                        dense
                        hide-details
                        number
                        :key="fctKey"
                        v-model="billingItem.baseAmount"
                      ></v-text-field>
                      <v-text-field
                        v-if="billingItem.level !== 'SHIPMENT'"
                        label="Base Amount*"
                        outlined
                        dense
                        hide-details
                        number
                        :key="baKey"
                        v-model="billingItem.baseUnitAmount"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-textarea
                    class="mt-0"
                    label="Notes"
                    v-model="billingItem.reason"
                    outlined
                    rows="5"
                    auto-grow
                  ></v-textarea>
                  
                </v-col>
               
                <v-col cols="12" sm="3" class="mt-1 pr-5">
                  <v-row >
                    <v-select
                      label="Calculation Type*"
                      class="mt-2 ml-3 text-right"
                      outlined
                      hide-details
                      dense
                      value="'FIXED'"
                      :items="calculationTypes"
                      v-model="billingItem.calculationType">
                     </v-select>
                  </v-row>
                  <v-row v-if="billingItem.calculationType === 'PERCENTAGE'">
                    <v-text-field
                      label="Calculation Value*"
                      class="mt-2 ml-3 text-right"
                      outlined
                      suffix="%"
                      hide-details
                      dense
                      v-model="billingItem.calculationValue">
                    </v-text-field>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="3" class="mt-1 pr-5">
                  <v-row>

                    <v-select
                    label="Level*"
                    outlined
                    class="mt-2 ml-3 text-right"
                    hide-details
                    dense
                    value="'SHIPMENT'"
                    :items="levelTypes"
                    v-model="billingItem.level">
                  </v-select>
                  </v-row>
                  <v-row v-if="billingItem.calculationType === 'PERCENTAGE'">
                    <v-select
                      label="Calculated on*"
                      class="mt-2 ml-3 text-right"
                      outlined
                      hide-details
                      value="'SALE'"
                      :items="onTypes"
                      dense
                      v-model="billingItem.calculatedOn"></v-select>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="3"
              v-if="
                billingItem &&
                billingItem.childItems &&
                containers &&
                containers.length > 0 && 
                billingItem.level === 'CONTAINER'
              "
            >
              <v-list
                dense
                subheader
                v-if="
                  billingItem &&
                  billingItem.childItems &&
                  containers &&
                  containers.length > 0 && 
                  billingItem.level === 'CONTAINER'
                "
              >
                <v-subheader style="font-size: 16px"
                  >Order Items
                  <v-spacer></v-spacer>
                  <v-tooltip
                    top
                    v-if="billingItem.childItems.length < containers.length"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        icon
                        color="primary"
                        @click="selectAllOrderItems()"
                      >
                        <v-icon small>check</v-icon>
                      </v-btn>
                    </template>
                    <span>Select All</span>
                  </v-tooltip>

                  <v-tooltip top v-if="billingItem.childItems.length > 0">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        icon
                        color="red"
                        @click="deselectAllOrderItems()"
                      >
                        <v-icon small>cancel</v-icon>
                      </v-btn>
                    </template>
                    <span>Deselect All</span>
                  </v-tooltip>
                </v-subheader>
                <v-divider></v-divider>
                <v-list dense style="max-height: 50vh; overflow-y: auto">
                  <v-list-item
                    v-for="container in containers"
                    :key="container.id"
                    @click="selectOrderItem(container.id)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{
                        container.ctoNo
                      }}</v-list-item-title>
                      <v-list-item-subtitle v-if="container.containerNo">{{
                        container.containerNo
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action :key="containerKey">
                      <v-icon
                        v-if="billingItem.childItems.includes(container.id)"
                        color="green"
                        small
                        >check_circle_outline</v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDeleteBilling" width="400px" persistent>
      <v-card>
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <!-- <v-btn text @click="confirmSelectedPointModal = false">X</v-btn> -->
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 14px">
            Are you sure you would to delete this cost item?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="confirmDeleteBilling = false"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              :loading="deletingCostItem"
              @click="deleteCostItem()"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

   
  </div>
</template>
<script>
import * as CurrencyCodes from "currency-codes";
import getSymbolFromCurrency from "currency-symbol-map";
import dateFormat from "dateformat";
export default {
  props: [
    "financeChargeItems",
    "billingItems",
    "orderId",
    "consigneeProfileId",
    "organisationRelationshipId",
    "documentItem",
    "baseCurrency",
    "containers",
    "orderDocuments",
    "organisations",
    "disableDate",
    "organisationId",
    "toggleScaffolding",
    "documentViewModal"
  ],
  data: () => ({
    currentExchangeRate: 1,
    unKey: 0,
    canUpdateAmounts: false, // Temporary
    decimalPrimary: 4,
    decimalSecondary: 2,
    fctKey: 0,
    itemsKey: 0,
    baKey: 0,
    levelTypes: ['SHIPMENT', 'CONTAINER'],
    onTypes: ['COST', 'SALE'],
    calculationTypes: ['FIXED','PERCENTAGE'],
    loadingCurrency: false,
    inverseExchangeRate: 1,
    factoredRate: 1,
    billingItem: {},
    billingItemModal: false,
    categories: ["Origin", "Freight", "Destination"],
    subcategories: [
      "Transport",
      "Documentation",
      "Statutory",
      "Storage",
      "Insurance",
      "Terminal Handling Charges",
      "Commissions",
      "Miscellaneous",
    ],
    confirmDeleteBilling: false,
    containerKey: 100,
    currencies: CurrencyCodes.codes(),
    deletingCostItem: false,
    expanded: [],
    savingBillingItem: false,
    prevLevel: null,
  }),
  watch: {
    "billingItem.unitAmount" : {
      immediate: true,
      handler: function(val) {
        if(val) {
          if(this.billingItem.level !== 'SHIPMENT') {
            if(this.billingItem.currency !== this.billingItem.baseCurrency) {
              if(this.factoredRate) {
                this.billingItem.baseUnitAmount = parseFloat((this.billingItem.unitAmount / this.factoredRate).toFixed(2))
                this.baKey++
              }
            } else  {
              this.billingItem.baseUnitAmount = this.billingItem.unitAmount
            }
          } 
        }
      }
    },
    "billingItem.level": {
      immediate: true,
      handler: function(val) {
        if(val) {
          if(this.billingItem.level === 'SHIPMENT') {
              this.selectAllOrderItems()
            this.prevLevel = this.billingItem.level
          } else {
            if(this.prevLevel === 'SHIPMENT') {
                this.deselectAllOrderItems()
              this.prevLevel = this.billingItem.level
            } else {
              this.prevLevel = this.billingItem.level
            }
          }
        }
      }
    },
    // "canUpdateAmounts": {
    //   immediate: true,
    //   handler: function(val) {
    //     if(val) {
    //       console.log('canUpdateAmounts changed')
    //     }
    //   }
    // },
    // "billingItem.baseUnitAmount": {
    //   immediate: true,
    //   handler: function(val) {
    //     if(val) {
    //       console.log('billingItem.baseUnitAmount changed')
    //       console.log(this.billingItem.baseUnitAmount)
    //     }
    //   }
    // },
    "billingItem.amount": {
      immediate: true,
      handler: function (val) {
        if (val) {
          if(this.factoredRate !== 1) {
            if (this.billingItem.baseCurrency == this.billingItem.currency && this.canUpdateAmounts) {
              this.billingItem.baseAmount = this.billingItem.amount;
            } else if(this.billingItem.currency !== this.billingItem.baseCurrency && this.canUpdateAmounts) {
              if(this.factoredRate && this.canUpdateAmounts) {
                  this.billingItem.baseAmount = parseFloat((this.billingItem.amount / this.factoredRate).toFixed(this.decimalSecondary))
              }
            }
          } else {
            this.billingItem.baseAmount = this.billingItem.amount
          }
        }
      },
    },
    "billingItem.currency": {
      immediate: true,
      handler: function (val) {
        if (val) {
          if(this.billingItem.currency !== this.billingItem.baseCurrency) {
            this.getCurrentRate(this.billingItem.baseCurrency, this.billingItem.currency)
          }
        }
      },
    },
    "factoredRate": {
      immediate: true,
      handler: function (val) {
        if (val) {
          if(this.billingItem.currency !== this.billingItem.baseCurrency && this.canUpdateAmounts) {
            if(this.billingItem.level !== 'SHIPMENT' && this.billingItem.unitAmount && this.factoredRate) {
              this.billingItem.exchangeRate = parseFloat(( 1 / this.factoredRate))
              this.billingItem.baseUnitAmount = parseFloat((this.billingItem.unitAmount / this.factoredRate).toFixed(this.decimalSecondary))
              this.baKey++
            } else {
              // this.inverseExchangeRate = parseFloat(( 1 / this.factoredRate))
              this.billingItem.exchangeRate = parseFloat(( 1 / this.factoredRate))
              this.billingItem.baseAmount = parseFloat((this.billingItem.amount / this.factoredRate).toFixed(this.decimalSecondary))
              this.fctKey++
            }
          }
        }
      },
    },
  },
  methods: {
    truncatedLevel(level) {
      return level.substring(0, 4) + '.';
    },
    addBillingItem(
      category,
      subcategory,
      documentId = null,
      date = null,
      reference = null
    ) {
      documentId = this.documentItem ? this.documentItem.id : null;
      reference = this.documentItem ? this.documentItem.reference : null;
      date = this.documentItem ? this.documentItem.date : null;
      this.billingItem = {
        category,
        finChargeCategoryId: subcategory ? subcategory.id: null,
        subcategory: subcategory? subcategory.name: null,
        bookingId: this.orderId,
        consigneeProfileId: this.consigneeProfileId,
        organisationRelationshipId: this.organisationRelationshipId,
        currency: this.baseCurrency,
        level: null,
        baseCurrency: this.baseCurrency,
        shipmentDocumentId: documentId,
        date: date ? date : new Date().toISOString().split("T")[0],
        reference: reference,
        childItems: [],
      };
      if (this.disableDate) {
        delete this.billingItem.date;
      }
      this.canUpdateAmounts = true
      this.billingItemModal = true;
    },
    clearCategory() {
      this.billingItem.subcategory = null;
      this.billingItem.finChargeCategoryId = null;
    },
    async deleteCostItem() {
      this.deletingCostItem = true;
      await this.$API.updateOrderBillingItems({
        id: this.billingItem.id,
        isActive: false,
        isDeleted: true,
      });
      this.deletingCostItem = false;
      this.confirmDeleteBilling = false;
      let index = this.billingItems.findIndex(
        (x) => x.id == this.billingItem.id
      );
      this.billingItems.splice(index, 1);
      if(this.billingItem.subItemCount > 0) {
        let childIndexes = []
        for(const item of this.billingItems) {
          let index = this.billingItems.findIndex(
            (x) => x.parentBillingItemId === this.billingItem.id
          );
          childIndexes.push(index)
        }
        for(const index of childIndexes) {
          this.billingItems.splice(index, 1);
        }
      }
      this.billingItemModal = false;
      this.$message.success("Successfully removed cost item!");
    },
    filterCategories(type) {
      
      if(!this.toggleScaffolding) {
        let items = this.financeChargeItems
          .filter((x) => x["is" + type])
          .map((x) => x.finChargeCategory);
        let uniqueCategories = [...new Set(items.map((x) => x.id))];
        let result = uniqueCategories
          .map((x) => items.find((y) => y.id == x))
          .map((x) => ({ id: x.id, name: x.category }));
        result.sort((a, b) => a.name.localeCompare(b.name));
        return result;
      } else {
        let filteredCategories = [];
        let uniqueCategories = new Set();
        for (const item of this.billingItems) {
          if(this.documentItem) {
            let matchingItems = this.financeChargeItems
              // .filter((x) => x["is" + type])
              .filter((x) => x.id === item.finChargeItemId && item.category === type && item.shipmentDocumentId === this.documentItem.id)
              .map((x) => x.finChargeCategory)
            
            // if (matchingItems.length > 0 && type === 'Destination') {
            //   console.log('Matched billingItem:', item);
            // }
  
            filteredCategories.push(...matchingItems);
            matchingItems.forEach(x => uniqueCategories.add(x.id));
          } else {
            let matchingItems = this.financeChargeItems
              // .filter((x) => x["is" + type])
              .filter((x) => x.id === item.finChargeItemId && item.category === type && item.isActive)
              .map((x) => x.finChargeCategory)
            
            // if (matchingItems.length > 0 && type === 'Destination') {
            //   console.log('Matched billingItem:', item);
            // }
  
            filteredCategories.push(...matchingItems);
            matchingItems.forEach(x => uniqueCategories.add(x.id));
          }
        }
        uniqueCategories = Array.from(uniqueCategories);
        let result = uniqueCategories
          .map((id) => filteredCategories.find((y) => y.id == id))
          .map((x) => ({ id: x.id, name: x.category }));

        result.sort((a, b) => a.name.localeCompare(b.name));
        return result;
      }
    },
    editBillingItem(item) {
      if(item.type == 'RELATIONSHIP' && this.consigneeProfileId){
        this.$message.error('Cannot update item inherited from Relationship Settings')
      } else {
        this.billingItem = item;
        
        if(item.baseCurrency !== item.currency && item.exchangeRate) {
          this.factoredRate = parseFloat((1 / item.exchangeRate).toFixed(4))
        }
        this.billingItem.childItems = this.billingItems
        .filter((x) => x.parentBillingItemId == item.id)
        .map((x) => x.bookingContainerId);
        // if(this.billingItem.level !== "SHIPMENT" && this.billingItem.baseUnitAmount && this.billingItem.childItems) {
          // for(const item of this.billingItems) {
          //   if(item.parentBillingItemId === this.billingItem.id) {
          //     this.billingItem.unitAmount = item.amount
          //     this.unKey++
          //     break
          //   }
          // } 
        // }
        this.canUpdateAmounts = true
      this.billingItemModal = true;
      }
      
    },
    expandSubcategory(id) {
      if (this.expanded.includes(id)) {
        this.expanded = this.expanded.filter((exp) => exp !== id);
      } else {
        this.expanded.push(id);
      }
    },
    filterCosts(category, subcategory, parentItemId, shipmentDocumentId) {
      shipmentDocumentId = this.documentItem ? this.documentItem.id : null;
      
      let result = this.billingItems.filter(
        (cost) =>
          cost.category === category &&
          cost.finChargeCategoryId == subcategory &&
          cost.parentBillingItemId === parentItemId
      );
      if (this.filterBookingContainerId) {
        result = this.billingItems.filter(
          (cost) =>
            cost.category === category &&
            cost.finChargeCategoryId == subcategory &&
            // cost.parentBillingItemId === parentItemId
            cost.bookingContainerId == this.filterBookingContainerId
        );
      }
      if (shipmentDocumentId) {
        result = result.filter(
          (x) => x.shipmentDocumentId == shipmentDocumentId
        );
      }

      return result;
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
      }
      return result;
    },
    formatNumber(x, isCost = false) {
      if (isCost) {
        x *= -1;
      }
      if (x) {
        let result = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return result;
      } else {
        return null;
      }
    },
    getSymbol(currencyCode) {
      return getSymbolFromCurrency(currencyCode);
    },
    selectAllOrderItems() {
      this.billingItem.childItems = this.containers.map(
        (container) => container.id
      );
      this.containerKey++;
    },
    deselectAllOrderItems() {
      this.billingItem.childItems = [];
      this.containerKey++;
    },
    selectOrderItem(id) {
      if (this.billingItem.childItems.includes(id)) {
        this.billingItem.childItems = this.billingItem.childItems.filter(
          (container) => container !== id
        );
      } else {
        this.billingItem.childItems.push(id);
      }
      this.containerKey++;
    },
    async saveBillingItem() {
      this.savingBillingItem = true;
      if(this.billingItem.supplierId && this.organisations){
        this.billingItem.supplier = this.organisations.data.find(x => x.relatedOrganisation.id == this.billingItem.supplierId).relatedOrganisation
      }
      let financeItem = this.financeChargeItems.find(
        (x) => x.id == this.billingItem.finChargeItemId
      );
      this.billingItem.finChargeCategoryId = financeItem.finChargeCategoryId;
        this.billingItem.subcategory = financeItem.finChargeCategory.category;
      if (this.orderId) {
        if (this.billingItem.id) {
          let result = await this.$API.updateOrderBillingItems(
            this.billingItem
          );
          this.removeOldItems(result)
          result.forEach((item) => {
            let index = this.billingItems.findIndex((x) => x.id == item.id);
            if (index > -1) {
              this.billingItems.splice(index, 1, item);
            } else {
              this.billingItems.push(item);
            }
          });
          let newbillingItems = this.billingItems
          this.$emit("updateBillingItems", newbillingItems)
          
        } else {
          let result = await this.$API.createOrderBillingItems(
            this.billingItem
          );
          this.billingItems = [...this.billingItems, ...result];
          let newbillingItems = this.billingItems
          this.$emit("updateBillingItems", newbillingItems)
          // this.billingItems.push(result)
        }
      } else if (this.organisationRelationshipId) {
        this.billingItem.supplierId = this.organisationId;
        if (this.billingItem.id) {
          let result = await this.$API.updateRelationshipBillingItem(
            this.billingItem
          );
          this.removeOldItems(result)
          result.forEach((item) => {
            let index = this.billingItems.findIndex((x) => x.id == item.id);
            if (index > -1) {
              this.billingItems.splice(index, 1, item);
            } else {
              this.billingItems.push(item);
            }
          });
          let newbillingItems = this.billingItems
          this.$emit("updateBillingItems", newbillingItems)
        } else {
          let result = await this.$API.createRelationshipBillingItem(
            this.billingItem
          );
          this.billingItems = [...this.billingItems, ...result];
          let newbillingItems = this.billingItems
          this.$emit("updateBillingItems", newbillingItems)
        }
      } else if (this.consigneeProfileId) {
        if (this.billingItem.id) {
          let result = await this.$API.updateConsigneeProfileBillingItem(
            this.billingItem
          );
          this.removeOldItems(result)
          result.forEach((item) => {
            let index = this.billingItems.findIndex((x) => x.id == item.id);
            if (index > -1) {
              this.billingItems[index] = item
            } else {
              this.billingItems.push(item);
            }
          });
          let newbillingItems = this.billingItems
          this.$emit("updateBillingItems", newbillingItems)
        } else {
          let result = await this.$API.createConsigneeProfileBillingItem(
            this.billingItem
          );
          this.billingItems = [...this.billingItems, ...result];
          let newbillingItems = this.billingItems
          this.$emit("updateBillingItems", newbillingItems)
        }
      }

      this.billingItemModal = false;
      this.savingBillingItem = false;
    },
    removeOldItems(items) {
      let parentId = items.find(item => !item.parentBillingItemId)?.id;

      let currentItems = this.billingItems.filter(oldItem => {
        if (oldItem.parentBillingItemId !== parentId) {
          return true;
        }
        return items.some(currentItem => oldItem.id === currentItem.id);
      });
      this.billingItems = currentItems
      this.itemsKey++
    },
    async getCurrentRate(currencyFrom, currencyTo){
      this.loadingCurrency = true
      let rate = `${currencyFrom}/${currencyTo}`
      // let rate = `USD/ZAR`
      let res = await this.$API.getLatestExchangeRate({rate: rate})
      this.currentExchangeRate = parseFloat(res.rate.toFixed(this.decimalPrimary))
      this.loadingCurrency = false
    },
    applyCurrentRate() {
        this.factoredRate = parseFloat((this.currentExchangeRate).toFixed(4))
    },
    invalidBillingItem(billingItem) {
      if(billingItem.level !== 'SHIPMENT') {
        if(billingItem.calculationType !== 'PERCENTAGE') {
          if(!billingItem.finChargeItemId || !billingItem.baseUnitAmount || !billingItem.currency || !billingItem.unitAmount || !billingItem.level) {
            // if(billingItem.currency !== billingItem.baseCurrency) {
            //   if(!billingItem.exchangeRate) {
            //     console.log(2)
            //     return true
            //   } else {
            //     console.log(3)
            //     return false
            //   }
            // } else {
            //   console.log(4)
            //   return true
            // }
            return true
          } else {
            return false
          }

        } else {
          if(!billingItem.calculationValue || !billingItem.calculatedOn || !billingItem.finChargeItemId) {
            return true
          } else {
            return false
          }
        }
      } else {
        if(billingItem.calculationType !== 'PERCENTAGE') {
          if(!billingItem.finChargeItemId || !billingItem.amount || !billingItem.currency || !billingItem.baseAmount || !billingItem.level) {
            return true
          } else {
            return false
          }

        } else {
          if(!billingItem.calculationValue || !billingItem.calculatedOn || !billingItem.level) {
            return true
          } else {
            return false
          }
        }
      }
    }
  },
  
};
</script>

<style scoped>
::v-deep .dialogRight {
  position: fixed !important;
  right: 0 !important;
}
</style>