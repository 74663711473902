<template>
  <div style="background: var(--v-component-base) !important; height: 80vh">
        <div v-if="activePage == 'table'">
          <v-toolbar flat dense>
        <v-spacer></v-spacer>
        <v-chip class="mr-1 searchChipWrap">
          <v-btn left small color="primary" icon id="add-profile-button" @click="createProfile()">
                    <v-icon>add_circle_outline</v-icon>
                </v-btn>
           
          <v-icon small color="grey">search</v-icon>
          <v-text-field
            placeholder="Search"
            class="mb-1 searchInput"
            hide-details
            rounded
            clearable
            dense
            style="margin-top: 2px; width: 300px"
            v-model="search"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-tooltip top v-if="filterActive">
            <template v-slot:activator="{ on }">
              <v-btn
                rounded
                right
                small
                icon
                color="redPop"
                @click="resetFilters"
                v-on="on"
              >
                <v-icon>filter_alt_off</v-icon>
              </v-btn>
            </template>
            <span style="font-size: 12px">Clear Filters</span>
          </v-tooltip>
        </v-chip>
      </v-toolbar>
      <ag-grid-vue
        groupDisplayType="multipleColumns"
        :animateRows="false"
        :rowGroupPanelShow="'always'"
        :autoSizeStrategy="{
          type: 'fitCellContents',
          defaultMinWidth: 100,
          columnLimits: [],
        }"
        style="width: 100%; height: 700px"
        :enableRangeSelection="true"
        :allowContextMenuWithControlKey="true"
        :columnDefs="headers"
        :rowData="profiles.data"
        :suppressAggFuncInHeader="true"
        :defaultColDef="defaultColDef"
        :initialState="initialState"
        :sideBar="['filters', 'columns']"
        :statusBar="statusBar"
        :postProcessPopup="postProcessPopup"
        :getRowId="getRowId"
        :getContextMenuItems="getContextMenuItems"
        @grid-ready="onGridReady"
        @state-updated="onStateUpdated"
      >
      </ag-grid-vue>
        </div>

       
        <!-- <div v-else-if="activePage == 'create'"> -->
        <v-dialog
          fullscreen
          v-model="displayConsigneeProfile"
          persistent
          transition="dialog-bottom-transition"
        >
          <v-card flat class="mx-0 px-0">
            <v-card-text class="mx-0 px-0">
              <ConsigneeProfile
                @back="setActivePage('table')"
                :contacts="contacts"
                :productKey="productKey"
                :disabledFields="disabledFields"
                :related="related"
                :countries="countries"
                :loadingProfile="itemLoading"
                @refresh="getConsigneeProfiles()"
                @refreshRelated="getRelatedOrganisations()"
                @refreshSubscribers="getConsigneeProfileSubscribers()"
                :item="item"
                :selectedCustomer="selectedCustomer"
                :auditLog="auditLog"
                @refreshRelationship="getConsigneeProfileRelationships()"
                @refreshProduct="getConsigneeProfileProducts()"
                @addCustomer="addCustomer($event)"
                @addProduct="addProduct($event)"
                @refreshCustomer="getConsigneeProfileRelationships()"
                :key="updateKey"
              />
            </v-card-text>
          </v-card>

          <!-- <ChatTest :type="'Consignee Profile'" v-show="item.id" :item="item"/> -->
        </v-dialog>
        <v-dialog v-model="incoTermDialog" persistent max-width="600">
          <v-card class="mx-auto">
            <v-card-title class="headline">Inco Terms</v-card-title>
            <v-card-text>
           
              <v-list>
                <v-list-item-group v-model="selectedIncoTerm">
                  <v-list-item
                    v-for="(item, i) in incoTerms"
                    :key="i"
                    :value="item"
                    active-color="primary"
                  >
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <b>Inco Term:</b> {{ item.incoTerm }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <b>Shipping Line: </b>{{ item.shippingLine }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <b>Payment term:</b> {{ item.paymentTerm }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <b>Freight Charge:</b>
                        {{
                          item.freightCharge
                            ? item.freightCharge.toUpperCase()
                            : null
                        }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="item.freightCharge == 'forwarder'"
                      >
                        <b>Freight Charge To:</b>
                        {{
                          item.freightInvoiceTo
                            ? item.freightInvoiceTo.toUpperCase()
                            : null
                        }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <b>Origin Charge:</b>
                        {{
                          item.originCharge
                            ? item.originCharge.toUpperCase()
                            : null
                        }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="item.originCharge == 'forwarder'"
                      >
                        <b>Origin Charge To:</b>
                        {{
                          item.originInvoiceTo
                            ? item.originInvoiceTo.toUpperCase()
                            : null
                        }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <b>Destination Charge: </b
                        >{{
                          item.destinationCharge
                            ? item.destinationCharge.toUpperCase()
                            : null
                        }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="item.destinationCharge == 'forwarder'"
                      >
                        <b>Destination Charge To: </b
                        >{{
                          item.destinationInvoiceTo
                            ? item.destinationInvoiceTo.toUpperCase()
                            : null
                        }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <b>Contract Owner:</b>
                        {{
                          item.contractOwner
                            ? item.contractOwner.toUpperCase()
                            : null
                        }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <b>Comment:</b> {{ item.comment }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="incoTermDialog = false"
                >Cancel</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="saveShipment()"
                :disabled="!selectedIncoTerm"
                >Create</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

       

        <v-dialog v-model="infoStart" max-width="31vw">
          <v-card>
            <v-btn
              @click="infoStartGuide"
              icon
              style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0"
              ><v-icon color="primary" size="30">close</v-icon></v-btn
            >
            <v-card-title class="d-flex justify-center">
              FRIKO Welcome to Shipment Profile!
            </v-card-title>
            <v-card-text>
              <span>
                <p>FRIKO</p>
                <p>
                  FRIKO <br />• Map out routes for transporters <br />• Add
                  GeoJSON data to locations used determine if a vehicle is
                  within a certain area <br />• Calculate accurate waiting times
                  at loading points <br />• Calculate distances for billing
                  purposes <br />• Pin-point locations for the purpose of
                  reporting and analysis <br />• Map out farms that aren't found
                  on Google Maps
                </p>
                If you would like learn how to load POI's into the system, click
                the button below to start the tutorial.
              </span>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-btn @click="startGuide" color="edit" class="mb-3"
                >Start!</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

       

       
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import ActionColum from './ShipmentProfileComponents/ActionsColumn.vue'
import Country from "../GridComponents/Country.vue";
import UserCell from "../GridComponents/User.vue";
import OrganisationName from "@/components/GridComponents/Organisation.vue";
import PortCell from "@/components/GridComponents/Port.vue";
import Status from "./ShipmentProfileComponents/Status.vue";
import TableHeader from "@/components/GridComponents/TableHeader.vue";

// import ConsigneeProfile from "../Customer/ConsigneeProfile/ManageProfile.vue";
import ConsigneeProfile from "./ShipmentProfileComponents/ManageProfile.vue";
import Driver from "driver.js";
import "driver.js/dist/driver.min.css";
// import steps from "./Steps/shipmentProfileSteps";
export default {
  props: ["selectedCustomer", "bookingScope", "shipmentScope"],
  components: {
    ActionColum,
    AgGridVue,
    Country,
    OrganisationName,
    PortCell,
    UserCell,
    agColumnHeader: TableHeader,
    // ChatTest,
    // BookingModal,
    Status,
    ConsigneeProfile,
  },
  data: () => ({
    steps: null,
    activePage: "table",
    addresses: [],
    auditLog: [],
    bookingModal: false,
    bookingItem: {},
    countries: [],
    contacts: [],
    incoTerms: [],
    createdShipments: [],
    createdShipmentsComplete: false,
    disabledFields: [],
    displayConsigneeProfile: false,
    driver: null,
    expanded: [],
    filePage: 1,
    fileParams: {
      limit: 10,
      offset: 0,
      search: null,
      origin: null,
      destination: null,
    },
    fileTimeout: null,
    filterDialog: false,
    intraBooking: false,
    incoTermDialog: false,
    selectedIncoTerm: null,
    filterActive: false,
    filterSetting: {},
    headers: null,
    initialState: {},
    selectedShipments: [],
    sortBy: "consignee.name",
    sortDesc: false,
    shipmentHeaders: [
      // {
      //   text: "ID",
      //   value: "id",
      //   align: "center",
      //   visible: false,
      // },
      {
        text: "File Number",
        value: "fileNumber",
        align: "center",
      },
      {
        text: "Linked Bookings",
        value: "noOfBookings",
        align: "center",
      },
      {
        text: "No. Containers",
        value: "noOfContainers",
        align: "center",
      },
      {
        text: "Shipper",
        value: "shipperName",
        align: "center",
      },
      {
        text: "Receiver",
        value: "receiverName",
        align: "center",
      },
      {
        text: "Vessel/Voyage",
        value: "vessel",
        align: "center",
      },
      {
        text: "POL",
        value: "portOfLoadValue",
        align: "center",
      },
      // {
      //   text: "POD",
      //   value: "portOfDischargeCity",
      //   align: "center",
      // },
      {
        text: "Country of Destination",
        value: "countryOfDestination",
        align: "center",
      },
    ],
    podName: null,
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      enableRowGroup: true,
      enablePivot: true,
      flex: 1,
      minWidth: 100,
      cellClass: "d-flex align-center",
      cellStyle: { textAlign: "center", fontSize: "11px" },
    },
    item: {},
    itemLoading: false,
    loadingText: "Creating shipment",
    loading: false,
    loadingFiles: false,
    params: {},
    profiles: {
      total: 0,
      data: null
    },
    related: [],
    search: null,
    searchTimeout: null,
    savingShipment: false,
    shipmentFileDialog: false,
    shipmentFiles: {
      total: 0,
      data: [],
    },
    shipment: {},
    sortFileBy: "id",
    sortFileDesc: true,
    userAddress: null,
    productKey: 7000,
    updateKey: 6000,
    dealKey: 0,
    infoStart: false,
    createShipmentGuideStart: false,
    statusBar: {
      statusPanels: [
        {
          statusPanel: "agTotalRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agFilteredRowCountComponent",
        },
        {
          statusPanel: "agSelectedRowCountComponent",
        },
        {
          statusPanel: "agAggregationComponent",
        },
      ],
    },
  }),
  watch: {
    search(val) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(async () => {
        this.gridApi.updateGridOptions({ quickFilterText: val });
      }, 400);
    },
    activePage(val) {
      this.displayConsigneeProfile = val == "create";
    },
    "selectedCustomer.id": {
      immediate: true,
      handler(value) {
        if (value) {
          this.getConsigneeProfiles();
          this.getRelatedOrganisations();
        }
      },
    },
    async filePage(value) {
      this.fileParams.offset = (value - 1) * this.fileParams.limit;
      await this.getShipmentFiles();
    },
    "fileParams.limit": {
      immediate: true,
      async handler() {
        await this.getShipmentFiles();
      },
    },
    "fileParams.search": {
      immediate: true,
      async handler() {
        await this.getShipmentFiles();
      },
    },
    sortFileBy(value) {
      this.fileParams.sortBy = value;
      this.getShipmentFiles();
    },
    sortFileDesc(value) {
      this.fileParams.sortDesc = value;
      this.getShipmentFiles();
    },
    selectedIncoTerm: {
      immediate: true,
      handler() {
        console.log(this.selectedIncoTerm);
        if (this.selectedIncoTerm && this.selectedIncoTerm != null) {
          this.shipment.incoTermId = this.selectedIncoTerm.id;
        } else {
          this.shipment.incoTermId = null;
        }
      },
    },
  },
  computed: {
    filteredData() {
      let result = this.profiles.data ?? [];
      if (this.search) {
        result = result.filter(
          (item) =>
            (item.systemReference &&
              item.systemReference
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (item.reference &&
              item.reference
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (item.buyer &&
              item.buyer.name
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (item.forwarder &&
              item.forwarder.name
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (item.shipper &&
              item.shipper.name
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (item.consignee &&
              item.consignee.name
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (item.onBehalfForwarder &&
              item.onBehalfForwarder.name
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (item.onBehalfShipper &&
              item.onBehalfShipper.name
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (item.onBehalfConsignee &&
              item.onBehalfConsignee.name
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (item.consigneeProfileIncoTerms &&
              item.consigneeProfileIncoTerms
                .map((y) => y.incoTerm.toLowerCase())
                .includes(this.search.toLowerCase()))
        );
      }
      return result;
    },
  },
  async created() {
    if (
      localStorage.getItem(
        "profileTable_" + this.$store.state.currentOrg.id
      )
    ) {
      let state = JSON.parse(
        localStorage.getItem(
          "profileTable_" + this.$store.state.currentOrg.id
        )
      );
      if (typeof state === "object" && state !== null) {
        this.initialState = state;
      }
    }
    this.loadCountries();
    this.getOrganisationAddresses();
    this.getContacts();
    await this.getSteps();
  },
  async mounted() {
    this.driver = new Driver({
      animate: false,
    });
  },
  methods: {
    async getSteps() {
        let steps = await this.$API.getGuideSteps({
            guideId: 'Contacts/ShipmentProfile/Steps',
        });
        this.steps = steps.steps
    },
    calculateContainers(bookings) {
      return bookings
        .map((item) => item.bookingContainers.length)
        .reduce((prev, next) => prev + next);
    },
    createBooking(item) {
      this.bookingItem = item;
      this.intraBooking = true;
      // this.bookingModal = true
    },
    redirectToBooking(type) {
      this.$router.push({
        path: "/bookings/create/" + this.selectedCustomer.relatedOrganisationId,
        query: { profile: this.bookingItem.systemReference, type: type },
      });
    },
    filterListener(event) {
      let filter = this.gridApi.getFilterModel();
      this.filterActive = Object.keys(filter).length > 0;
      this.filterSetting = filter;
    },
    getContextMenuItems(params) {
      let result = [];
      if (!params.node.group) {
        result = [
          {
            name: "Add to Filter",
            icon: '<span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span>',
            action: () => {
              if (params.node && params.node.data) {
                let filter = this.gridApi.getFilterModel();
                if (filter[params.column.colId]) {
                  filter[params.column.colId].values.push(params.value);
                } else {
                  filter[params.column.colId] = {
                    values: [params.value],
                  };
                }
                this.gridApi.setFilterModel(filter);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      } else {
        result = [
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      }

      return result;
    },
    getRowId(params) {
      return params.data.id;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.addEventListener("filterChanged", this.filterListener);
    },
    onStateUpdated(state) {
      localStorage.setItem(
        "profileTable_" + this.$store.state.currentOrg.id,
        JSON.stringify(state.state)
      );
    },
    postProcessPopup(params) {
      if (params.type !== "columnMenu") {
        return;
      }
      const ePopup = params.ePopup;
      ePopup.style.top = "65px";
      ePopup.style.left =
        params.column.actualWidth / 2 + params.mouseEvent.left + "px";
    },
    async resetFilters() {
      this.gridApi.setFilterModel({});
    },
    createProfile() {
      this.auditLog = [];
      this.itemLoading = false;
      this.item = {
        movementType: "EXPORT",
        type: "LOGISTICS",
        modeOfTransport: "OCEAN",
        serviceType: "FCL",
        editable: true,
        preAdviseRequired: false,
        bookingContainerItems: [],
        consigneeProfileDocuments: [],
        consigneeProfileFormResponses: [],
        consigneeProfilePorts: [],
        consigneeProfileIncoTerms: [],
        consigneeProfileShippingContracts: [],
        consigneeProfileDistributionLists: [],
        consigneeProfileProducts: [],
        consigneeProfileRelationships: [
          {
            customerId: this.selectedCustomer.relatedOrganisationId,
            organisationRelationshipId: this.selectedCustomer.id,
          },
        ],
        notificationSubscribers: {
          users: [],
          teams: this.selectedCustomer.relationshipTeams.map((x) => ({
            teamId: x.teamId,
            team: x.team,
          })),
        },
        modalType: "Add",
        allOriginPorts: true,
        allDestinationPorts: true,
        incoTerm: "EXW",
        originCountry: {
          continent: "Africa",
          id: 343,
          iso2: "ZA",
          name: "South Africa",
        },
        originCountryId: 343,
        currencyCode: "USD",
        status: "Draft",
      };

      let keys = [
        "buyer",
        "shipper",
        "forwarder",
        "consignee",
        "firstNotifyParty",
        "secondNotifyParty",
        "courierParty",
      ];

      keys.forEach((x) => {
        if (this.selectedCustomer[x]) {
          this.item[x + "Id"] = this.selectedCustomer.relatedOrganisationId;
          this.item[x] = this.selectedCustomer.relatedOrganisation;
          if (
            this.selectedCustomer.relatedOrganisation.organisationAddresses &&
            this.selectedCustomer.relatedOrganisation.organisationAddresses
              .length > 0
          ) {
            let address =
              this.selectedCustomer.relatedOrganisation.organisationAddresses.find(
                (y) => y.isDocsAddress
              );
            if (address) {
              this.item[x + "Address"] = address;
              this.item[x + "AddressId"] = address.id;
            } else {
              address =
                this.selectedCustomer.relatedOrganisation.organisationAddresses.find(
                  (y) => y.isHQ
                );
              if (address) {
                this.item[x + "Address"] = address;
                this.item[x + "AddressId"] = address.id;
              } else {
                address =
                  this.selectedCustomer.relatedOrganisation
                    .organisationAddresses[0];
                this.item[x + "Address"] = address;
                this.item[x + "AddressId"] = address.id;
              }
            }

            // this.item[x + "Address"] =
            //   this.selectedCustomer.relatedOrganisation.organisationAddresses[0];
            // this.item[x + "AddressId"] =
            //   this.selectedCustomer.relatedOrganisation.organisationAddresses[0].id;
          }
        } else if (this.selectedCustomer.inverse[x]) {
          this.item[x + "Id"] = this.selectedCustomer.organisationId;
          this.item[x] = this.selectedCustomer.organisation;
          if (
            this.selectedCustomer.organisation.organisationAddresses &&
            this.selectedCustomer.organisation.organisationAddresses.length > 0
          ) {
            let address =
              this.selectedCustomer.organisation.organisationAddresses.find(
                (y) => y.isDocsAddress
              );
            if (address) {
              this.item[x + "Address"] = address;
              this.item[x + "AddressId"] = address.id;
            } else {
              address =
                this.selectedCustomer.organisation.organisationAddresses.find(
                  (y) => y.isHQ
                );
              if (address) {
                this.item[x + "Address"] = address;
                this.item[x + "AddressId"] = address.id;
              } else {
                address =
                  this.selectedCustomer.organisation.organisationAddresses[0];
                this.item[x + "Address"] = address;
                this.item[x + "AddressId"] = address.id;
              }
            }

            // this.item[x + "Address"] =
            //   this.selectedCustomer.organisation.organisationAddresses[0];
            // this.item[x + "AddressId"] =
            //   this.selectedCustomer.organisation.organisationAddresses[0].id;
          }
        }
      });
      console.log(this.item);
      this.setActivePage("create");
    },
    createShipment(item) {
      this.item = item;
      this.setActivePage("booking");
    },
    createShipmentFile(item) {
      if (item.consigneeProfileIncoTerms.length == 0) {
        this.$message.error(
          "Please add Inco Terms to the profile before creating a shipment"
        );
        return;
      }
      this.createdShipmentsComplete = false;
      this.shipment = {
        consigneeProfileId: item.id,
        documentProcessingAddressId: this.userAddress
          ? this.userAddress.organisationAddressId
          : null,
      };

      if (!this.shipment.documentProcessingAddressId) {
        this.shipment.documentProcessingAddressId = this.addresses.find(
          (x) => x.isHQ
        )
          ? this.addresses.find((x) => x.isHQ).id
          : null;
      }
      this.createdShipments = [];
      this.selectedShipments = [];
      this.shipmentFileDialog = true;
      this.fileParams.destination = item.destinationCountry.iso2;
      this.fileParams.origin = item.originCountry.iso2;
      this.fileParams.shipperId = item.shipperId;
      this.getShipmentFiles();
      this.getShipmentIncoTerms(item.id);
    },
    async getShipmentIncoTerms(profileId) {
      this.incoTerms = await this.$API.getConsigneeProfileIncoTerms(profileId);
    },
    async cloneProfile(item) {
      this.itemLoading = true;
      this.$emit("startLoad");

      this.setActivePage("create");
      this.item = await this.$API.getConsigneeProfile(item.id);
      // this.item.consigneeProfileShippingContracts =
      //   await this.$API.getConsigneeProfileContracts(item.id);
      this.item.consigneeProfileDocuments =
        await this.$API.getConsigneeProfileDocuments(item.id);
      this.item.consigneeProfileRelationships =
        await this.$API.getConsigneeProfileRelationships(item.id);
        this.item.consigneeProfileShares =
        await this.$API.getConsigneeProfileShareSetting(item.id);
      this.item.consigneeProfileProducts =
        await this.$API.getConsigneeProfileProducts(item.id);
      this.itemLoading = false;
      this.item.modalType = "Add";
      this.item.status = "Draft";
      this.item.editable = true;
      if (this.item.reference) {
        this.item.reference = this.item.reference + "/1";
      }
      delete this.item.id;
      delete this.item.systemReference;
      this.item.consigneeProfileShares.forEach((x) => {
        delete x.id;
        delete x.consigneeProfileId;
        delete x.createdAt
        delete x.updatedAt
      });
      this.item.consigneeProfileFormResponses.forEach((x) => {
        delete x.id;
        delete x.consigneeProfileId;
      });
      this.item.consigneeProfileRelationships.forEach((x) => {
        delete x.id;
        delete x.consigneeProfileId;
      });
      this.item.bookingContainerItems.forEach((x) => {
        delete x.id;
        delete x.consigneeProfileId;
        x.bookingCargoItems.forEach((y) => {
          delete y.id;
          delete y.consigneeProfileId;
        });
      });
      this.item.consigneeProfileDocuments.forEach((x) => {
        delete x.id;
        delete x.consigneeProfileId;
      });
      this.item.consigneeProfilePorts.forEach((x) => {
        delete x.id;
        delete x.consigneeProfileId;
      });
      this.item.consigneeProfileIncoTerms.forEach((x) => {
        delete x.id;
        delete x.consigneeProfileId;
        x.consigneeProfileShippingContracts.forEach((y) => {
          delete y.id;
          delete y.consigneeProfileIncoTermId;
        });
      });
      this.item.consigneeProfileDistributionLists.forEach((x) => {
        delete x.id;
        delete x.consigneeProfileId;
      });
      this.item.consigneeProfileProducts.forEach((x) => {
        delete x.id;
        delete x.consigneeProfileId;
      });
      this.setActivePage("create");
      this.$emit("stopLoad");
    },
    expandRow(data) {
      let find = this.expanded.find((x) => x.id == data.id);
      if (!find) this.expanded = [data];
      else this.expanded = [];
    },

    async editProfile(item) {
      this.itemLoading = true;
      this.$emit("startLoad");
      this.setActivePage("create");
      this.item = await this.$API.getConsigneeProfile(item.id);
      this.item.modalType = "Edit";
      // this.item.consigneeProfileShippingContracts =
      //   await this.$API.getConsigneeProfileContracts(item.id);
      this.item.consigneeProfileDocuments =
        await this.$API.getConsigneeProfileDocuments(item.id);
      this.item.notificationSubscribers =
        await this.$API.getConsigneeProfileSubscribers(item.id);
      this.item.consigneeProfileRelationships =
        await this.$API.getConsigneeProfileRelationships(item.id);
      this.item.consigneeProfileShares = await this.$API.getConsigneeProfileShareSetting(
        item.id
      );
        await this.$API.getConsigneeProfileProducts(item.id);
      this.disabledFields = this.selectedCustomer.networkFunctions;
      let findShare = this.item.consigneeProfileShares.find(x => x.sharedPartyId == this.$store.state.currentOrg.id)
      findShare = findShare ? findShare.share: false;
      this.item.editable = (this.item.ownerId == this.$store.state.currentOrg.id) || findShare
      console.log("Editable", this.item.editable);
      this.itemLoading = false;
      this.$emit("stopLoad");
    },
    async getContacts() {
      let data = await this.$API.getRelation({});
      this.contacts = data.data
    },
    async getConsigneeProfiles() {
      console.log("Refreshing");
      this.loading = true;
      this.profiles = await this.$API.listCustomerConsigneeProfile(
        this.selectedCustomer.relatedOrganisationId,
        {}
      );
      this.loading = false;
      this.headers = [
    {
        headerName: "Action",
        field: "action",
        cellRenderer: "ActionColum",
        cellRendererParams: (p) => {
          return {
            params: p,
            shipmentScope: this.shipmentScope,
            bookingScope: this.bookingScope,
            cloneProfile: this.cloneProfile,
            editProfile: this.editProfile,
            removeProfile: this.removeProfile,
          };
        },
      },
      {
        headerName: "Status",
        field: "status",
        cellRenderer: "Status",
      },
    {
        headerName: "System Ref",
        field: "systemReference",
      },
      {
        headerName: "Reference",
        field: "reference",
      },
      {
        headerName: "Consignee",
        field: "consignee.name",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.consignee
            ? {
                organisation: p.data.consignee,
              }
            : null;
        },
      },
      {
        headerName: "Shipper",
        field: "shipper.name",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.shipper
            ? {
                organisation: p.data.shipper,
              }
            : null;
        },
      },
      {
        headerName: "On Behalf Shipper",
        field: "onBehalfShipper",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.onBehalfShipper
            ? {
                organisation: p.data.onBehalfShipper,
              }
            : null;
        },
      },
      {
        headerName: "Forwarder",
        field: "forwarder.name",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.forwarder
            ? {
                organisation: p.data.forwarder,
              }
            : null;
        },
      },
      {
        headerName: "Origin",
        field: "originCountry.name",
        cellRenderer: "Country",
        cellRendererParams: (p) => {
          return p.data && p.data.originCountry
            ? {
                country: p.data.originCountry
              }
            : null;
        },
      },
      {
        headerName: "Loading Ports",
        field: "loadingPorts",
        valueFormatter: (p) => {
          
          return p.data ? p.data.allOriginPorts ? "All Ports" :
          
          p.data.consigneeProfilePorts.filter(
                    (x) => x.type == 'loading'
                  ).map(x=>x.name ).join(", ") : "";
        },  
      },
      {
        headerName: "Destination",
        field: "destinationCountry.name",
        cellRenderer: "Country",
        cellRendererParams: (p) => {
          return p.data && p.data.destinationCountry
            ? {
                country: p.data.destinationCountry
              }
            : null;
        },
      },
      {
        headerName: "Discharge Ports",
        field: "dischargePorts",
        filter: false,
        valueFormatter: (p) => {
          
          return p.data ? p.data.allDestinationPorts ? "All Ports" :
          
          p.data.consigneeProfilePorts.filter(
                    (x) => x.type == 'discharge'
                  ).map(x=>x.name ).join(", ") : "";
        },  
      },
      {
        headerName: "Final Dest.",
        field: "finalDestinationCity"
      },
      {
        headerName: "Inco Term(s)",
        field: "incoTerm",
        valueGetter: (p)=>{
          return p.data ? [...new Set(p.data.consigneeProfileIncoTerms.map(x=>x.incoTerm))].join(", ") :''
        }
      }
    ]
    },
    addCustomer(item) {
      this.item.consigneeProfileRelationships.push(item);
    },
    addProduct(item) {
      this.item.consigneeProfileProducts.push(item);
      this.productKey++;
    },
    async getRelatedOrganisations() {
      this.related = await this.$API.getCustomerRelationships(
        this.selectedCustomer.relatedOrganisationId
      );
      this.related.forEach((x) => {
        if (x.relatedOrganisation.primaryOrganisationId) {
          let find = this.related.find(
            (y) =>
              y.relatedOrganisationId ==
              x.relatedOrganisation.primaryOrganisationId
          );
          x.buyer = find.buyer;
          x.producer = find.producer;
          x.shipper = find.shipper;
          x.consignee = find.consignee;
          x.coldstore = find.coldstore;
          x.notifyParty = find.notifyParty;
        }
      });
    },
    async getOrganisationAddresses() {
      this.addresses = await this.$API.getOrganisationAddress();
      this.userAddress = await this.$API.getOrganisationUserAddress();
    },
    async getConsigneeProfileSubscribers() {
      console.log("Refresh subs", this.item.notificationSubscribers);
      this.item.notificationSubscribers =
        await this.$API.getConsigneeProfileSubscribers(this.item.id);
    },
    async getShipmentFiles() {
      if (this.fileTimeout) {
        clearTimeout(this.fileTimeout);
      }
      this.loadingFiles = true;
      this.fileTimeout = setTimeout(async () => {
        this.shipmentFiles = await this.$API.listShipmentFile({
          params: this.fileParams,
        });
        this.loadingFiles = false;
      }, 500);
    },
    async getConsigneeProfileRelationships() {
      if (this.item.id) {
        this.item.consigneeProfileRelationships =
          await this.$API.getConsigneeProfileRelationships(this.item.id);
      }
      this.updateKey++;
    },
    async getConsigneeProfileShareSetting() {
      if (this.item.id) {
        this.item.consigneeProfileShares =
          await this.$API.getConsigneeProfileShareSetting(this.item.id);
      }
      this.updateKey++;
    },
    async getConsigneeProfileProducts() {
      if (this.item.id) {
        this.item.consigneeProfileProducts =
          await this.$API.getConsigneeProfileProducts(this.item.id);
      }
      this.productKey++;
    },
    async loadCountries() {
      this.countries = await this.$API.searchCountriesBasic({});
    },
    async removeProfile(item) {
      this.$confirm(
        "Are you sure you want to delete this consignee profile?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          await this.$API.updateConsigneeProfile({
            id: item.id,
            isActive: false,
            isDeleted: true,
          });
          this.getConsigneeProfiles();
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    async checkIncoTerm(item) {
      if (this.incoTerms.length > 1) {
        this.incoTermDialog = true;
      } else if (this.incoTerms.length === 1) {
        this.shipment.incoTermId = this.incoTerms[0].id;
        this.saveShipment();
      }
    },
    async saveShipment() {
      this.incoTermDialog = false;
      this.createdShipments = [];
      for (let i = 0; i < this.selectedShipments.length; i++) {
        this.loadingText =
          "Creating shipment " + (i + 1) + "/" + this.selectedShipments.length;
        let obj = this.shipment;
        obj.shipmentFileId = this.selectedShipments[i].id;
        this.savingShipment = true;
        let findShipment = await this.$API.checkShipmentFileExist(
          obj.shipmentFileId
        );
        let result;
        if (findShipment) {
          result = await this.$API.createShipment(obj);
          this.createdShipments.push(result);
        } else {
          this.$message.error("Shipment already exists");
        }
        console.log(result);
      }
      this.selectedShipments = [];
      this.createdShipmentsComplete = true;
      this.savingShipment = false;
    },
    routeToShipment(item) {
      this.$router.push({
        path: "/shipment/" + item.shipmentReference,
      });
    },
    setActivePage(page) {
      this.$emit("summary");
      this.activePage = page;
      if (page != "table") {
        this.$emit("hideTabs");
      } else {
        this.$emit("showTabs");
      }
    },
    infoStartGuide() {
      if (this.infoStart) {
        this.infoStart = false;
      } else {
        this.infoStart = true;
      }
    },
    startGuide(e) {
      this.infoStart = false;
      e.stopPropagation();
      this.driver.defineSteps(this.steps);
      this.driver.start();
    },
    createShipmentModal() {
      if (this.createShipmentGuideStart) {
        this.createShipmentGuideStart = false;
      } else {
        this.createShipmentGuideStart = true;
      }
    },
  },
};
</script>
