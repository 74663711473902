<template>
    <div>
      <div v-if="!loadingProfile">
        <!-- <v-toolbar flat style="background-color: transparent !important; width: 90%" absolute bottom><v-spacer></v-spacer>
  
          <v-menu v-model="fab" top offset-y nudge-top="5" :close-on-click="false" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
  
              <v-btn v-bind="attrs" v-on="on" rounded dark fab large color="primary" fixed right bottom
                style="text-transform: none;" @click="fab = !fab">
                <v-badge :key="messageKey" :content="unreadMessages" :value="unreadMessages" color="blue"
                  style="z-index: 99999">
                  <v-icon dark v-if="!fab">chat</v-icon>
                  <v-icon v-else>close</v-icon>
                </v-badge>
  
              </v-btn>
  
            </template>
  
            <Chat :key="messageKey" @close="fab = false" :open="fab" :item="item"
              :consigneeProfileComments="consigneeProfileComments" :loadingComments="loadingComments"
              @newMessage="newMessage" @updateMessage="update" />
          </v-menu>
        </v-toolbar> -->
        <v-card flat>
          <v-toolbar flat wrap>
            <v-btn icon @click="$emit('back')">
              <v-icon>arrow_back</v-icon>
            </v-btn>
            <v-toolbar-title style="font-size: 16px"><b>#{{ item.systemReference }}: {{
              selectedCustomer.relatedOrganisation.name
            }}</b>
            </v-toolbar-title>
            <v-text-field class="ml-3 mt-5 pt-2" prefix="Ref. #" placeholder="Reference No" flat v-model="item.reference" :disabled="!item.editable"
              outlined dense style="font-weight: bold; font-size: 18px; max-width: 250px"></v-text-field>
            <v-spacer></v-spacer>
            <div v-if="!$vuetify.breakpoint.mobile" :key="userUpdateKey" class="mr-2">
              <v-tooltip top v-for="user in activeUsers" :key="user.connectionId">
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar v-bind="attrs" v-on="on" style="cursor: pointer" color="secondary" size="48"
                    class="mt-0 pt-0 mx-1">
                    <span v-if="!user.user.avatar" class="primaryText--text text-h5">{{
                     $Format.avatarAbbreviation(user.user.name)
                    }}</span>
                    <img v-else :src="user.user.avatar" referrerpolicy="no-referrer" />
                  </v-avatar>
                </template>
                <span style="font-size: 12px">{{ user.user.name }}</span>
              </v-tooltip>
            </div>
  
              <div v-if="!$vuetify.breakpoint.mobile">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small active-class="activeClass" dark :depressed="false" v-bind="attrs" v-on="on" class="mr-0" :disabled="!item.editable"
                    :color="getStatusColor(item.status)">
                    {{ item.status }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(status, index) in statusOptions" :key="index" @click="updateStatus(status)">
                    <v-list-item-title>{{ status }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              </div>
              <div v-if="!$vuetify.breakpoint.mobile">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-btn color="primary" text :loading="savingProfile" @click="saveConsigneeProfile()"
                        :disabled="!item.editable || buttonDisabled"><v-icon left>save</v-icon> {{ item.id ? "Save" : "Submit" }}
                      </v-btn>
                    </div>
                  </template>
                  <span v-if="this.item.status === 'Approved'">
                    <div >
                      <span style="font-size: 12px" class="text-decoration-underline">
                        Reasons why button is disabled:
                      </span>
                    </div>
  
                    <div v-if="!item.consigneeProfileIncoTerms.length > 0
                      ">
                      <span style="font-size: 12px; color: #ff6666">No deal term is selected</span>
                    </div>
  
                    <div v-if="!item.finalDestinationValue && !item.finalDestinationCity
                      ">
                      <span style="font-size: 12px; color: #ff6666">No final destination is selected</span>
                    </div>
  
                    <div v-if="!item.podCountry 
  
                      ">
                      <span style="font-size: 12px; color: #ff6666">No POD is selected</span>
                    </div>
  
  
                    <div v-if="!item.destinationCountry 
  
  
                      ">
                      <span style="font-size: 12px; color: #ff6666">No destination country is selected</span>
                    </div>
  
                    <div v-if="!item.polCountry
  
  
  
                      ">
                      <span style="font-size: 12px; color: #ff6666">No POL is selected</span>
                    </div>
  
                    <div v-if="!item.consigneeId
                      ">
                      <span style="font-size: 12px; color: #ff6666">No Consignee is selected</span>
                    </div>
  
                    <div v-if="!item.shipperId
                      ">
                      <span style="font-size: 12px; color: #ff6666">No Shipper is selected</span>
                    </div>
  
  
                    <div v-if="!item.consigneeProfileRelationships.length > 0
  
                      ">
                      <span style="font-size: 12px; color: #ff6666">No Customer is selected</span>
                    </div>
                  </span>
                  </v-tooltip>
                  </div>
          </v-toolbar>
          <v-row class="my-3 mx-2" justify="center" v-if="$vuetify.breakpoint.mobile">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small active-class="activeClass" dark :depressed="false" v-bind="attrs" v-on="on" class="mr-0"
                  :color="getStatusColor(item.status)">
                  {{ item.status }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(status, index) in statusOptions" :key="index" @click="updateStatus(status)">
                  <v-list-item-title>{{ status }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn color="primary" text :loading="savingProfile" @click="saveConsigneeProfile()"><v-icon left>save</v-icon>
              {{ item.id ? "Save" : "Submit" }}
            </v-btn>
          </v-row>
          <v-tabs v-model="tab" style="font-size: 14px">
            <v-tab> General Information </v-tab>
            <v-tab v-if="item.editable"> Settings</v-tab>
            <v-tab v-if="item.editable"> Documents </v-tab>
            <v-tab v-if="item.editable"> Forms </v-tab>
            <v-tab v-if="item.editable">Financial</v-tab>
            <!-- <v-tab> Roles & Responsibilities </v-tab> -->
          </v-tabs>
          <div class="mt-0" :style="{
            height: !$vuetify.breakpoint.mobile ? '85vh' : '85vh',
            'overflow-y': 'auto',
          }">
            <div class="mx-0 px-0" v-if="tab == 0">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6">
                      <GeneralInformation :item="item" :selectedCustomer="selectedCustomer" :related="related"
                        @refreshRelated="$emit('refreshRelated')" @refresh="$emit('refresh')" />
                    </v-col>
                    <v-col cols="12" md="3">
                      <RelationshipOverview :profile="item" :contacts="contacts" :selectedCustomer="selectedCustomer"
                        :key="relationshipKey" @addCustomer="$emit('addCustomer', $event)"
                        @refreshCustomer="$emit('refreshCustomer')" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <Chat :key="messageKey" @close="fab = false" :open="fab" :item="item"
              :consigneeProfileComments="consigneeProfileComments" :loadingComments="loadingComments"
              @newMessage="newMessage" @updateMessage="update" />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
            <!-- <div class="mx-0 px-0" v-if="tab == 1">
                <v-card flat>
                  <v-card-text>
                    <OrganisationChart :item="item"/>
                  </v-card-text>
                </v-card>
              </div> -->
  
            <div class="mx-0 px-0" v-if="tab == 1">
              <v-card flat>
                <v-card-text>
                  <Settings :item="item" :contacts="contacts" :profile="item" :selectedCustomer="selectedCustomer"
                    :countries="countries" :related="related" :productKey="productKey" :currencies="currencies"
                    @refreshSubscribers="$emit('refreshSubscribers')" @refreshProduct="$emit('refreshProduct')"
                    @addProduct="$emit('addProduct', $event)" />
                </v-card-text>
              </v-card>
            </div>
            <div class="mx-0 px-0" v-if="tab == 2">
              <v-card flat>
                <v-card-text>
                  <Documents :key="countryKey" :item="item" @updateProfileDocument="updateProfileDocument" />
                </v-card-text>
              </v-card>
            </div>
            <div class="mx-0 px-0" v-if="tab == 3">
              <v-card flat>
                <v-card-text>
                  <IntegrationSetup :key="countryKey" :item="item" @formUpdate="formResponseUpdate" />
                </v-card-text>
              </v-card>
            </div>
            <div class="mx-0 px-0" v-if="tab == 4">
              <v-card flat>
                <v-card-text>
                  <FinancialSettings :consigneeProfileId="item.id" :organisations="contacts" />
                </v-card-text>
              </v-card>
            </div>
          </div>
  
          <v-dialog v-model="verificationModal" persistent width="600px" :fullscreen="$vuetify.breakpoint.mobile"
            style="background: var(--v-component-base) !important">
            <v-card>
              <v-toolbar flat color="transparent">
                <v-toolbar-title> Validation error </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn text @click="verificationModal = false">X</v-btn>
              </v-toolbar>
              <v-card-text>
                <v-list dense subheader>
                  <v-subheader>Please resolve the following items:</v-subheader>
                  <v-list-item v-for="(error, index) in verificationItems" :key="index">
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 14px">
                        {{ error }}
                      </v-list-item-title>
                    </v-list-item-content></v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card>
      </div>
      <div v-else style="height: 70vh; padding-top: 0; margin-top: 0" class="text-center">
        <v-card flat color="background">
          <valhalla-loading />
        </v-card>
      </div>
  
  
  
    </div>
  </template>
  <script>
  import Chat from "./Chat.vue";
  import IntegrationSetup from "../../Customer/ConsigneeProfile/IntegrationSetup.vue";
  import Documents from "../../Customer/ConsigneeProfile/Document.vue";
//   import GeneralInformation from "../../Customer/ConsigneeProfile/GeneralInformation.vue";
    import GeneralInformation from "./GeneralInformation.vue";
  // import OrganisationChart from './OrganisationChart.vue'
  import RelationshipOverview from "./RelationshipOverview.vue";
  // import RolesResponsibilities from './RolesResponsibilities.vue'
  import Settings from "../../Customer/ConsigneeProfile/Settings.vue";
  import FinancialSettings from "../../Customer/ConsigneeProfile/FinancialSettings.vue";
  export default {
    props: [
      "auditLog",
      "contacts",
      "countries",
      "disabledFields",
      "item",
      "selectedCustomer",
      "related",
      "loadingProfile",
      "productKey"
    ],
    components: {
      Chat, IntegrationSetup, Documents,
      FinancialSettings,
      GeneralInformation,
      // OrganisationChart, 
      RelationshipOverview,
      // RolesResponsibilities,
      Settings
    },
    data: () => ({
      activeUsers: [],
      consigneeProfileComments: [],
      countryKey: 0,
      currencies: [],
      fab: false,
      loadingComments: false,
      messageKey: 100,
      relationshipKey: 5000,
      savingProfile: false,
      statusOptions: [
        "Draft",
        "Updated",
        "Approved",
        "Inactive"
      ],
      tab: 0,
      unreadMessages: 0,
      userUpdateKey: 1000,
      verificationItems: [],
      verificationModal: false,
    }),
    watch: {
      fab(val) {
        if (val) {
          this.unreadMessages = 0;
        }
      },
      "item.consigneeProfileRelationships.length": {
        immediate: true,
        handler(val) {
          this.relationshipKey++
        }
      },
      "item.id": {
        immediate: true,
        handler: function (val) {
          if (val) {
            // this.fab = true;
            // this.$socket.onmessage = (data) => {
            //   let message = JSON.parse(data.data);
            //   console.log(message);
            //   if (
            //     message.type == "users" &&
            //     message.room == "consigneeProfile_" + this.item.id
            //   ) {
            //     this.activeUsers = message.data;
            //     this.userUpdateKey++;
            //   }
            // };
            this.getComments()
            //       this.$socket.onmessage = (data) => {
            //   console.log(data);
            //   let message = JSON.parse(data.data);
            //   this.handleMessage(message);
            //   if (
            //     message.type == "users" &&
            //     message.room == "consigneeProfile_" + this.item.id
            //   ) {
            //     this.activeUsers = message.data;
            //     this.userUpdateKey++;
            //   }
            // };
            this.$socket.send(
              JSON.stringify({
                action: "join",
                room: "consigneeProfile_" + val,
                user: {
                  id: this.$store.state.user.id,
                  name:
                    this.$store.state.user.firstname +
                    " " +
                    this.$store.state.user.surname,
                  avatar: this.$store.state.user.avatar,
                  company: this.$store.state.currentOrg.name,
                },
              })
            );
          }
        },
      },
    },
    mounted() {
      this.$socket.onmessage = (data) => {
        let message = JSON.parse(data.data);
        this.handleMessage(message);
        if (
          message.type == "users" &&
          message.room == "consigneeProfile_" + this.item.id
        ) {
          this.activeUsers = message.data;
          this.userUpdateKey++;
        }
      };
    },
    unmounted() {
      delete this.$socket.onmessage
    },
    created() {
      this.getCurrencies();
    },
    beforeDestroy() {
      delete this.$socket.onmessage
      this.$socket.send(
        JSON.stringify({
          action: "leave",
          room: "consigneeProfile_" + this.item.id,
        })
      );
    },
    computed: {
      buttonDisabled() {
        if (this.item.status !== 'Approved') {
          return false; // Not disabled if status is not 'Approved'
        }
        // Check all other conditions
        return !(
          this.item.consigneeProfileIncoTerms.length > 0 &&
          this.item.finalDestinationValue && this.item.finalDestinationCity &&
          this.item.podCountry  &&
          this.item.destinationCountry &&
          this.item.polCountry  &&
          this.item.consigneeId &&
          this.item.shipperId &&
          this.item.consigneeProfileRelationships.length > 0 
        );
      },
    },
    methods: {
  
      async getCurrencies() {
        this.currencies = await this.$API.getCurrencyInfo();
      },
      async getComments() {
        this.loadingComments = true;
        this.consigneeProfileComments =
          await this.$API.getConsigneeProfileComment(this.item.id);
        this.loadingComments = false;
      },
      formResponseUpdate(event) {
        this.item.consigneeProfileFormResponses = event
      },
      getStatusColor(status) {
        switch (status) {
          case 'Draft':
            return 'blue'
          case 'Updated':
            return 'orange'
          case 'Approved':
            return 'green'
          case 'Inactive':
            return 'red'
        }
      },
  
      handleMessage(message) {
        if (
          message.type == "message" &&
          message.room == "consigneeProfile_" + this.item.id
        ) {
          if (!this.consigneeProfileComments.map(x => x.id).includes(message.data.id)) {
            this.messageKey++
            this.consigneeProfileComments.push(message.data);
            this.playNotification()
          }
          if (!this.fab) {
            this.unreadMessages++;
            this.messageKey++
          }
          // this.activeUsers = message.data;
          // this.userUpdateKey ++
        }
  
      },
      newMessage(result) {
        this.consigneeProfileComments.push(result);
      },
      playNotification() {
        var audioFile = new Audio('https://cdn.loglive.io/sounds/pristine.mp3');
        audioFile.play();
      },
      async saveConsigneeProfile() {
        console.log('this item', this.item)
        let proceedWithoutCourierParty = false
        //if this.item.courierParty is null give popup. If user say yes then the rest of this function should excecute otherwise dont save

        if (!this.item.courierParty) {
          //give popup and ask if user wants to proceed without courier party
          proceedWithoutCourierParty = await this.$confirm('No courier party selected. Since no courier party is selected, this profile will use Consignee as courier party. Do you want to proceed?')
        }
        if (!this.verifyInformation() && this.item.status == "Approved") {
          this.item.status = "Draft";
        }
        this.savingProfile = true;
        if (this.item.originCountry) {
          this.item.originCountryId = this.item.originCountry.id;
        }
        if (this.item.destinationCountry) {
          this.item.destinationCountryId = this.item.destinationCountry.id;
        }
        if (this.item.id) {
          if (this.item.status === 'Draft') {
            await this.$API.updateConsigneeProfile(this.item);
            this.$emit("refresh");
            this.$emit("back");
            this.savingProfile = false;
          }
          else if ((this.item.status === 'Approved' || this.item.status === 'Updated') && this.item.consigneeProfileIncoTerms.length > 0) {
            await this.$API.updateConsigneeProfile(this.item);
            this.$emit("refresh");
            this.$emit("back");
            this.savingProfile = false;
          }
          else if (this.item.status === 'Inactive') {
            await this.$API.updateConsigneeProfile(this.item);
            this.$emit("refresh");
            this.$emit("back");
            this.savingProfile = false;
          }
          else {
            this.$message.error("Inco Term is required to save the profile");
            this.savingProfile = false;
          }
        } else {
          if (this.item.status === 'Draft') {
            await this.$API.createConsigneeProfile(this.item);
            this.$emit("refresh");
            this.$emit("back");
            this.savingProfile = false;
          }
          else if ((this.item.status === 'Approved' || this.item.status === 'Updated') && this.item.consigneeProfileIncoTerms.length > 0) {
            await this.$API.createConsigneeProfile(this.item);
            this.$emit("refresh");
            this.$emit("back");
            this.savingProfile = false;
          }
          else if (this.item.status === 'Inactive') {
            await this.$API.createConsigneeProfile(this.item);
            this.$emit("refresh");
            this.$emit("back");
            this.savingProfile = false
          }
          else {
            this.$message.error("Inco Term is required to save the profile");
            this.savingProfile = false;
          }
        }
        // this.$emit("refresh");
        // this.$emit("back");
        // this.savingProfile = false;
      },
      updateStatus(status) {
        this.item.status = status;
        // this.saveConsigneeProfile();
      },
      async verifyProfile() {
        if (this.verifyInformation()) {
          this.item.status = "Approved";
  
          if (this.item.id) {
            await this.$API.updateConsigneeProfile(this.item);
          }
          this.$message.success("Profile has been approved");
        } else {
          this.verificationItems = [];
          if (!this.item.shipperId) {
            this.verificationItems.push("Shipper is required");
          }
          if (!this.item.forwarderId) {
            this.verificationItems.push("Forwarder is required");
          }
          if (!this.item.consigneeId) {
            this.verificationItems.push("Consignee is required");
          }
          if (!this.item.incoTerm) {
            this.verificationItems.push("Default Inco Term is required");
          }
          if (!this.item.originCountryId) {
            this.verificationItems.push("Origin Country is required");
          }
          if (!this.item.destinationCountryId) {
            this.verificationItems.push("Destination Country is required");
          }
          this.verificationModal = true;
        }
      },
      update(result) {
        this.consigneeProfileComments[
          this.consigneeProfileComments.length - 1
        ] = result;
      },
      updateProfileDocument(item) {
        let find = this.item.consigneeProfileDocuments.findIndex(x => x.id == item.id)
        if (find > -1) {
          this.item.consigneeProfileDocuments.push(
            item
          );
          this.countryKey++
        }
        else {
          this.item.consigneeProfileDocuments.push(
            item
          );
          this.countryKey++
        }
      },
      verifyInformation() {
        return (
          this.item.shipperId &&
          this.item.forwarderId &&
          this.item.consigneeId &&
          this.item.originCountryId &&
          this.item.destinationCountryId
        );
      },
    },
  };
  </script>
  