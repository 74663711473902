<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="4">
        <v-card outlined class="my-0 py-0" id="country-ports"
          style="background-color: var(--v-toolbar-lighten1) !important">
          <v-card-text>
            <v-list dense subheader :key="locationKey">
              <v-subheader style="font-size: 16px">
                <v-icon class="mr-2" color="grey">location_on</v-icon>
                Location Settings</v-subheader>
              <v-divider></v-divider>
              <v-list-item @click="(countryItemType = 'Origin'), (countryDialog = true)">
                <v-list-item-action>
                  <v-icon :color="!item.originCountry ? 'secondary' : 'success'">public</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-if="item.originCountry">
                    {{ item.originCountry.name }}
                  </v-list-item-title>
                  <v-list-item-title v-else>-</v-list-item-title>
                  <v-list-item-subtitle> Origin Country </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-avatar size="32">
                    <v-img contain v-if="item.originCountry"
                      :src="`https://cdn.loglive.io/flags/4x3/${item.originCountry.iso2.toLowerCase()}.svg`"></v-img>
                  </v-avatar>
                </v-list-item-action>
              </v-list-item>

              <v-list-item @click="editCountry('POL')">
                <v-list-item-action>
                  <v-icon :color="item.polCountry ? 'success' : 'grey'">anchor</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-if="item.polCountry && item.allOriginPorts">
                    {{ item.polCountry.name }}
                  </v-list-item-title>
                  <v-list-item-title v-else>-</v-list-item-title>
                  <v-list-item-subtitle>
                    Port(s) of Loading
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-tooltip top v-if="item.polCountry && item.allOriginPorts">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on" outlined class="mr-1 mb-1" close-icon="close" x-small>
                        <v-avatar size="24" left>
                          <v-img contain v-if="item.polCountry && item.polCountry.iso2"
                            :src="`https://cdn.loglive.io/flags/4x3/${item.polCountry.iso2.toLowerCase()}.svg`"></v-img>
                        </v-avatar>
                        All Ports
                      </v-chip>
                    </template>
                    <span>{{ item.polCountry.name }}</span>
                  </v-tooltip>
                  <v-list v-else subheader>
                    <v-tooltip top v-for="port in loadingPorts" :key="port.id">
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip v-bind="attrs" v-on="on" outlined x-small @click:close="removePort(port)"
                          class="mr-1 mb-1" close-icon="close">
                          <v-avatar size="24" left>
                            <v-img contain v-if="port.code" :src="`https://cdn.loglive.io/flags/4x3/${port.code
                              .substring(0, 2)
                              .toLowerCase()}.svg`"></v-img>
                          </v-avatar>
                          {{ port.code }}
                        </v-chip>
                      </template>
                      <span style="font-size: 12px">{{ port.name }}</span>
                    </v-tooltip>

                    <v-list-item v-if="loadingPorts.length == 0">
                      <v-list-item-content class="text-center">
                        <span style="color: grey">No specified ports.</span>
                      </v-list-item-content></v-list-item>
                  </v-list>
                </v-list-item-action>
              </v-list-item>

              <v-list-item @click="editCountry('POD')">
                <v-list-item-action>
                  <v-icon :color="item.podCountry ? 'success' : 'grey'">anchor</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-if="item.podCountry && item.allDestinationPorts">
                    {{ item.podCountry.name }}
                  </v-list-item-title>
                  <v-list-item-title v-else>-</v-list-item-title>
                  <v-list-item-subtitle>
                    Port(s) of Discharge
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-tooltip top v-if="item.podCountry && item.allDestinationPorts">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on" outlined class="mr-1 mb-1" close-icon="close" x-small>
                        <v-avatar size="24" left>
                          <v-img contain v-if="item.podCountry && item.podCountry.iso2"
                            :src="`https://cdn.loglive.io/flags/4x3/${item.podCountry.iso2.toLowerCase()}.svg`"></v-img>
                        </v-avatar>
                        All Ports
                      </v-chip>
                    </template>
                    <span>{{ item.podCountry.name }}</span>
                  </v-tooltip>
                  <v-list v-else subheader>
                    <v-tooltip top v-for="port in dischargePorts" :key="port.id">
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip v-bind="attrs" v-on="on" outlined x-small @click:close="removePort(port)"
                          class="mr-1 mb-1" close-icon="close">
                          <v-avatar size="24" left>
                            <v-img contain v-if="port.code" :src="`https://cdn.loglive.io/flags/4x3/${port.code
                              .substring(0, 2)
                              .toLowerCase()}.svg`"></v-img>
                          </v-avatar>
                          {{ port.code }}
                        </v-chip>
                      </template>
                      <span style="font-size: 12px">{{ port.name }}</span>
                    </v-tooltip>

                    <v-list-item v-if="dischargePorts.length == 0">
                      <v-list-item-content class="text-center">
                        <span style="color: grey">No specified ports.</span>
                      </v-list-item-content></v-list-item>
                  </v-list>
                </v-list-item-action>
              </v-list-item>

              <v-list-item @click="fdModal = true">
                <v-list-item-action>
                  <v-icon :color="item.finalDestinationValue ? 'success' : 'grey'">public</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-if="item.finalDestinationCity">
                    {{ item.finalDestinationCity }}
                  </v-list-item-title>
                  <v-list-item-title v-else>-</v-list-item-title>
                  <v-list-item-subtitle>
                    Final Destination
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-tooltip top v-if="item.finalDestinationValue">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on" outlined class="mr-1 mb-1" close-icon="close" pill small>
                        <v-avatar size="32" left>
                          <v-img contain v-if="item.finalDestinationValue" :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue
                            .substring(0, 2)
                            .toLowerCase()}.svg`"></v-img>
                        </v-avatar>
                        {{ item.finalDestinationValue }}
                      </v-chip>
                    </template>
                    <span>{{ item.finalDestinationCity }}</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>

              <v-list-item @click="openDocumentationPODModal(item.documentationPOD)">
                <v-list-item-action>
                  <v-icon :color="item.documentationPOD ? 'success' : 'grey'">description</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    Documentation POD
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-tooltip top v-if="item.documentationPOD">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on" outlined class="mr-1 mb-1" close-icon="close" pill small>
                        <v-avatar size="32" left>
                          <v-img contain v-if="item.documentationPOD" :src="`https://cdn.loglive.io/flags/4x3/${item.documentationPOD
                            .substring(0, 2)
                            .toLowerCase()}.svg`"></v-img>
                        </v-avatar>
                        {{ item.documentationPOD }}
                      </v-chip>
                    </template>
                    <span>{{ item.documentationPOD }}</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>

              <v-list-item @click="
                (countryItemType = 'Destination'), (countryDialog = true)
                ">
                <v-list-item-action>
                  <v-icon :color="!item.destinationCountry ? 'secondary' : 'success'">public</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-if="item.destinationCountry">
                    {{ item.destinationCountry.name }}
                  </v-list-item-title>
                  <v-list-item-title v-else>-</v-list-item-title>
                  <v-list-item-subtitle>
                    Destination Country
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-avatar size="32">
                    <v-img contain v-if="item.destinationCountry"
                      :src="`https://cdn.loglive.io/flags/4x3/${item.destinationCountry.iso2.toLowerCase()}.svg`"></v-img>
                  </v-avatar>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>

      </v-col>
      <v-col cols="12" md="6">
        <!-- 
        <v-row> -->
        <!-- <v-col cols="12" md="6">
            <v-card outlined id="subscribers">
              <v-card-title style="font-size: 16px" class="mb-0 pb-0"
                ><v-icon left color="grey">notifications</v-icon>
                Subscribers
              </v-card-title>
              <v-card-subtitle style="font-size: 12px" class="my-0 py-0">
                These are the users/teams that will receive notifications when
                any changes are made to this profile.
              </v-card-subtitle>
              <v-card-text class="mt-0 pt-0">
                <v-row>
                  <v-col cols="12" md="6" class="my-0 py-0">
                    <v-subheader class="mt-0 pt-0"
                      ><v-icon class="mr-2">groups</v-icon> Teams<v-btn
                        icon
                        @click="editTeams()"
                        id="add-team-subscribers"
                        color="primary"
                        ><v-icon>add_circle_outline</v-icon></v-btn
                      ></v-subheader
                    >
                    <v-list dense>
                      <v-list-item
                        v-for="team in teams"
                        :key="team.id"
                        :style="{ 'border-left': `5px solid ${team.colour}` }"
                        class="my-1"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ team.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="allTeams.length > 0">
                            {{ team.userTeams.length }} Member(s)
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="teams.length == 0">
                        <v-list-item-content class="text-center">
                          <span style="color: grey">No teams listed</span>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col cols="12" md="6" class="my-0 py-0">
                    <v-subheader class="mt-0 pt-0"
                      ><v-icon class="mr-2">groups</v-icon> Users<v-btn
                        icon
                        @click="editUsers()"
                        color="primary"
                        id="add-user-subscribers"
                        ><v-icon>add_circle_outline</v-icon></v-btn
                      ></v-subheader
                    >
                    <v-list dense>
                      <v-list-item
                        v-for="user in subscribedUsers"
                        :key="user.organisationUserId"
                        class="my-1"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ user.user.firstname }} {{ user.user.surname }}
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="user.user.emailAddress">
                            <v-icon class="mr-1" small>email</v-icon>
                            {{ user.user.emailAddress }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="subscribedUsers.length == 0">
                        <v-list-item-content class="text-center">
                          <span style="color: grey">No users listed</span>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col> -->
        <!-- <v-col cols="12" md="6">
            
          </v-col>
        </v-row> -->
        <v-card outlined id="products" :loading="loadingProduct"
          style="background-color: var(--v-toolbar-lighten1) !important">
          <v-card-text>
            <v-list dense subheader>
              <v-subheader style="font-size: 16px">
                <v-icon left color="grey">confirmation_number</v-icon> Booking
                Template
              </v-subheader>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-list dense class="text-left">
                    <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>import_export</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Movement Type</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip small @click="changeMovementType()"
                          :color="item.movementType == 'EXPORT' ? 'deep-orange' : 'blue darken-2'">
                          <v-icon small left>import_export</v-icon>
                          <span style="text-transform: none; color: white">
                            {{ $Format.capitalizeFirstLetter(item.movementType) }}
                          </span>
                        </v-chip>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>airline_stops</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Cargo Service Type</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu offset-y rounded>
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" small color="indigo">
                              <v-icon small left>import_export</v-icon>
                              <span style="text-transform: none; color: white">
                                {{ item.serviceType }}
                              </span>
                            </v-chip>
                          </template>
                          <v-list dense subheader>
                            <v-list-item @click="item.serviceType = 'FCL'">
                              <v-list-item-content>
                                <v-list-item-title>FCL</v-list-item-title>
                                <v-list-item-subtitle>Full Container Load</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="item.serviceType = 'LCL'">
                              <v-list-item-content>
                                <v-list-item-title>LCL</v-list-item-title>
                                <v-list-item-subtitle>Less than Container
                                  Load</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="item.serviceType = 'BREAKBULK'">
                              <v-list-item-content>
                                <v-list-item-title>Breakbulk</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>category</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Booking Type</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu offset-y rounded>
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" small color="teal">
                              <v-icon small left v-if="item.type == 'LOGISTICS'">mode_of_travel</v-icon>
                              <v-icon small left v-else-if="item.type == 'DOCUMENTATION'">article</v-icon>
                              <v-icon small left v-else-if="item.type == 'HAULAGE'">local_shipping</v-icon>
                              <v-icon small left v-else-if="item.type == 'MONITORING'">thermostat</v-icon>

                              <span style="text-transform: none; color: white">
                                {{ $Format.capitalizeFirstLetter(item.type) }}
                              </span>
                            </v-chip>
                          </template>
                          <v-list dense subheader>
                            <v-list-item @click="item.type = 'LOGISTICS'">
                              <v-list-item-content>
                                <v-list-item-title>Logistics</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="item.type = 'DOCUMENTATION'">
                              <v-list-item-content>
                                <v-list-item-title>Documentation Only</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item @click="item.type = 'HAULAGE'">
                              <v-list-item-content>
                                <v-list-item-title>Transport Only</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="item.type = 'MONITORING'">
                              <v-list-item-content>
                                <v-list-item-title>Monitoring Only</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>

                    <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>mode_of_travel</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Mode of Transport</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu offset-y rounded>
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" small color="blue-grey darken-2">
                              <v-icon small left v-if="item.modeOfTransport == 'OCEAN'">directions_boat</v-icon>
                              <v-icon small left v-else-if="item.modeOfTransport == 'AIR'">flight</v-icon>
                              <v-icon small left v-else-if="item.modeOfTransport == 'RAIL'">train</v-icon>
                              <v-icon small left v-else-if="item.modeOfTransport == 'ROAD'">local_shipping</v-icon>

                              <span style="text-transform: none; color: white">
                                {{
                                  $Format.capitalizeFirstLetter(item.modeOfTransport)
                                }}
                              </span>
                            </v-chip>
                          </template>
                          <v-list dense subheader>
                            <v-list-item @click="item.modeOfTransport = 'OCEAN'">
                              <v-list-item-content>
                                <v-list-item-title>Ocean</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="item.modeOfTransport = 'AIR'">
                              <v-list-item-content>
                                <v-list-item-title>Air</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="item.modeOfTransport = 'ROAD'">
                              <v-list-item-content>
                                <v-list-item-title>Road</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="item.modeOfTransport = 'RAIL'">
                              <v-list-item-content>
                                <v-list-item-title>Rail</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <v-list dense subheader>
                    <v-subheader>General Settings</v-subheader>
                    <v-divider></v-divider>
                    <v-list-item @click="caProductModal = true">
                      <v-list-item-action>
                        <v-icon :color="!item.caProduct ? 'secondary' : 'grey'">thermostat_auto</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="item.caProduct">
                          {{ item.caProduct }}
                        </v-list-item-title>
                        <v-list-item-title v-else>-</v-list-item-title>
                        <v-list-item-subtitle>
                          Controlled Atmosphere
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- <v-list-item @click="getRegimeCodes(true)">
                      <v-list-item-action>
                        <v-icon
                          :color="!item.regimeCode ? 'secondary' : 'success'"
                          >tag</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="item.regimeCode">
                          {{ item.regimeCode }}
                          <v-chip
                            class="ml-2"
                            outlined
                            small
                            v-if="item.regime && item.regime.steri"
                          >
                            <v-icon left color="blue" small>ac_unit</v-icon
                            >Steri Code
                          </v-chip>
                        </v-list-item-title>
                        <v-list-item-title v-else>-</v-list-item-title>
                        <v-list-item-subtitle v-if="item.regime">
                          <v-icon class="mr-2" color="grey" small
                            >thermostat</v-icon
                          >Set Point: {{ item.regime.setPointTemp }}°C
                          <v-icon class="mx-2" color="red" small
                            >thermostat</v-icon
                          >Max: {{ item.regime.maxProductTemp }}°C
                          <v-icon class="mx-2" color="blue" small
                            >thermostat</v-icon
                          >Min {{ item.regime.minProductTemp }}°C
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          Regime Code
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item> -->

                    <v-list-item @click="setSelectableParties(), (haulierModal = true)" :key="haulierKey">
                      <v-list-item-action>
                        <v-avatar color="white" size="32" v-if="item.transportCoordinator">
                          <v-img v-if="item.transportCoordinator.logo" :src="item.transportCoordinator.logo"
                            contain></v-img>
                          <v-icon small v-else color="black">{{
                            item.transportCoordinator.icon
                          }}</v-icon>
                        </v-avatar>
                        <v-icon v-else color="secondary">local_shipping</v-icon>
                      </v-list-item-action>
                      <v-list-item-content :key="haulierKey">
                        <v-list-item-title v-if="item.transportCoordinator">
                          {{ item.transportCoordinator.name }}
                        </v-list-item-title>
                        <v-list-item-title v-else>-</v-list-item-title>
                        <v-list-item-subtitle
                          v-if="item.transportCoordinator && item.movementType == 'EXPORT' && item.preAdviseRequired"><v-chip
                            color="blue" x-small>Preadvise Required</v-chip></v-list-item-subtitle>
                        <v-list-item-subtitle>Transport Coordinator</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-list dense subheader>
                    <v-subheader>Cargo Settings <v-spacer></v-spacer>
                      <v-btn icon color="primary" @click="addContainerDetails()">
                        <v-icon>add_circle_outline</v-icon>
                      </v-btn></v-subheader>
                    <v-divider></v-divider>
                    <v-list-item v-for="container in item.bookingContainerItems" :key="container.index">
                      <v-list-item-action>
                        <v-chip>{{ container.quantity }}</v-chip>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{
                            getContainerDescription(container.containerTypeCode)
                          }}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="container.bookingCargoItems &&
                            container.bookingCargoItems.length > 0
                            ">
                          <v-icon left small color="grey">category</v-icon>Products:
                          {{
                            container.bookingCargoItems.filter(x=>x.product)
                              .map((x) => x.product.name)
                              .join(", ")
                          }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-else>
                          <v-icon left small color="grey">category</v-icon>No
                          Products
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="container.temporaryProducts && container.temporaryProducts.length > 0"
                          dense>
                          <div style="margin-top:5px">
                            <v-list-item>
                              <div v-for="(product, index) in mapTemporaryProducts(container.temporaryProducts)"
                                :key="index">
                                <v-btn icon outlined small :color="product.color" class="my-1">
                                  
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                     
                                      <v-icon small v-on="on">{{ product.icon }}</v-icon>
                                  
                                    </template>
                                    <span style="white-space:pre-line;">{{ product.product.replace(/_/g, ' ') }} </span>
                                  </v-tooltip>
                                </v-btn>
                              </div>
                            </v-list-item>
                          </div>
                        </v-list-item-subtitle>
                        <!-- <v-list-item-subtitle v-if="container.temporaryProducts && container.temporaryProducts.length>0">
                          <v-list-item 
                                    v-for="(product, index) in mapTemporaryProducts(container.temporaryProducts)" 
                                    :key="index">
                                    <v-tooltip top >
                                      <template v-slot:activator="{ on }">
                                        <v-btn icon outlined small v-on="on" :color="product.color"><v-icon
                                            small>{{product.icon}}</v-icon></v-btn>
                                      </template>
                                      <span>{{ product.product + ' ' + 'Required'}} </span>
                                    </v-tooltip>
                           
                                  </v-list-item>
                        </v-list-item-subtitle> -->
                        <!-- <v-list-item-subtitle>
                          <v-row class="my-1 ml-3">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-chip
                                  :color="
                                    container.gensetRequired ? '#c4c166' : ''
                                  "
                                  v-on="on"
                                  x-small
                                  class="mx-1"
                                >
                                  <v-icon small>bolt</v-icon>
                                </v-chip>
                              </template>
                              <span v-if="container.gensetRequired"
                                >Genset: Required
                              </span>
                              <span v-else>Genset: Not Required </span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-chip
                                  :color="
                                    container.controlledAtmosphere
                                      ? '#2383de'
                                      : ''
                                  "
                                  v-on="on"
                                  x-small
                                  class="mx-1"
                                >
                                  <v-icon small>thermostat_auto</v-icon>
                                </v-chip>
                              </template>
                              <span v-if="container.caProduct"
                                >Controlled Atmosphere:
                                {{ container.caProduct }}</span
                              >
                              <span v-else>Controlled Atmosphere: N/A</span>
                            </v-tooltip>

                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-chip
                                  :color="container.sensorDevice ? 'teal' : ''"
                                  v-on="on"
                                  x-small
                                  class="mx-1"
                                >
                                  <v-icon small>router</v-icon>
                                </v-chip>
                              </template>
                              <span
                                >Monitoring Device:
                                {{ container.sensorDeviceType }}
                                {{
                                  container.sensorService
                                    ? " with " +
                                      container.sensorService +
                                      " service"
                                    : "without a service"
                                }}
                              </span>
                            </v-tooltip>
                          </v-row>
                        </v-list-item-subtitle> -->
                        <v-list-item-subtitle class="text-wrap">
                          {{ container.comment }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-row justify="center">
                          <v-btn icon small @click="editContainerItem(container)"><v-icon small>edit</v-icon></v-btn>
                          <v-btn icon small @click="deleteContainerItem(container)" color="red"><v-icon
                              small>delete</v-icon></v-btn>
                        </v-row>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item v-if="!item.bookingContainerItems ||
                      item.bookingContainerItems.length == 0
                      " class="text-center">
                      <v-list-item-content>
                        <span style="color: grey"> No container items </span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-list>
          </v-card-text>
        </v-card>
        <v-row>
          <v-col cols="12" sm="6">

            <v-card outlined id="deal-terms" class="mt-2" style="background-color: var(--v-toolbar-lighten1) !important">
              <v-card-title style="font-size: 16px"><v-icon left color="grey">payments</v-icon> Deal Terms
                <v-btn icon color="primary" @click="addPayment({})"
                  id="add-deal-terms"><v-icon>add_circle_outline</v-icon></v-btn>
              </v-card-title>
              <v-card-text class="my-0" :key="contractKey">
                <v-list dense>
                  <v-list-item v-if="item.consigneeProfileIncoTerms.length == 0">
                    <v-list-item-content>
                      <span style="color: grey">No Deal Terms listed</span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-card class="mt-0 pt-0" flat v-for="(profile, index) in item.consigneeProfileIncoTerms"
                    :key="profile.index">
                    <v-card-title class="my-0 py-0" style="font-size: 16px">
                      <b>{{ profile.incoTerm }}</b>
                      <v-spacer></v-spacer>
                      <v-btn icon small @click="editPayment(profile)" id="edit-deal-terms"><v-icon
                          small>edit</v-icon></v-btn>
                      <v-btn icon small color="red" @click="removeProfile(profile)" id="delete-deal-terms"><v-icon
                          small>delete</v-icon></v-btn>
                      <v-btn icon @click="
                        expandedProfile == index
                          ? (expandedProfile = null)
                          : (expandedProfile = index)
                        " id="deal-terms-dropdown">
                        <v-icon>{{
                          expandedProfile == index ? "expand_less" : "expand_more"
                        }}</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-subtitle style="font-size: 12px" v-if="profile.contractOwner">
                      {{ profile.contractOwner }}
                    </v-card-subtitle>
                    <v-card-subtitle style="font-size: 12px" v-if="profile.comment">
                      {{ profile.comment }}
                    </v-card-subtitle>
                    <v-card-text class="my-0 py-0">
                      <v-expand-transition :key="contractKey">
                        <div v-show="expandedProfile == index">
                          <v-divider></v-divider>
                          <v-row>
                            <v-col cols="12" sm="6">
                              <v-list dense subheader>
                                <v-subheader>Charges</v-subheader>
                                <v-list-item style="height: 25px">
                                  <v-list-item-content>
                                    <v-list-item-title style="
                                    text-transform: capitalize;
                                    font-size: 14px;
                                  ">
                                      {{ profile.paymentTerm }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      Freight Payment Term
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item style="height: 25px">
                                  <v-list-item-content>
                                    <v-list-item-title style="
                                    text-transform: capitalize;
                                    font-size: 14px;
                                  ">
                                      {{ profile.freightCharge }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      Freight Charge
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item style="height: 25px">
                                  <v-list-item-content>
                                    <v-list-item-title style="
                                    text-transform: capitalize;
                                    font-size: 14px;
                                  ">
                                      {{ profile.originCharge }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      Origin Charge
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item style="height: 25px">
                                  <v-list-item-content>
                                    <v-list-item-title style="
                                    text-transform: capitalize;
                                    font-size: 14px;
                                  ">
                                      {{ profile.destinationCharge }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      Destination Charge
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-list dense subheader>
                                <v-subheader>Shipping Lines</v-subheader>
                                <v-list-item style="cursor: pointer"
                                  v-for="shippingLine in profile.consigneeProfileShippingContracts"
                                  :key="shippingLine.index">
                                  <v-list-item-action>
                                    <v-chip>{{ shippingLine.index + 1 }}</v-chip>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title v-if="shippingLine.shippingLine">
                                      {{ shippingLine.shippingLine.name }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      {{ shippingLine.contractNumber }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle style="text-transform: capitalize">
                                      {{ shippingLine.contractOwner }}
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="profile.consigneeProfileShippingContracts
                                  .length == 0
                                  ">
                                  <v-list-item-content class="text-center">
                                    <span style="font-size: 12px; color: grey">No shipping lines listed</span>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-col>
                          </v-row>
                        </div>
                      </v-expand-transition>
                    </v-card-text>
                  </v-card>
                </v-list>
              </v-card-text>
            </v-card>

          </v-col>
          <v-col cols="12" sm="6" >
            <v-card outlined id="ucr-number" class="mt-2" style="background-color: var(--v-toolbar-lighten1) !important">
              <v-card-title style="font-size: 16px"><v-icon left color="grey">published_with_changes</v-icon> Manual override UCR Number</v-card-title>
              <v-card-text>
                  <v-text-field 
                  v-model="item.ucrNo"
                  outlined 
                  small 
                  dense 
                  label="UCR Number"
                  hint="*Saving this will update this profile and all linked Shipments"
                  persistent-hint>
                  <template v-slot:prepend>
                    <v-icon color="grey">tag</v-icon>
                  </template>
                  <template v-slot:append-outer>
                    <v-btn icon text style="margin-top: -7px;" @click="saveUCRNumber()" :loading="savingUCR">
                      <v-icon color="primary">save</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-card-text>
            </v-card>

            <v-card outlined class="mt-2" id="distribution-list"
              style="background-color: var(--v-toolbar-lighten1) !important">
              <v-card-text>
                <v-list dense :key="emailKey" subheader>
                  <v-subheader style="font-size: 16px">
                    <v-icon left color="grey">email</v-icon> Distribution List
                    <v-spacer></v-spacer>
                    <v-btn icon color="primary" @click="addEmail()"
                      id="add-dist-list"><v-icon>add_circle_outline</v-icon></v-btn>
                  </v-subheader>
                  <v-divider></v-divider>

                  <v-list-item v-if="item.consigneeProfileDistributionLists.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="color: grey">No email addresses</span>
                    </v-list-item-content></v-list-item>

                  <v-list-item v-for="email in item.consigneeProfileDistributionLists" :key="email.index">
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 14px">{{
                        email.email
                      }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-row justify="center">
                        <v-btn icon small @click="editEmail(email)" id="edit-dist-list">
                          <v-icon small>edit</v-icon>
                        </v-btn>
                        <v-btn icon small color="red" @click="removeEmail(email)" id="remove-dist-list">
                          <v-icon small>delete</v-icon>
                        </v-btn>
                      </v-row>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- <v-card outlined id="products" :loading="loadingProduct">
          <v-card-title style="font-size: 16px"
            ><v-icon left color="grey">category</v-icon> Products
            <v-btn icon color="primary" @click="addProduct()" id="add-product"
              ><v-icon>add_circle_outline</v-icon></v-btn
            >
          </v-card-title>
          <v-card-text :key="productKey">
            <v-list dense :key="productKey">
              <v-list-item
                v-for="(product, index) in item.consigneeProfileProducts"
                :key="index"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ product.product.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    HS Code:
                    {{
                      product.product.hsCode
                        ? product.product.hsCode.alternateCode
                        : "No HS Code Loaded Against Product!"
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn @click="removeProduct(product)" icon color="red"
                    ><v-icon>delete</v-icon></v-btn
                  >
                </v-list-item-action>
              </v-list-item>
              <v-list-item v-if="item.consigneeProfileProducts.length == 0">
                <v-list-item-content class="text-center">
                  <span style="color: grey">No products selected</span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card> -->
      </v-col>
      <!--
       
      </v-col> -->
    </v-row>

    <v-dialog v-model="documentationPODModal" persistent width="400px" :fullscreen="$vuetify.bre">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Point of Entry </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="documentationPODModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <!-- <v-col cols="3">
          <v-radio-group v-model="selectedRadio">
            <v-radio label="All" value="all"></v-radio>  
          </v-radio-group>
        </v-col> -->
            <v-col cols="4">
              <v-radio-group v-model="selectedRadio">
                <v-radio label="Sea" value="sea"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="4">
              <v-radio-group v-model="selectedRadio">
                <v-radio label="Air" value="air"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="4">
              <v-radio-group v-model="selectedRadio">
                <v-radio label="Rail" value="rail"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-autocomplete hide-no-data dense class="mt-2" outlined clearable :loading="documentationPODLoading"
            :search-input.sync="documentationPODSearch" v-model="item.documentationPOD"
            :menu-props="{ closeOnContentClick: true }" label="Search" :items="ports" item-text="name" item-value="locode"
            :filter="filterObject">
            <template v-slot:selection="data">
              <span style="font-size: 12px">{{ data.item.name }} ({{ data.item.locode }})</span>
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.item.locode }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="portModal" persistent width="750px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Manage Ports </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="portModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text v-if="countryItemType == 'POL'">
          <v-list dense>
            <v-row align="center">
              <v-col cols="12" sm="7">
                <v-list-item style="height: 25px">
                  <v-list-item-content>
                    <v-autocomplete class="mt-2" ref="originCountry" hide-no-data hide-selected placeholder="Country*"
                      dense outlined :items="countries" clearable item-text="name" item-value="id"
                      :menu-props="{ closeOnContentClick: true }" return-object v-model="item.polCountry"
                      :loading="loadingCountry">
                      <template v-slot:selection>
                        <v-avatar size="32" class="mr-2" v-if="item.polCountry">
                          <v-img contain v-if="item.polCountry.iso2"
                            :src="`https://cdn.loglive.io/flags/4x3/${item.polCountry.iso2.toLowerCase()}.svg`"></v-img>
                        </v-avatar>
                        <span v-if="item.polCountry">
                          {{ item.polCountry.name }}
                        </span>
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-avatar>
                          <v-img contain v-if="data.item.iso2"
                            :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="5">
                <v-list-item style="height: 25px" class="mb-4" @click="changePortType('origin')">
                  <v-list-item-action>
                    <v-icon v-if="item.allOriginPorts" color="success">check</v-icon>
                    <v-icon v-else color="red">close</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> All Origin Ports </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>

            <v-list v-if="!item.allOriginPorts" subheader class="mx-2 px-2">
              <v-row>
                <v-col cols="12" md="8">
                  <v-autocomplete hide-no-data dense class="mt-2" outlined clearable :loading="entryPointLoading"
                    return-object :search-input.sync="entryPointSearch" v-model="entryPort"
                    @change="addPort('loading', entryPort)" :menu-props="{ closeOnContentClick: true }"
                    label="Search Entry Point" :items="entryPoints" item-text="name" item-value="locode"
                    :filter="filterObject">
                    <template v-slot:selection="data">
                      <span style="font-size: 12px">{{ data.item.name }} ({{ data.item.locode }})</span>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>{{
                          data.item.name
                        }}</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ data.item.locode }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="4">
                  <v-select dense label="Filter" outlined class="mt-2" v-model="entryFilterPoint" :items="points"
                    item-text="name" item-value="value"></v-select>
                </v-col>
              </v-row>
              <v-tooltip top v-for="port in loadingPorts" :key="port.id">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-bind="attrs" v-on="on" outlined label close @click:close="removePort(port)" class="mr-1 mb-1"
                    close-icon="close">{{ port.code }}
                  </v-chip>
                </template>
                <span style="font-size: 12px">{{ port.name }}</span>
              </v-tooltip>
            </v-list>
          </v-list>
        </v-card-text>
        <v-card-text v-else-if="countryItemType == 'POD'">
          <v-list dense>
            <v-row align="center">
              <v-col cols="12" sm="7">
                <v-list-item style="height: 25px">
                  <v-list-item-content>
                    <v-autocomplete class="mt-2" ref="destinationCountry" hide-no-data hide-selected
                      placeholder="Country*" dense outlined :items="countries" clearable item-text="name" item-value="id"
                      :menu-props="{ closeOnContentClick: true }" return-object v-model="item.podCountry"
                      :loading="loadingCountry">
                      <template v-slot:selection>
                        <v-avatar size="32" class="mr-2" v-if="item.podCountry">
                          <v-img contain v-if="item.podCountry.iso2"
                            :src="`https://cdn.loglive.io/flags/4x3/${item.podCountry.iso2.toLowerCase()}.svg`"></v-img>
                        </v-avatar>
                        <span v-if="item.podCountry">
                          {{ item.podCountry.name }}
                        </span>
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-avatar>
                          <v-img contain v-if="data.item.iso2"
                            :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="5">
                <v-list-item style="height: 25px" class="mb-4" @click="changePortType('destination')">
                  <v-list-item-action>
                    <v-icon v-if="item.allDestinationPorts" color="success">check</v-icon>
                    <v-icon v-else color="red">close</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      All Destination Ports
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>

            <v-list v-if="!item.allDestinationPorts" subheader class="mx-2 px-2">
              <v-row>
                <v-col cols="12" md="8">
                  <v-autocomplete hide-no-data dense class="mt-2" outlined clearable :loading="destinationPointLoading"
                    return-object :search-input.sync="destinationPointSearch" v-model="destinationPort"
                    @change="addPort('discharge', destinationPort)" :menu-props="{ closeOnContentClick: true }"
                    label="Search Destination Point" :items="destinationPoints" item-text="name" item-value="locode"
                    :filter="filterObject">
                    <template v-slot:selection="data">
                      <span style="font-size: 12px">{{ data.item.name }} ({{ data.item.locode }})</span>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>{{
                          data.item.name
                        }}</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ data.item.locode }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="4">
                  <v-select dense label="Filter" outlined class="mt-2" v-model="entryFilterPoint" :items="points"
                    item-text="name" item-value="value"></v-select>
                </v-col>
              </v-row>
              <v-tooltip top v-for="port in dischargePorts" :key="port.id">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-bind="attrs" v-on="on" outlined label close @click:close="removePort(port)" class="mr-1 mb-1"
                    close-icon="close">{{ port.code }}
                  </v-chip>
                </template>
                <span style="font-size: 12px">{{ port.name }}</span>
              </v-tooltip>
            </v-list>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="fdModal" width="400px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2" color="secondary">location_on</v-icon> Manage
            Final Destination
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(fdModal = false), (portSearch = null)">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <template>
            <v-container class="pa-0">
              <!-- Chips Container -->
              <v-chip-group v-model="selectedTransportModes" active-class="primary--text" multiple>
                <v-chip v-for="transport in transportModes" :key="transport" :value="transport" filter small>
                  {{ transport }}
                </v-chip>
              </v-chip-group>
            </v-container>
          </template>
          <v-text-field class="my-0 py-0" autofocus prepend-inner-icon="search" dense placeholder="Search" outlined
            clearable v-model="portSearch" hide-details></v-text-field>
          <v-progress-linear v-if="loadingPortSearch" color="primary" indeterminate></v-progress-linear>
          <v-expand-transition>
            <v-card flat class="my-0 py-0 mx-0 px-0">
              <v-card-text class="my-0 py-0 mx-0 px-0">
                <v-virtual-scroll v-if="portResults.length > 0" class="my-0 py-0" :bench="0" :items="portResults"
                  height="250" item-height="50">
                  <template v-slot:default="{ item }">
                    <v-list-item :key="item.id" @click="setFinalDestination(item)">
                      <v-list-item-avatar>
                        <v-avatar size="24" class="mr-1" left>
                          <v-img contain v-if="item.locode" :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                            .substring(0, 2)
                            .toLowerCase()}.svg`"></v-img>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 14px">
                          {{ item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 12px; color: grey">
                          {{ item.locode }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
                <v-list-item v-else>
                  <v-list-item-content class="text-center">
                    <span style="color: grey; font-size: 12px">No results found</span>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-expand-transition>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Inco Term Dialog -->
    <v-dialog v-model="incoTermDialog" scrollable width="1200px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-card-title>Setup Terms <v-spacer></v-spacer>
          <v-btn color="primary" text :loading="savingIncoTerm" :disabled="!incoTerm.incoTerm ||
            !incoTerm.paymentTerm ||
            !incoTerm.originCharge ||
            !incoTerm.freightCharge ||
            !incoTerm.destinationCharge ||
            !incoTerm.contractOwner
            " @click="saveIncoTerm()">
            Submit
          </v-btn>
          <v-btn text @click="(incoTermDialog = false), (incoTerm = {})">X</v-btn>
        </v-card-title>
        <v-card-text class="pt-2">
          <v-row>
            <v-col cols="12" md="4">
              <v-subheader style="font-size: 16px">Details</v-subheader>
              <v-select dense outlined clearable label="Inco Term*" v-model="incoTerm.incoTerm" :items="incoTerms"
                item-text="abbreviation" item-value="abbreviation">
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      data.item.abbreviation
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-select>
              <v-select clearable outlined dense label="Contract Owner*" :items="filteredCharges"
                v-model="incoTerm.contractOwner" item-text="friendlyName" item-value="value"
                style="text-transform: capitalize" @change="setParties()">
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.friendlyName }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-select>

              <v-select v-model="incoTerm.originTerm" outlined dense label="Origin Payment Term*" item-text="value"
                item-value="key" :items="paymentTerms" clearable>
              </v-select>
              <v-select clearable outlined dense label="Origin Charges*" :items="filteredCharges"
                v-model="incoTerm.originCharge" item-text="friendlyName" item-value="value"
                style="text-transform: capitalize">
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.friendlyName }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-select>
              <v-select clearable outlined dense label="Forwarder Invoice Origin Charge To" :items="filteredCharges"
                v-if="incoTerm.originCharge == 'forwarder'" v-model="incoTerm.originInvoiceTo" item-text="friendlyName"
                item-value="value" style="text-transform: capitalize" ref="originInvoice">
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.friendlyName }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-select>

              <v-select clearable v-model="incoTerm.paymentTerm" outlined dense label="Freight Payment Term*"
                item-text="value" item-value="key" :items="paymentTerms">
              </v-select>
              <v-select clearable outlined dense label="Freight Charges*" :items="filteredCharges"
                item-text="friendlyName" item-value="value" v-model="incoTerm.freightCharge"
                style="text-transform: capitalize">
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.friendlyName }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-select>
              <v-select clearable outlined dense label="Forwarder Invoice Freight Charge To" :items="filteredCharges"
                v-if="incoTerm.freightCharge == 'forwarder'" v-model="incoTerm.freightInvoiceTo" item-text="friendlyName"
                item-value="value" style="text-transform: capitalize" ref="freightInvoice">
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.friendlyName }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-select>

              <v-select clearable v-model="incoTerm.destinationTerm" outlined dense label="Destination Payment Term*"
                item-text="value" item-value="key" :items="paymentTerms"></v-select>
              <v-select clearable outlined dense label="Destination Charges*" :items="filteredCharges"
                v-model="incoTerm.destinationCharge" style="text-transform: capitalize" item-text="friendlyName"
                item-value="value">
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.friendlyName }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-select>
              <v-select clearable outlined dense label="Forwarder Invoice Destination Charge To" :items="filteredCharges"
                v-if="incoTerm.destinationCharge == 'forwarder'" v-model="incoTerm.destinationInvoiceTo"
                item-text="friendlyName" item-value="value" style="text-transform: capitalize" ref="destinationInvoice">
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.friendlyName }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="8">
              <v-subheader>Shipping Lines
                <v-btn icon color="primary" :disabled="!incoTerm.contractOwner"
                  @click="addShippingContract"><v-icon>add_circle_outline</v-icon>
                </v-btn>
              </v-subheader>
              <v-list dense :key="contractKey">
                <draggable group="contracts" v-model="incoTerm.consigneeProfileShippingContracts"
                  @change="updateContractIndex">
                  <v-list-item style="cursor: pointer" v-for="shippingLine in incoTerm.consigneeProfileShippingContracts"
                    :key="shippingLine.index">
                    <v-list-item-action>
                      <v-chip>{{ shippingLine.index + 1 }}</v-chip>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="shippingLine.shippingLine">
                        {{ shippingLine.shippingLine.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ shippingLine.contractNumber }}
                      </v-list-item-subtitle>
                      <!-- <v-list-item-subtitle style="text-transform: capitalize">
                        {{ shippingLine.contractOwner }}
                      </v-list-item-subtitle> -->
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-row>
                        <v-btn icon small @click="editShippingContract(shippingLine)"><v-icon small>edit</v-icon></v-btn>
                        <v-btn small icon color="red" @click="removeContract(shippingLine)"><v-icon
                            small>delete</v-icon></v-btn>
                      </v-row>
                    </v-list-item-action>
                  </v-list-item>
                </draggable>
                <v-list-item v-if="incoTerm &&
                  incoTerm.consigneeProfileShippingContracts &&
                  incoTerm.consigneeProfileShippingContracts.length == 0
                  ">
                  <v-list-item-content class="text-center">
                    <span style="color: grey">No contracts listed</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-row>
                <v-col cols="12" class="mt-0 pt-0">
                  <v-textarea v-model="incoTerm.comment" label="Notes" outlined rows="3"></v-textarea>
                </v-col>
              </v-row>
            </v-col>
            *You need to select the owner of the contract before you can add
            Shipping Lines.
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="emailDialog" persistent width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-card-title>Distribution <v-spacer></v-spacer>
          <v-btn text @click="(emailDialog = false), (email = {})">X</v-btn></v-card-title>
        <v-card-text>
          <v-text-field v-model="email.email" outlined dense label="Email Address"></v-text-field>
          <v-row class="mt-2" justify="center">
            <v-btn @click="saveEmail" :disabled="!email.email" color="primary">Save</v-btn>
          </v-row>
        </v-card-text>
      </v-card></v-dialog>

    <v-dialog v-model="teamModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title><v-icon class="mr-2">groups</v-icon>Assigned Teams</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="saveTeams" :loading="savingTeams"><v-icon>save</v-icon></v-btn>
          <v-btn text @click="teamModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-item v-for="team in allTeams" :key="team.id" :style="{ 'border-left': `5px solid ${team.colour}` }"
              class="my-1">
              <v-list-item-content>
                <v-list-item-title>
                  {{ team.name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-checkbox color="primary" v-model="selectedTeams" :value="team.id"></v-checkbox>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="userModal" width="750px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2">person</v-icon>
            Subscribed Users
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="saveUsers" :loading="savingUsers"><v-icon>save</v-icon></v-btn>
          <v-btn text @click="userModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row justify="end" class="mb-2">
            <v-col cols="12" sm="12" md="8" lg="6">
              <el-input suffix-icon="el-icon-search" v-model="userSearch" clearable placeholder="Search">
              </el-input>
            </v-col>
          </v-row>
          <v-data-table v-model="selectedUsers" show-select id="user-table" dense :items="users" :loading="loadingUsers"
            :search="userSearch" :headers="headers" style="cursor: pointer">
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="productDialog" width="450px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Manage Products</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(productDialog = false), (productItem = {})">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field label="Search" v-model="productSearch" outlined dense clearable></v-text-field>
          <v-progress-linear v-if="loadingProduct" indeterminate color="primary"></v-progress-linear>
          <v-list dense style="max-height: 40vh; overflow-y: auto">
            <v-list-item v-for="product in products.data" :key="product.id">
              <v-list-item-content>
                <v-list-item-title>
                  {{ product.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Type: {{ product.type }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="product.parentProduct">
                  Parent: {{ product.parentProduct.name }} ({{
                    product.parentProduct.type
                  }})
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="product.hsCode">
                  HS Code: {{ product.hsCode.alternateCode }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon color="success" @click="addProductToProfile(product)"
                      :loading="loadingProductId == product.id"><v-icon>add_circle_outline</v-icon></v-btn>
                  </template>
                  <span>Add product to profile</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-if="products.total == 0">
              <v-list-item-content>
                <span style="color: grey">No products found</span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="shippingContractModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-card-title>
          Shipping Contract
          <v-spacer></v-spacer>
          <v-btn text @click="(shippingContractModal = false), (shippingContract = {})">X</v-btn>
        </v-card-title>
        <v-card-text>
          <v-autocomplete :items="shippingLines" label="Shipping Line*" outlined dense item-text="name" item-value="id"
            v-model="shippingContract.shippingLineId">
            <template v-slot:selection="data">
              <v-avatar size="48" class="mr-2" v-if="data.item && data.item.logo">
                <v-img v-if="data.item.logo" contain :src="data.item.logo">
                </v-img>
              </v-avatar>
              <span v-if="data.item">
                {{ data.item.name }}
              </span>
            </template>
            <template v-slot:item="data">
              <v-list-item-avatar>
                <v-img v-if="data.item.logo" contain :src="data.item.logo">
                </v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-text-field v-model="shippingContract.contractNumber" outlined dense clearable
            label="Contract No."></v-text-field>
          <!-- <v-select outlined dense label="Carrier Contract Owner" :items="chargeOptions" item-text="name"
            item-value="value" v-model="shippingContract.contractOwner" style="text-transform: capitalize">
          </v-select> -->
          <v-row justify="center">
            <v-btn color="primary" text :loading="savingContract" @click="saveShippingContract">Save</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="infoStart" max-width="31vw">
      <v-card>
        <v-btn @click="infoStartGuide" icon style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0"><v-icon
            color="primary" size="30">close</v-icon></v-btn>
        <v-card-title class="d-flex justify-center">
          FRIKO Welcome to Company Profiles!
        </v-card-title>
        <v-card-text>
          <span>
            <p>FRIKO</p>
            <p>
              FRIKO <br />• Map out routes for transporters <br />• Add GeoJSON
              data to locations used determine if a vehicle is within a certain
              area <br />• Calculate accurate waiting times at loading points
              <br />• Calculate distances for billing purposes <br />• Pin-point
              locations for the purpose of reporting and analysis <br />• Map
              out farms that aren't found on Google Maps
            </p>
            If you would like learn how to load POI's into the system, click the
            button below to start the tutorial.
          </span>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="startGuide" color="edit" class="mb-3">Start!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn color="grey" class="infoButton" elevation="2" fab fixed bottom right style="text-transform: none"
      @click="infoStartGuide">
      <v-icon>help</v-icon>
    </v-btn>
    <v-dialog v-model="countryDialog" width="400px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            Select {{ countryItemType }} Country
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(countryDialog = false), (searchCountries = null)">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field outlined autofocus dense hide-details v-model="searchCountries" clearable placeholder="Search"
            prepend-inner-icon="search"></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item v-for="country in filterCountries" :key="country.id" @click="setCountry(country)">
              <v-list-item-action>
                <v-avatar size="24" left>
                  <v-img contain v-if="country.iso2"
                    :src="`https://cdn.loglive.io/flags/4x3/${country.iso2.toLowerCase()}.svg`"></v-img>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ country.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="filterCountries.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px">No countries found</span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="temporaryProductModal" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>{{ bulkType == 'Add' ? 'Add Products' : 'Edit Products' }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn icon @click="addProducts()" :loading="saveProducts" color="secondary">
            <v-icon>save</v-icon>
          </v-btn> -->
          <v-btn icon @click="closeTemporaryProductModal()" color="primary">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <!-- Left Side with List Items -->
            <v-col cols="4">
              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>bolt</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title>
                    Genset Required
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="gensetToggleKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Genset')" :color="availableProducts.Genset === false
                      ? 'primary'
                      : 'grey'
                      ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Genset')" :color="availableProducts.Genset === true
                      ? 'tertiary'
                      : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>

              <!-- New List Items -->

              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>air</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title>
                    Otflow Required
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="otflowKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Otflow')" :color="availableProducts.Otflow === false
                      ? 'primary'
                      : 'grey'
                      ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Otflow')" :color="availableProducts.Otflow === true
                      ? 'tertiary'
                      : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>sanitizer</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title>
                    Zoono Required
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="zoonoKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Zoono')" :color="availableProducts.Zoono === false
                      ? 'primary'
                      : 'grey'
                      ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Zoono')" :color="availableProducts.Zoono === true
                      ? 'tertiary'
                      : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>power</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title>
                    Void Plugs Required                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="voidPlugKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Void_Plugs')" :color="availableProducts.Void_Plugs === false
                      ? 'primary'
                      : 'grey'
                      ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Void_Plugs')" :color="availableProducts.Void_Plugs === true
                      ? 'tertiary'
                      : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>



              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>watch_later</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title>
                    Early Arrival Required
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="earlyArrivalKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Early_Arrival')" :color="availableProducts.Early_Arrival === false
                      ? 'primary'
                      : 'grey'
                      ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Early_Arrival')" :color="availableProducts.Early_Arrival === true
                      ? 'tertiary'
                      : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>

              <v-list-item dense v-if="availableProducts.Early_Arrival === true">
                <v-select label="LAR description(Early Arrival)" placeholder="Select a LAR description" persistent-placeholder
                  hide-details outlined dense class="py-1 mt-2 mb-2" :items="[
                    'Pending',
                    'Approved',
                    'Approved (No Charge)',
                    'Rejected',
                    'Approved (Not Used)',
                    'Not Applicable'
                  ]" v-model="earlyDescription"></v-select>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>watch_later</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title>
                    Late Arrival Required
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="lateArrivalKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Late_Arrival')" :color="availableProducts.Late_Arrival === false
                      ? 'primary'
                      : 'grey'
                      ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Late_Arrival')" :color="availableProducts.Late_Arrival === true
                      ? 'tertiary'
                      : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>

              <v-list-item dense v-if="availableProducts.Late_Arrival === true">
                <v-select label="LAR description(Late Arrival)" placeholder="Select a LAR description" persistent-placeholder
                  hide-details outlined dense class="py-1 mt-2 mb-2" :items="[
                    'Pending',
                    'Approved',
                    'Approved (No Charge)',
                    'Rejected',
                    'Approved (Not Used)',
                    'Not Applicable'
                  ]" v-model="lateDescription"></v-select>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>directions_boat</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title>
                    Fumigation Required
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="portMonitoringKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Fumigation')" :color="availableProducts.Fumigation === false
                      ? 'primary'
                      : 'grey'
                      ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Fumigation')" :color="availableProducts.Fumigation === true
                      ? 'tertiary'
                      : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>emergency</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title>Steri Insurance Required </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="portMonitoringKey">
                    <v-btn x-small @click="toggleProducts('NO', 'SteriInsurance')" :color="availableProducts.SteriInsurance === false ? 'primary' : 'grey'
                      ">
                      NO
                    </v-btn>

                    <v-btn x-small @click="toggleProducts('YES', 'SteriInsurance')" :color="availableProducts.SteriInsurance === true ? 'tertiary' : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                </v-list-item-action>
              </v-list-item>
            </v-col>

            <!-- Right Side with Text Fields -->
            <v-col cols="4">

              <v-card outlined flat>
                <v-row no-gutters>
                  <v-col cols="11"> <!-- Adjust "cols" as needed -->
                    <v-list-item dense>
                      <v-select label="Monitoring Service" placeholder="Select a Monitoring Service"
                        persistent-placeholder hide-details outlined dense item-text="text" item-value="value"
                        class="py-1 mt-2 mb-2" :items="[
                          {
                            text:'iCheck',
                            value: 'iCheck',
                          },
                          {
                            text: 'TempCheck',
                            value: 'TempCheck',
                          },
                          {
                            text: 'TempCheckPLUS',
                            value: 'TempCheckPLUS',
                          },
                          // {
                          //   text: 'TempCheckLite',
                          //   value: 'TempCheckLite',
                          // },
                          {
                            text: 'Port Monitoring',
                            value: 'Port Monitoring',
                          },
                          {
                            text: 'N/A',
                            value: ''
                          }
                        ]" v-model="availableProducts.monitoringService"></v-select>
                    </v-list-item>
                  </v-col>
                  <v-col cols="1"> <!-- Adjust "cols" as needed -->
                    <v-btn icon color="primary" @click="addServices()" class="mt-3">
                      <v-icon>add_circle_outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <!-- <v-card-title>
             TelemPlus
             <v-spacer></v-spacer>
             <v-btn icon color="primary" class="ml-3"
                                                       @click="addServices()"><v-icon>add_circle_outline</v-icon></v-btn>
           </v-card-title> -->
                <v-card-text v-if="uniqueProducts.includes('TelemPlus')">
                  <v-list dense class="ma-0 pa-0">
                    <v-list-item
                      v-for="(product, index) in availableDevices.filter(option => option.count > 0 && option.product == 'TelemPlus')"
                      :key="index">
                      <v-text-field type="number" v-model="product.count" :min="0" class="small-text-field"
                        style="text-align: center;" :label="product.variation + ' ' + 'Template'"
                        @input="updateProductCount(product)" />
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="4">
              <v-card outlined flat
                v-for="uniqueProduct in uniqueProducts.filter(p => ['Dunnage Bags', 'Blast Freezing'].includes(p))"
                :key="uniqueProduct">
                <v-card-title>
                  {{ uniqueProduct }}
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                  <v-list dense class="ma-0 pa-0">
                    <v-list-item v-for="(product, index) in availableDevices.filter(d => d.product === uniqueProduct)"
                      :key="index">
                      <v-text-field type="number" v-model="product.count" :min="0" class="small-text-field"
                        style="text-align: center;" @input="updateProductCount(product)"></v-text-field>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="regimeCodeModal" width="500px" :fullscreen="$vuetify.breakpoint.mobile" peristent>
      <v-card :loading="regimeCodeLoading">
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Manage Regime Code </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="regimeCodeModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field placeholder="Search" autofocus prpend-inner-icon="search" v-model="regimeCodeSearch" outlined
            dense clearable></v-text-field>
          <v-virtual-scroll v-if="filterRegimeCodes.length > 0" class="my-0 py-0" :bench="0" :items="filterRegimeCodes"
            height="500" item-height="85">
            <template v-slot:default="{ item }">
              <v-list-item @click="setRegimeCode(item)" style="border-bottom: 0.5px solid grey">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.code }}
                    <v-chip class="ml-2" outlined small v-if="item.steri">
                      <v-icon left color="blue" small>ac_unit</v-icon>Steri Code
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-icon class="mr-2" color="grey" small>thermostat</v-icon>Set Point: {{ item.setPointTemp }}°C
                    <v-icon class="mx-2" color="red" small>thermostat</v-icon>Max: {{ item.maxProductTemp }}°C
                    <v-icon class="mx-2" color="blue" small>thermostat</v-icon>Min {{ item.minProductTemp }}°C
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ item.recipeDescription }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-virtual-scroll>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item v-if="filterRegimeCodes.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px">No regime codes found</span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="caProductModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Set Controlled Atmosphere</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="caProductModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-autocomplete v-model="item.caProduct" :items="caProducts" @change="caProductModal = false" outlined dense
            placeholder="Select a CA Product" clearable item-text="description" item-value="name"></v-autocomplete>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="haulierModal" width="900px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
      <v-card>
        <v-card-title>
          Manage Haulier
          <v-spacer></v-spacer>
          <v-btn text @click="haulierModal = false">X</v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-list style="max-height: 50vh; overflow-y: auto" dense>
                <v-subheader style="font-size: 16px">Transport Type</v-subheader>
                <v-divider></v-divider>
                <v-list-item v-for="haulier in hauliers" :key="haulier.index" @click="setHaulier(haulier)">
                  <v-list-item-action>
                    <v-avatar color="white" size="36">
                      <v-img v-if="haulier.logo" :src="haulier.logo" contain></v-img>
                      <v-icon v-else color="black">{{ haulier.icon }}</v-icon>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ haulier.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      haulier.subtitle
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon color="success" v-if="item[haulier.flag]">check_circle_outline</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="6">
              <v-list style="max-height: 50vh; overflow-y: auto" dense>
                <v-subheader style="font-size: 16px">Transport Coordinator</v-subheader>
                <v-divider></v-divider>
              </v-list>
              <v-select :items="selectableParties" :disabled="!item.isPrivateHaulage" :placeholder="item.isPrivateHaulage
                ? 'Select Transporter'
                : 'Not Applicable'
                " item-text="name" item-value="id" rounded style="background-color: #2f2f2f; margin: 1vh 1vw 0.5vh 1vw"
                hide-details outlined return-object v-model="item.transportCoordinator" @change="setTransporter" clearable
                dense>
                <template v-slot:selection="data">
                  <v-row align="center" v-if="item.isPrivateHaulage">
                    <v-avatar size="42" class="mr-2" v-if="data.item">
                      <v-img v-if="data.item.icon" contain :src="data.item.icon"></v-img>
                    </v-avatar>
                    <span v-if="data.item">
                      {{ data.item.name }}
                    </span>
                  </v-row>
                </template>
                <template v-slot:item="data">
                  <v-list-item-avatar>
                    <v-img contain v-if="data.item.logo" :src="data.item.logo"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
              <v-list-item dense v-if="item.isPrivateHaulage && item.movementType == 'EXPORT'" class="mt-2">
                <v-list-item-action>
                  <v-icon color="secondary">article</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> Pre-Advise </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action :key="preadviseKey">
                  <v-switch color="success" v-model="item.preAdviseRequired"></v-switch>
                </v-list-item-action>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Edit container item details -->
    <v-dialog v-model="containerItemModal" width="95vw" :fullscreen="$vuetify.breakpoint.mobile" persistent>
      <v-card>
        <v-toolbar flat color="transaprent">
          <v-toolbar-title v-if="containerItem.type == 'CONTAINER'">
            Manage Container Item
          </v-toolbar-title>
          <v-toolbar-title v-else> Manage Breakbulk Item </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="saveContainerItem" :loading="savingContainerItem"
            icon><v-icon>save</v-icon></v-btn>
          <v-btn text @click="exitContainerItemModal()">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="4" class="pt-1">
              <v-row>
                <!-- <v-col cols="6">
                  <v-text-field
                    label="Customer Ref"
                    v-model="containerItem.customerContainerRef"
                    outlined
                    dense
                    hide-details
                    clearable
                  ></v-text-field>
                </v-col> -->
                <v-col cols="6">
                  <v-select outlined v-if="item.movementType == 'EXPORT'" label="VGM Method"
                    :items="['Method 1', 'Method 2']" dense hide-details clearable
                    v-model="containerItem.verificationMethod"></v-select>
                </v-col>
                <!-- <v-col v-if="containerItem.verificationMethod == 'Method 1'" cols="12" sm="6">
                                    <v-select label="Weighbridge Contract Owner" placeholder="Select a Party"
                                        persistent-placeholder hide-details outlined dense class="py-2"
                                        :items="['Shipper', 'Forwarder']"
                                        v-model="containerItem.verificationMethodOwner"></v-select>
                                </v-col>
                                <v-col v-if="containerItem.verificationMethod == 'Method 1'" cols="12" sm="6">
                                    <v-autocomplete v-model="containerItem.weighBridgeId" prepend-inner-icon="location_on"
                                        placeholder="Weighbridge Location" persistent-placeholder hide-details
                                        :items="weighBridges" dense outlined clearable :loading="loadingWeighBridges"
                                        item-text="name" item-value="id">
                                    </v-autocomplete>
                                </v-col> -->
              </v-row>
              <v-list dense>
                <!-- <v-divider></v-divider> -->
                <!-- <v-subheader style="font-size: 16px">Containers
                                </v-subheader>
                                <v-divider></v-divider> -->
                <v-list-item dense v-if="containerItem.type == 'CONTAINER'">
                  <v-list-item-action>
                    <el-input-number class="my-0 py-0" :min="1" :disabled="item.serviceType == 'LCL'"
                      :value="containerItem.quantity" size="mini" v-model="containerItem.quantity"></el-input-number>
                  </v-list-item-action>
                  <v-autocomplete class="my-0 py-0" hide-details outlined dense clearable label="Container Type"
                    v-model="containerItem.containerTypeCode" @change="containerTypeKey++" :items="containerTypes"
                    item-text="shortDescription" item-value="typeCode">
                  </v-autocomplete>
                </v-list-item>
              </v-list>

              <v-list dense subheader v-if="containerItem.bookingCargoItems">
                <v-divider></v-divider>
                <v-subheader style="font-size: 16px">Cargo/Products
                  <v-btn icon color="primary" @click="addContainerCargoDetails">
                    <v-icon>add_circle_outline</v-icon></v-btn></v-subheader>
                <v-divider></v-divider>

                <v-list-item v-if="!containerItem.bookingCargoItems ||
                  (containerItem.bookingCargoItems &&
                    containerItem.bookingCargoItems.length == 0)
                  ">
                  <v-list-item-content class="text-center">
                    <span style="color: grey">No products listed</span>
                  </v-list-item-content></v-list-item>
                <v-list-item v-for="cargo in containerItem.bookingCargoItems" :key="cargo.index">
                  <v-list-item-content>
                    <v-list-item-title v-if="cargo.product">
                      {{ cargo.product.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-wrap">
                      {{ cargo.hsCode }}
                      <span v-if="cargo.pallets">
                        - {{ cargo.pallets }} PALLETS</span>
                      <span v-if="cargo.quantity">
                        - {{ cargo.quantity }} {{ cargo.quantityType }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="cargo.grossWeight || cargo.nettWeight || cargo.volume
                      " class="text-wrap">
                      NETT {{ cargo.nettWeight }} KG - GROSS
                      {{ cargo.grossWeight }} KG - {{ cargo.volume }} CBM
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-row justify="center">
                      <v-btn icon small @click="editCargoItem(cargo)"><v-icon small>edit</v-icon></v-btn>
                      <v-btn icon small @click="deleteCargoItem(cargo)" color="red"><v-icon small>delete</v-icon></v-btn>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item @click="getRegimeCodes(true)" :disabled="!containerItem.bookingCargoItems ||
                  (containerItem.bookingCargoItems &&
                    containerItem.bookingCargoItems.length == 0)
                  ">
                  <v-list-item-action>
                    <v-icon :color="!containerItem.regimeId ? 'secondary' : 'success'">tag</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-if="containerItem.regime">
                      {{ containerItem.regime.code }}
                      <v-chip class="ml-2" outlined small v-if="containerItem.regime && containerItem.regime.steri">
                        <v-icon left color="blue" small>ac_unit</v-icon>Steri
                        Code
                      </v-chip>
                    </v-list-item-title>
                    <v-list-item-title v-else>-</v-list-item-title>
                    <v-list-item-subtitle v-if="containerItem.regime">
                      <v-icon class="mr-2" color="grey" small>thermostat</v-icon>Set Point: {{
                        containerItem.regime.setPointTemp }}°C
                      <v-icon class="mx-2" color="red" small>thermostat</v-icon>Max: {{
                        containerItem.regime.maxProductTemp }}°C
                      <v-icon class="mx-2" color="blue" small>thermostat</v-icon>Min {{
                        containerItem.regime.minProductTemp }}°C
                    </v-list-item-subtitle>
                    <v-list-item-subtitle> Regime Code </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-textarea v-model="containerItem.comment" label="Comments" outlined hide-details rows="3"></v-textarea>
            </v-col>

            <v-col cols="12" sm="12" md="4" :key="containerTypeKey">
              <v-list dense subheader>
                <v-divider></v-divider>
                <v-subheader style="font-size: 16px">
                  Additional Products & Services<v-spacer></v-spacer> <v-btn icon color="primary"
                    @click="getTemporaryProducts()">
                    <v-icon>add_circle_outline</v-icon></v-btn></v-subheader>
                <v-divider></v-divider>
                <v-list dense class="mt-0 pt-0">
                  <v-list-item v-if="!hasProductsCount && !hasDevicesWithCount">
                    <v-list-item-content class="text-center">
                      <span style="color: grey">No products & services listed</span>
                    </v-list-item-content>
                  </v-list-item>

                  <template v-if="hasProductsCount">
                    <v-list-item v-for="(item) in hasProducts" :key="generateKey(item)">
                      <v-list-item-content>
                        <v-row no-gutters>
                          <v-col cols="10" class="py-4">
                            {{ item.product }}
                          </v-col>
                          <v-col cols="2">

                            <v-list-item-action class="py-0">
                              <v-btn-toggle :key="gensetToggleKey">
                                <v-btn x-small @click="toggleProducts('NO', item.product)" :color="item.selected === false
                                  ? 'primary'
                                  : 'grey'
                                  ">
                                  NO
                                </v-btn>
                                <v-btn x-small @click="toggleProducts('YES', item.product)" :color="item.selected === true
                                  ? 'tertiary'
                                  : 'grey'
                                  ">
                                  YES
                                </v-btn>
                              </v-btn-toggle>
                            </v-list-item-action>
                          </v-col>
                        </v-row>

                      </v-list-item-content>
                      <v-list-item-action></v-list-item-action>
                    </v-list-item>
                  </template>

                  <template v-if="hasDevicesWithCount">
                    <v-list-item v-for="(item) in hasDevices" :key="generateKey(item)">
                      <v-list-item-content>
                        <v-row no-gutters>
                          <v-col cols="9" class="py-4">
                            {{ item.product }} {{ item.variation !== "Standard" ? '(' + item.variation + ')' : '' }}

                          </v-col>
                          <v-col cols="3">

                            <v-list-item-action class="py-0">
                              <el-input-number :min="0" :value="item.count" size="mini" :max=20 v-model="item.count"
                                @input="checkItemCount(item)"></el-input-number>
                            </v-list-item-action>
                          </v-col>
                        </v-row>
                      </v-list-item-content>

                    </v-list-item>
                  </template>
                  <template v-if="availableProducts.monitoringService">
                    <v-list-item>
                      <v-list-item-content>
                        <v-row no-gutters>
                          <v-col cols="9" class="py-4">
                            {{ `Monitoring Service - ` + availableProducts.monitoringService }}
                          </v-col>
                          <v-col cols="3">
                            <!-- <v-list-item-action class="py-0">
                              <el-input-number
                                :min="0"
                                :value="bulkUpdateItem.monitoringServiceCount"
                                size="mini"
                                :max="20"
                                v-model="bulkUpdateItem.monitoringServiceCount"
                              ></el-input-number>
                            </v-list-item-action> -->
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-list>
              <!-- <v-list dense class="mt-0 pt-0">
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>bolt</v-icon>
                    </v-list-item-action>
                    <v-list-item-content class="text-wrap">
                      <v-list-item-title> Genset Required </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch
                        v-model="containerItem.gensetRequired"
                        color="success"
                      ></v-switch>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item style="height: 35px">
                    <v-list-item-action>
                      <v-icon>thermostat</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Add Temperature Device
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch
                        v-model="containerItem.sensorDevice"
                        color="success"
                      ></v-switch>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item
                    style="height: 35px"
                    v-if="containerItem.sensorDevice"
                  >
                    <v-select
                      class="mr-1"
                      label="Monitoring Service"
                      persistent-placeholder
                      clearable
                      flat
                      dense
                      outlined
                      hide-details
                      :items="['TempCheck', 'TempCheckPLUS', 'Port Monitoring']"
                      v-model="containerItem.sensorService"
                    ></v-select>
                    <v-select
                      class="ml-1"
                      label="Temperature Device Type"
                      persistent-placeholder
                      clearable
                      flat
                      hide-details
                      dense
                      outlined
                      :items="[
                        'RF TEMPTALE',
                        'USB TEMPTALE',
                        'GEO EAGLE TEMPTALE',
                        'MOST TEMPTALE',
                        '2G GEO TEMPTALE',
                        '3G GEO TEMPTALE',
                        '4G GEO TEMPTALE',
                        '2G TIVE',
                        '5G TIVE',
                      ]"
                      v-model="containerItem.sensorDeviceType"
                    ></v-select>
                  </v-list-item>
                </v-list> -->
              <v-list v-if="displayReeferSettings && containerItem.type == 'CONTAINER'">
                <v-divider></v-divider>
                <v-subheader style="font-size: 16px">
                  <v-icon class="mr-2">ac_unit</v-icon> Reefer
                  Settings</v-subheader>
                <v-divider></v-divider>
                <v-row class="mt-2">
                  <v-col cols="12" sm="4" class="my-0 py-0">
                    <v-select label="Vents" outlined dense hide-details class="pb-1" v-model="containerItem.vents"
                      :items="ventSettings"></v-select>
                  </v-col>
                  <v-col cols="12" sm="4" class="my-0 py-0">
                    <v-text-field prepend-inner-icon="thermostat" label="Set Temp" hide-details
                      v-model="containerItem.setPointTemp" outlined dense class="pb-1" suffix="°C"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" class="my-0 py-0">
                    <v-text-field label="Humidity" v-model="containerItem.humidity" outlined hide-details dense
                      type="number" suffix="%"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field label="Oxygen" v-model="containerItem.oxygen" outlined dense
                      v-if="containerItem.caProduct" hide-details class="pb-1" type="number" suffix="%"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field label="Carbon Dioxide" v-model="containerItem.carbonDioxide"
                      v-if="containerItem.caProduct" hide-details class="pb-1" outlined type="number" dense
                      suffix="%"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <!-- <v-text-field
                        label="Nitrogen"
                        v-model="containerItem.nitrogen"
                        outlined
                        v-if="containerItem.caProduct"
                        hide-details
                        dense
                        type="number"
                        suffix="%"
                      ></v-text-field> -->
                  </v-col>
                </v-row>
              </v-list>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="pt-1">
              <v-list dense>
                <v-divider></v-divider>
                <v-subheader style="font-size: 16px">Pre-Carriage Legs / Loading Points
                </v-subheader>
                <v-divider></v-divider>
                <v-divider></v-divider>
                <v-list-item v-for="(milestone, index) in availableMilestones.filter(
                      (x) => x.type == item.movementType
                    )" :key="index" @click="addMilestone(containerItem, milestone.value, false)">
                  <v-list-item-action class="mr-0 pr-0">
                    <v-avatar size="10" v-if="containerItem[milestone.value]" :color="containerItem[milestone.value].isActual
                      ? 'success'
                      : 'secondary'
                      "></v-avatar>
                    <v-icon v-else color="grey" small>warning</v-icon>
                  </v-list-item-action>
                  <v-list-item-content v-if="containerItem[milestone.value]">
                    <v-list-item-title style="font-size: 14px">{{
                      containerItem[milestone.value].description
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content v-else>
                    <v-list-item-subtitle style="font-size: 14px" v-if="milestone">{{
                      milestone.name
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Edit Cargo -->
    <v-dialog v-model="cargoModal" persistent width="600px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Cargo Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="secondary" :loading="savingCargoItem" :disabled="!cargoItem.product || !cargoItem.hsCode || !cargoItem.description
            " @click="saveCargoItem"><v-icon>save</v-icon></v-btn>
          <v-btn text @click="(cargoItem = {}), (cargoModal = false)">
            X
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-select hide-details class="my-2" label="Type" :items="['Product Group', 'Product', 'Variety']"
                v-model="filterProductType" outlined dense></v-select>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-autocomplete class="my-2" hide-details ref="countrySelect" autofocus hide-no-data hide-selected
                label="Product" dense outlined :items="filterProducts" clearable item-text="name" item-value="id"
                :menu-props="{ closeOnContentClick: true }" @change="setCargoItemDetails" v-model="cargoItem.product"
                return-object :loading="loadingProduct">
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ data.item.type }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="data.item.parentProduct" style="font-size: 12px">
                      Parent: {{ data.item.parentProduct.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <v-textarea hide-details class="my-2" rows="3" label="Description" v-model="cargoItem.description"
                outlined></v-textarea>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field hide-details class="my-2" label="HS Code" v-model="cargoItem.hsCode" outlined
                dense></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field hide-details class="my-2" label="No Pallets" type="number" v-model="cargoItem.pallets"
                outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field hide-details class="my-2" label="Nett Weight" suffix="Kg" v-model="cargoItem.nettWeight"
                outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field hide-details class="my-2" label="Gross Weight" suffix="Kg" v-model="cargoItem.grossWeight"
                outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field hide-details class="my-2" label="Cubic measure" suffix="CBM" v-model="cargoItem.volume"
                outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field hide-details class="my-2" label="Batch Number" v-model="cargoItem.batchNumber" outlined
                dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-select hide-details class="my-2" label="Quantity Type" clearable v-model="selectedQuantityType" outlined
                dense :items="productQuantityTypes" @change="selectQuantityType()" return-object></v-select>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field hide-details class="my-2" :label="`Quantity`" v-model="cargoItem.quantity" outlined
                dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-switch label="Industrial Use" dense hide-details v-model="cargoItem.industrial"></v-switch>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-switch label="Organic" dense hide-details v-model="cargoItem.organic"></v-switch>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-switch label="Hazardous" dense v-model="cargoItem.hazardous"></v-switch>
            </v-col>
          </v-row>
          <v-list dense subheader v-if="cargoItem.hazardous">
            <v-divider></v-divider>
            <v-subheader style="font-size: 16px">Hazardous Details</v-subheader>
            <v-divider></v-divider>
            <v-row class="mt-2">
              <v-col cols="12" sm="6" class="my-0 py-0" v-if="cargoItem.hazardous">
                <v-text-field hide-details class="my-2" label="UN Code" v-model="cargoItem.unCode" outlined
                  dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="my-0 py-0" v-if="cargoItem.hazardous">
                <v-text-field hide-details class="my-2" label="UN Packing Group" v-model="cargoItem.unCodePackingGroup"
                  outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="my-0 py-0" v-if="cargoItem.hazardous">
                <v-text-field hide-details class="my-2" :label="`Hazard Class`" v-model="cargoItem.class" outlined
                  dense></v-text-field>
              </v-col>
            </v-row>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addServiceModal" max-width="400px" persistent>
      <v-card>
        <v-card-title>TelemPlus Products <v-spacer></v-spacer>
          <!-- <v-btn icon @click="addProducts" color="primary"><v-icon>add_circle_outline</v-icon></v-btn> -->
          <v-btn icon @click="addServiceModal = false" color="secondary"><v-icon>close</v-icon></v-btn></v-card-title>
        <v-list>
          <v-list-item v-for="(product, index) in availableDevices.filter((x) => x.product == 'TelemPlus')" :key="index">
            <v-checkbox :label="product.variation" v-model="product.checked" @change="toggleCheckbox(product)" />
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addMilestoneModal" width="500px">
      <ManageContainerMilestone :item="milestoneItem" :key="milestoneKey" :bookingType="item.movementType"
        :hideDate="true" @close="addMilestoneModal = false" @unsavedItem="setMilestonePoint" />
    </v-dialog>
  </div>
</template>
<script>
import dateFormat from "dateformat";
import draggable from "vuedraggable";
import Driver from "driver.js";
import "driver.js/dist/driver.min.css";
import ManageContainerMilestone from "../../Bookings/Dialogs/ManageContainerMilestone.vue";
// import steps from "../../Customer/Steps/Settings";
export default {
  props: ["item", "countries", "currencies", "productKey", "contacts", "profile"],
  components: {
    draggable,
    ManageContainerMilestone,
  },
  data: () => ({
    steps: null,
    savingUCR: false,
    addMilestoneModal: false,
    availableMilestones: [
      { value: "EMPTY_PICKUP", type: "EXPORT", name: "Empty Pickup" },
      { value: "LOADING_POINT_1", type: "EXPORT", name: "Loading Point 1" },
      { value: "LOADING_POINT_2", type: "EXPORT", name: "Loading Point 2" },
      { value: "WEIGH_BRIDGE", type: "EXPORT", name: "Weigh Bridge" },
      { value: "FULL_DROP_OFF", type: "EXPORT", name: "Port Holding" },

      { value: "FULL_PICKUP", type: "LOCAL", name: "Full Pickup" },
      { value: "DELIVERY_POINT_1", type: "LOCAL", name: "Delivery Point 1" },
      { value: "DELIVERY_POINT_2", type: "LOCAL", name: "Delivery Point 2" },
      { value: "DELIVERY_POINT_3", type: "LOCAL", name: "Delivery Point 3" },
      { value: "EMPTY_DROP_OFF", type: "LOCAL", name: "Empty Drop Off" }, 

      { value: "FULL_PICKUP", type: "IMPORT", name: "Full Pickup" },
      { value: "DELIVERY_POINT_1", type: "IMPORT", name: "Delivery Point 1" },
      { value: "DELIVERY_POINT_2", type: "IMPORT", name: "Delivery Point 2" },
      { value: "DELIVERY_POINT_3", type: "IMPORT", name: "Delivery Point 3" },
      { value: "EMPTY_DROP_OFF", type: "IMPORT", name: "Empty Drop Off" },
    ],
    temporaryProductModal: false,
    bookingType: 0,
    caProductModal: false,
    caProducts: [
      {
        shippingLine: "HPL",
        name: "EXTRAFRESH PLUS",
        description: "EXTRAFRESH PLUS",
        oxygen: 4,
        carbonDioxide: 6,
      },
      {
        shippingLine: "MSK",
        name: "STARCARE",
        description: "STARCARE",
        oxygen: 4,
        carbonDioxide: 6,
      },
      {
        shippingLine: "MSK",
        name: "STARCOOL",
        description: "STARCOOL",
        oxygen: 4,
        carbonDioxide: 6,
      },
      {
        shippingLine: "MSK",
        name: "LIVENTUS",
        description: "LIVENTUS",
        oxygen: 8,
        carbonDioxide: 15,
      },
      {
        shippingLine: "ONE",
        name: "MAXTEND",
        description: "MAXTEND",
        oxygen: 5,
        carbonDioxide: 10,
      },
      {
        shippingLine: "MSC",
        name: "XTENDFR A",
        description: "XTENDFRESH AVOCADOS",
        oxygen: 4,
        carbonDioxide: 6,
      },
      {
        shippingLine: "HPL",
        name: "EXTRAFR B",
        description: "EXTRAFR BERRIES",
        oxygen: 4,
        carbonDioxide: 12,
      },
    ],
    selectedRadio: "sea",
    driver: null,
    infoStart: false,
    allTeams: [],
    carrierContracts: [],
    carriers: [],
    cargoItem: {},
    saveProducts: false,
    cargoModal: false,
    temporaryProductsView: [
      { icon: "bolt", color: "#D6D46D", name: "Genset" },
      { icon: "watch_later", color: "#BB2525", name: "Late Arrival" },
      { icon: "watch_later", color: "orange", name: "Early Arrival" },
      { icon: "router", color: "#18e00d", name: "TelemPlus" },
      { icon: "shopping_bag", color: "#F1DBBF", name: "Dunnage Bags" },
      { icon: "air", color: "#6A9C89", name: "Otflow" },
      { icon: "sanitizer", color: "#5b93c7", name: "Zoono" },
      { icon: "cleaning_services", color: "#BB2525", name: "Fumigation" },
      { icon: "power", color: "#D6D46D", name: "Void Plugs" },
      { icon: "ac_unit", color: "#13a7d4", name: "Blast Freezing" },
      { icon: "pivot_table_chart", color: "#508D69", name: "Latches" },
      { icon: "settings_input_component", color: "#EA906C", name: "Ratches" },
      {icon:'filter_alt',color:"#EE12CA",name:"Ethylene Filters"},
      { icon: "video_stable", color: "#5F6F52", name: 'Stabling bars' },
      { icon: "note", color: "#C5E898", name: "Kraft paper" },
      { icon: "emergency", color: "#FFBFBF", name: 'SteriInsurance' },

      { icon: "backpack", color: "#DCBFFF", name: 'Absorb Bags' },      // { icon: "monitor_heart", color: "#F1DBBF", name: "Port Monitoring" },
    ],
    chargeOptions: [
      { friendlyName: '', name: "Buyer", value: "buyer" },
      { friendlyName: '', name: "Forwarder", value: "forwarder" },
      { friendlyName: '', name: "On behalf Forwarder", value: "onBehalfForwarder" },
      { friendlyName: '', name: "Shipper", value: "shipper" },
      { friendlyName: '', name: "On behalf Shipper", value: "onBehalfShipper" },
      { friendlyName: '', name: "Consignee", value: "consignee" },
      { friendlyName: '', name: "1st Notify Party", value: "firstNotify" },
      { friendlyName: '', name: "2nd Notify Party", value: "secondNotify" },
      { friendlyName: '', name: "Customer", value: "customer" },
      { friendlyName: '', name: "Contract Party", value: "contractParty" },
    ],
    contractKey: 0,
    containerItem: {},
    containerItemModal: false,
    containerTypeKey: 300,
    containerTypes: [],
    countryDialog: false,
    countryItemType: "Origin",

    destinationPointLoading: false,
    destinationPointSearch: null,
    destinationPoints: [],
    destinationPointTimeout: null,
    destinationPort: null,
    destinationFilterPoint: "port",

    documentationPODLoading: false,
    documentationPODModal: false,
    documentationPODSearch: null,
    documentationPODTimeout: null,

    email: {},
    emailDialog: false,
    emailKey: 0,

    entryPointLoading: false,
    entryPointSearch: null,
    entryPoints: [],
    entryPointTimeout: null,
    entryPort: null,
    entryFilterPoint: "port",
    expandedProfile: null,
    fdModal: false,
    filterComments: "All",
    filterProductType: "Product",
    freightContractModal: false,
    freightItem: {},
    haulierModal: false,
    haulierKey: 100,
    headers: [
      {
        text: "Name",
        value: "user.firstname",
        align: "center",
      },
      {
        text: "Surname",
        value: "user.surname",
        align: "center",
      },
      {
        text: "Email",
        value: "user.emailAddress",
        align: "center",
      },
      {
        text: "Position",
        value: "position",
        align: "center",
      },
      {
        text: "Office",
        value: "organisationAddress.alias",
        align: "center",
      },
    ],
    incoTerms: [],
    incoTermDialog: false,
    incoTerm: {},
    importExport: 0,
    milestoneKey: 400,
    milestoneItem: {},
    originInvoiceTo: null,
    portSearch: null,
    portSearchQuery: undefined,
    portSearchTimeout: null,
    loadingPortSearch: false,
    portResults: [],
    freightInvoiceTo: null,
    destinationInvoiceTo: null,
    bulkType: null,
    internalComment: true,

    loadingComments: false,
    loadingCountry: false,
    loadingProduct: false,
    loadingProductId: null,
    loadingRegimeCode: false,
    loadingUsers: false,

    locationKey: 0,
    paymentTypes: [
      // { key: "Additional", value: "Additional Charges" },
      { key: "OceanFreight", value: "Basic Freight" },
      // { key: "DestinationHaulage", value: "Destination Haulage Charges" },
      { key: "DestinationTerminalHandling", value: "Destination Port Charges" },
      // { key: "OriginTerminalHandling", value: "Origin Haulage Charges" },
      { key: "OriginHaulage", value: "Origin Port Charges" },
    ],
    paymentTerms: [
      { key: "Prepaid", value: "Pre-paid" },
      { key: "Collect", value: "Collect" },
    ],
    points: [
      { name: "Airport", value: "airport" },
      { name: "Port", value: "port" },
      { name: "Rail", value: "rail" },
    ],
    ports: [],
    paymentItem: {},
    paymentModal: false,
    portModal: false,
    productDialog: false,
    productItem: {},
    productSearch: null,
    productSearchTimer: null,
    products: {
      data: [],

      total: 0,
    },
    selectedTransportModes: ['Port'],
    transportModes: ['Rail', 'Road', 'Port', 'Airport'],
    preadviseKey: 200,
    savingCarrierContract: false,
    selectedContract: null,
    savingIncoTerm: false,
    savingContract: false,
    users: [],
    userTimeout: null,
    text: "",
    items: [],
    regimeCodes: [],
    regimeCodeLoading: false,
    regimeCodeModal: false,
    regimeCodeSearch: null,
    savingCargoItem: false,
    savingContainerItem: false,
    selectableParties: [],
    shippingContractModal: false,
    shippingContract: {},
    shippingLines: [],
    subscriberModal: false,
    subscriptionItem: {},

    // Teams
    teamModal: false,
    savingTeams: false,
    selectedTeams: [],
    searchCountries: null,
    // Users
    savingUsers: false,
    userModal: false,
    userSearch: null,
    selectedUsers: [],
    ventSettings: [],
    selectedQuantityType: { value: '', text: '' },
    productQuantityTypes: [
      { value: 'BG', text: 'Bag' },
      { value: 'TT', text: 'Bag, tote' },
      { value: 'BA', text: 'Barrel' },
      { value: "BL", text: "Bale" },
      { value: 'BI', text: 'Bin' },
      { value: 'BX', text: 'Box' },
      { value: 'VL', text: 'Bulk, liquid' },
      { value: 'VR', text: 'Bulk, solid' },
      { value: 'BH', text: 'Bunch' },
      { value: 'BE', text: 'Bundle' },
      { value: 'CT', text: 'Carton' },
      { value: 'CS', text: 'Case' },
      { value: 'CN', text: 'Container' },
      { value: 'CR', text: 'Crate' },
      { value: 'DR', text: 'Drum' },
      { value: 'FB', text: 'Flexibag' },
      { value: 'JT', text: 'Jutebag' },
      { value: 'LU', text: 'Lug' },
      { value: 'PK', text: 'Package' },
      { value: 'PA', text: 'Packet' },
      { value: 'PX', text: 'Pallet' },
      { value: 'PT', text: 'Pot' },
      { value: 'PO', text: 'Pouch' },
      { value: 'RO', text: 'Roll' },
      { value: 'SH', text: 'Sachet' },
      { value: 'SA', text: 'Sack' },
      { value: 'SI', text: 'Skid' },
      { value: 'PU', text: 'Tray' },
      { value: 'VI', text: 'Vial' }
    ],
    portMonitoringKey: 0,
    lateArrivalKey: 0,
    earlyArrivalKey:0,
    blastFreezingKey: 0,
    voidPlugKey: 0,
    otflowKey: 0,
    zoonoKey: 0,
    industrialKey: 0,
    gensetToggleKey: 0,

    availableDevices: [

      { product: "TelemPlus", variation: "2G GEO", count: 0, monitoringService: '', checked: false },
      {
            product: "TelemPlus",
            variation: "4G PROBE",
            count: 0,
            monitoringService: "",
            checked: false,
          },
          {
            product: "TelemPlus",
            variation: "STD PROBE",
            count: 0,
            monitoringService: "",
            checked: false,
          },
      // { product: "TelemPlus", variation: "3G GEO", count: 0, monitoringService: '', checked: false },
      { product: "TelemPlus", variation: "T4", count: 0, monitoringService: '', checked: false },
      { product: "TelemPlus", variation: "RF", count: 0, monitoringService: '', checked: false },
      { product: "TelemPlus", variation: "4G GEO", count: 0, monitoringService: '', checked: false },
      // { product: "TelemPlus", variation: "GEO EAGLE", count: 0, monitoringService: '', checked: false },
      { product: "TelemPlus", variation: "MOST", count: 0, monitoringService: '', checked: false },
      { product: "TelemPlus", variation: "2G TIVE", count: 0, monitoringService: '', checked: false },
      { product: "TelemPlus", variation: "5G TIVE", count: 0, monitoringService: '', checked: false },
      {
        product: "TelemPlus",
        variation: "Escavox",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      { product: "Dunnage Bags", variation: "Standard", count: 0, monitoringService: '', checked: false },
      { product: "Blast Freezing", variation: "Standard", count: 0, monitoringService: '', checked: false },
    ],
    availableProducts: {
      'Genset': false,
      'Otflow': false,
      'Zoono': false,
      'Void_Plugs': false,
      'Late_Arrival': false,
      'Early_Arrival': false,
      'Fumigation': false,
      'monitoringService': null,
      'SteriInsurance': false,
    },
    lateDescription: null,
    earlyDescription: null,
    addServiceModal: false,
  }),
  async mounted() {
    this.setParties()
    this.driver = new Driver({
      animate: false,
    });
  },
  watch: {
    async portSearch(val) {
      if (this.loadingPortSearch) {
        clearTimeout(this.portSearchTimeout);
      }
      if (this.loadingPortSearch && this.portSearchQuery) {
        this.portSearchQuery.abort();
      }
      this.loadingPortSearch = true;
      let params = {
        search: val,
        portType: this.selectedTransportModes,
      };
      if (this.item.destinationCountry) {
        params.filter = {
          countryCode: this.item.destinationCountry.iso2,
        };
      }
      this.portSearchTimeout = setTimeout(async () => {
        this.portSearchQuery = new AbortController();
        const signal = this.portSearchQuery.signal;
        this.portResults = await this.$API.searchTowns({
          params: params,
          signal,
        });
        this.loadingPortSearch = false;
      }, 750);
    },
    selectedRadio(newVal) {
      this.item.documentationPOD = ""; // Reset the value to empty string
      this.documentationPODSearch = ""; // Reset the search to empty string
      this.ports = [];
    },
    async destinationPointSearch(val) {
      if (this.destinationPointLoading) {
        clearTimeout(this.destinationPointTimeout);
      }
      if (val && val.length > 2) {
        this.destinationPointLoading = true;
        this.destinationPointTimeout = setTimeout(async () => {
          this.destinationPoints = await this.$API.getCountryTowns(
            this.item.podCountry.iso2,
            {
              params: {
                search: val,
                portType: this.destinationFilterPoint,
              },
            }
          );
          this.destinationPointLoading = false;
        }, 250);
      } else {
        clearTimeout(this.destinationPointTimeout);
        this.destinationPointLoading = false;
      }
    },

    "item.destinationCountry": {
      immediate: true,
      handler(val) {
        if (val) {
          this.item.destinationCountryId = val.id;
          this.item.podCountry = val;
          // this.item.allDestinationPorts = true
          this.$nextTick(() => {
            document.activeElement.blur();
          });
        }
      },
    },
    "item.podCountry": {
      immediate: true,
      handler(val) {
        if (val) {
          this.item.podCountryId = val.id;
          this.$nextTick(() => {
            document.activeElement.blur();
          });
        }
      },
    },
    "item.originCountry": {
      immediate: true,
      handler(val) {
        if (val) {
          this.item.originCountryId = val.id;
          if (!this.item.polCountry) {
            this.item.polCountry = val;
          }
          // this.item.allOriginPorts = true
          this.$nextTick(() => {
            document.activeElement.blur();
          });
        }
      },
    },
    "item.polCountry": {
      immediate: true,
      handler(val) {
        if (val) {
          this.item.polCountryId = val.id;
          this.$nextTick(() => {
            document.activeElement.blur();
          });
        }
      },
    },
    "incoTerm.originCharge": {
      immediate: true,
      handler(val) {
        if (val && val != "forwarder") {
          this.originInvoiceTo = null;
          if (this.$refs.originInvoice) {
            this.$refs.originInvoice.reset();
          }
        }
      },
    },
    "incoTerm.freightCharge": {
      immediate: true,
      handler(val) {
        if (val && val != "forwarder") {
          this.freightInvoiceTo = null;
          if (this.$refs.freightInvoice) {
            this.$refs.freightInvoice.reset();
          }
        }
      },
    },
    "incoTerm.destinationCharge": {
      immediate: true,
      handler(val) {
        if (val && val != "forwarder") {
          this.destinationInvoiceTo = null;
          if (this.$refs.destinationInvoice) {
            this.$refs.destinationInvoice.reset();
          }
        }
      },
    },
    async entryPointSearch(val) {
      if (this.entryPointLoading) {
        clearTimeout(this.entryPointTimeout);
      }
      if (val && val.length > 2) {
        this.entryPointLoading = true;

        this.entryPointTimeout = setTimeout(async () => {
          this.entryPoints = await this.$API.getCountryTowns(
            this.item.polCountry.iso2,
            {
              params: {
                search: val,
                portType: this.entryFilterPoint,
              },
            }
          );
          this.entryPointLoading = false;
        }, 250);
      } else {
        clearTimeout(this.entryPointTimeout);
        this.entryPointLoading = false;
      }
    },

    async documentationPODSearch(val) {
      if (this.documentationPODLoading) {
        clearTimeout(this.documentationPODTimeout);
      }
      if (val && val.length > 2) {
        this.documentationPODLoading = true;
        if (this.selectedRadio == "sea") {
          this.entryFilterPoint = "port";
        } else if (this.selectedRadio == "air") {
          this.entryFilterPoint = "airport";
        } else if (this.selectedRadio == "rail") {
          this.entryFilterPoint = "rail";
        }
        // else if(this.selectedRadio == 'all'){
        //   this.entryFilterPoint = 'All'
        // }
        this.documentationPODTimeout = setTimeout(async () => {
          this.ports = await this.$API.searchTowns({
            params: {
              search: val,
              portType: this.entryFilterPoint,
            },
          });
          this.documentationPODLoading = false;
        }, 250);
      } else {
        clearTimeout(this.documentationPODTimeout);
        this.documentationPODLoading = false;
      }
    },
    filterComments() {
      this.commentKey++;
    },
    async productSearch() {
      await this.getProducts();
    },
  },
  computed: {
    filteredCharges() {
      // if(this.incoTerm.originCharge == 'forwarder'){
      //   return this.chargeOptions.filter(x=>x.value !== 'forwarder')
      // }
      // else{
      // return this.chargeOptions.filter(x => x.friendlyName !== null)
      // }
      let result = this.chargeOptions.filter(x => x.friendlyName !== null)
      return result
    },
    hasProductsCount() {
      return Object.values(this.availableProducts).some(x => x === true)
    },
    hasDevicesWithCount() {
      return this.availableDevices.some(device => device.count > 0);
    },
    hasDevices() {
      let devices = this.availableDevices.filter(device => device.count > 0);
      return devices
    },
    customers(){
        let organisations = this.contacts.map(x=>x.relatedOrganisation)
        let result = this.profile.consigneeProfileRelationships.map(x=>({
            ...x,
            organisation: organisations.find(y=>y.id==x.customerId),
            organisationId: x.customerId
        }))
        return result
    },
    hasProducts() {
      let finalProducts = []
      let products = Object.keys(this.availableProducts).filter(x => this.availableProducts[x] === true)
      products = products.map(x => x.replace(/_/g, " "))
      products.forEach(product => {
        let productObject = {
          product: product,
          selected: true
        }
        finalProducts.push(productObject)
      })
      return finalProducts
    },
    uniqueProducts() {
      const unique = new Set();
      const result = [];
      for (const device of this.availableDevices) {
        if (!unique.has(device.product)) {
          unique.add(device.product);
          result.push(device.product);
        }
      }
      return result;
    },
    displayReeferSettings() {
      let result = false;
      if (this.containerItem && this.containerItem.containerTypeCode) {
        let findType = this.containerTypes.find(
          (x) => x.typeCode == this.containerItem.containerTypeCode
        );
        result = findType && findType.typeCategory == "Reefer";
      }
      return result;
    },
    filterCountries() {
      let result = this.countries;
      if (this.searchCountries) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.searchCountries.toLowerCase())
        );
      }
      return result;
    },
    filterProducts() {
      let result = this.products.data.filter((x) => x.hsCode);
      if (result && this.filterProductType) {
        result = result.filter((x) => x.type == this.filterProductType);
      }
      return result;
    },
    filterRegimeCodes() {
      let result = this.regimeCodes;
      if (this.regimeCodeSearch) {
        result = result.filter((x) =>
          x.code.toLowerCase().includes(this.regimeCodeSearch.toLowerCase())
        );
      }
      return result;
    },
    commentList() {
      let result = this.consigneeProfileComments;
      if (this.filterComments == "Internal") {
        result = result.filter((x) => x.internal);
      } else if (this.filterComments == "External") {
        result = result.filter((x) => !x.internal);
      }
      return result;
    },
    dischargePorts() {
      let result = this.item.consigneeProfilePorts.filter(
        (x) => x.type == "discharge"
      );
      return result;
    },
    freightParties() {
      let keys = ["buyer", "consignee", "shipper", "forwarder"];
      let result = [];
      keys.forEach((x) => {
        if (this.item[x] && !result.find((y) => y.id == this.item[x + "Id"])) {
          result.push(this.item[x]);
        }
      });

      return result;
    },
    hauliers() {
      let result = [
        {
          index: 2,
          type: "Carrier Haulage",
          name: "Carrier Haulage",
          logo: null,
          icon: "directions_boat",
          flag: "isCarrierHaulage",
        },
        {
          index: 4,
          type: "Merchant Haulage",
          name: "Merchant Haulage",
          icon: "local_shipping",
          logo: null,
          flag: "isPrivateHaulage",
        },
        {
          index: 3,
          type: "Railage",
          name: "Rail",
          icon: "train",
          logo: null,
          flag: "isRailage",
        },
      ];
      return result;
    },
    loadingPorts() {
      let result = this.item.consigneeProfilePorts.filter(
        (x) => x.type == "loading"
      );
      return result;
    },
    subscribedUsers() {
      let filteredUsers =
        this.item.notificationSubscribers &&
          this.item.notificationSubscribers.users
          ? this.item.notificationSubscribers.users
            .filter((x) => x.organisationUserId)
            .map((x) => x.organisationUserId)
          : [];
      let result = this.users.filter((x) => filteredUsers.includes(x.id));
      return result;
    },
    teams() {
      // console.log('Subscribers', this.item.notificationSubscribers)
      let filteredTeams =
        this.item.notificationSubscribers &&
          this.item.notificationSubscribers.teams
          ? this.item.notificationSubscribers.teams
            .filter((x) => x.teamId)
            .map((x) => x.teamId)
          : [];
      let result = this.allTeams.filter((x) => filteredTeams.includes(x.id));
      return result;
    },
  },
  async created() {
    this.containerTypes = await this.$API.listContainerTypes();
    this.getCarrierContract();
    this.getTeams();
    this.getOrganisationUsers();
    this.getRegimeCodes();
    this.loadProducts();
    await this.getSteps();
  },
  methods: {
    async getSteps() {
        let guide = await this.$API.getGuideSteps({
            guideId: 'ConsigneeProfile/Settings/Steps',
        });
        this.steps = guide.steps
    },
    checkItemCount(item) {
      let count = parseInt(item.count)
      if (count < 1) {
        item.checked = false
      }
    },
    changeMovementType() {
      if (this.item.movementType == "EXPORT") {
        this.item.movementType = "IMPORT";
        this.item.preAdviseRequired = false
      } else {
        this.item.movementType = "EXPORT";
      }
    },
    generateKey(product) {
      return `${product.product}-${product.variation}`;
    },
    exitContainerItemModal() {
      this.availableDevices = [
      { product: "TelemPlus", variation: "Escavox", count: 0, monitoringService: '', checked: false },

        { product: "TelemPlus", variation: "2G GEO", count: 0, monitoringService: '', checked: false },
        {
            product: "TelemPlus",
            variation: "4G PROBE",
            count: 0,
            monitoringService: "",
            checked: false,
          },
          {
            product: "TelemPlus",
            variation: "STD PROBE",
            count: 0,
            monitoringService: "",
            checked: false,
          },
        // { product: "TelemPlus", variation: "3G GEO", count: 0, monitoringService: '', checked: false },
        { product: "TelemPlus", variation: "T4", count: 0, monitoringService: '', checked: false },
        { product: "TelemPlus", variation: "RF", count: 0, monitoringService: '', checked: false },
        { product: "TelemPlus", variation: "4G GEO", count: 0, monitoringService: '', checked: false },
        // { product: "TelemPlus", variation: "GEO EAGLE", count: 0, monitoringService: '', checked: false },
        { product: "TelemPlus", variation: "MOST", count: 0, monitoringService: '', checked: false },
        { product: "TelemPlus", variation: "2G TIVE", count: 0, monitoringService: '', checked: false },
        { product: "TelemPlus", variation: "5G TIVE", count: 0, monitoringService: '', checked: false },
        { product: "Dunnage Bags", variation: "Standard", count: 0, monitoringService: '', checked: false },
        { product: "Blast Freezing", variation: "Standard", count: 0, monitoringService: '', checked: false },
      ],
        this.availableProducts = {
          'Genset': false,
          'Otflow': false,
          'Zoono': false,
          'Void_Plugs': false,
          'Late_Arrival': false,
          'Early_Arrival': false,
          'Fumigation': false,
          'monitoringService': null,
          'SteriInsurance': false,
        },
        this.lateDescription = null,
        this.earlyDescription = null,

        this.containerItemModal = false;
      this.containerItem = {};

    },
    mapTemporaryProducts(temporaryProduct) {
      let devicesList = 'Devices Required:\n'
      return temporaryProduct.reduce((acc, product) => {
        const existingProduct = acc.find(
          (accProduct) => accProduct.product === product.product
        );
        if (!existingProduct) {
          const view = this.temporaryProductsView.find(
            (view) => view.name === product.product
          );
          if (view) {
            if (view.name == 'TelemPlus') {
              devicesList += '• ' + product.count + 'X' + product.variation + '\n'
              // const existingDevice = acc.find(
              //   (accProduct) => accProduct.product === 'Devices Required'
              // );
              // if(!existingDevice){
              // acc.push({
              //   product: 'Devices Required',
              //   variation: product.variation,
              //   icon: view.icon,
              //   color: view.color,
              // })
              // }
            }
            else if (view.name == 'Late Arrival') {
              acc.push({
                product: product.product + ' ' + 'Required' + ' : ' + product.variation,
                icon: view.icon,
                color: view.color,
              })
            }

            else if (view.name == 'Early Arrival') {
              acc.push({
                product: product.product + ' ' + 'Required' + ' : ' + product.variation,
                icon: view.icon,
                color: view.color,
              })
            }
            else if (view.name === 'SteriInsurance') {
              acc.push({
                product: 'Steri Insurance' + ' ' + 'Required',
                icon: view.icon,
                color: view.color,

              })
            }
            else {
              acc.push({
                product: product.product + ' ' + 'Required',
                icon: view.icon,
                color: view.color,
              });
            }
          }

        }

        //check if there is a monitoring service against one of our TelemPlus products
        if (product.product === 'Monitoring Service' && product.variation) {

          acc.push({
            product: product.variation + ' ' + 'Service',
            variation: product.monitoringService,
            icon: "desktop_windows",
            color: "#99B080"
          })
        }
        if (devicesList.length > 17) {
          //inside my acc check if there is entry with varaition none
          const existingDevice = acc.find(
            (accProduct) => accProduct.variation == 'None')
          if (!existingDevice) {
            acc.push({
              product: devicesList,
              variation: 'None',
              icon: "router",
              color: "green",
            })
          }
          else {
            //remove the existing entry and replace with new one
            acc.splice(acc.indexOf(existingDevice), 1)
            acc.push({
              product: devicesList,
              variation: 'None',
              icon: "router",
              color: "green",
            })
          }
        }
        return acc;
      }, []);
    },
    saveEditProducts() {
      console.log('save edit products')
    },
    addProducts() {
      this.saveProducts = true
      console.log('montioring service', this.availableProducts.monitoringService)
      let monitoringService = this.availableProducts.monitoringService
      let temporaryProducts = []
      let filteredDevices = this.availableDevices.filter((x) => x.count > 0)

      filteredDevices.map((x) => {
        // x.monitoringService = monitoringService
        // x.checked=null
        x.monitoringService = monitoringService
        x.count = parseInt(x.count)
        delete x.checked
        // const newObj={...x}
        // delete newObj.checked
        // return newObj
      })
      let filteredProducts = Object.keys(this.availableProducts).filter((x) => this.availableProducts[x] == true)
      filteredProducts.map((x) => {
        let product = {
          product: x,
          variation: 'Standard',
          count: 1,
          monitoringService: monitoringService
        }
        temporaryProducts.push(product)
      })
      // console.log(
      //   'filteredDevices',filteredDevices
      // )
      temporaryProducts = [...temporaryProducts, ...filteredDevices]
      this.containerItem.temporaryProducts = temporaryProducts
      this.saveProducts = false
      this.temporaryProductModal = false
    },

    toggleCheckbox(product) {
      product.count = product.count === 0 ? 1 : 0;
    },
    toggleProducts(choice, type) {

      type = type.replace(/ /g, "_");

      if (choice === "YES") {
        if (
          !Object.hasOwn(this.availableProducts, type) ||
          this.availableProducts[type] === false
        ) {
          this.availableProducts[type] = true;
        } else {
          delete this.availableProducts[type];
        }
        this.gensetToggleKey++;
        this.otflowKey++;
        this.zoonoKey++;
        this.industrialKey++;
        this.portMonitoringKey++;
        this.voidPlugKey++;
        this.lateArrivalKey++;
        this.earlyArrivalKey++


      }
      if (choice === "NO") {
        if (
          !Object.hasOwn(this.availableProducts, type) ||
          this.availableProducts[type] === true
        ) {
          this.availableProducts[type] = false;
        } else {
          delete this.availableProducts[type];
        }
        this.gensetToggleKey++;
        this.otflowKey++;
        this.zoonoKey++;
        this.industrialKey++;
        this.portMonitoringKey++;
        this.voidPlugKey++;
        this.lateArrivalKey++;
        this.earlyArrivalKey++
      }
    },
    addServices() {
      this.addServiceModal = true
    },
    updateProductCount(product) {
      //check the devices for the product and varaition
      let count = parseInt(product.count);
      this.availableDevices.forEach((x) => {
        if (x.product == product.name && x.variation == product.variation) {
          x.count = count;
        }
        if (x.count < 1) {
          x.checked = 0
        }
      });

    },
    closeTemporaryProductModal() {
      // this.availableDevices = [
      //   { product: "TelemPlus", variation: "2G GEO", count: 0, monitoringService: '' },
      //     { product: "TelemPlus", variation: "3G GEO", count: 0, monitoringService: '' },
      //     { product: "TelemPlus", variation: "USB", count: 0, monitoringService: '' },
      //     { product: "TelemPlus", variation: "RF", count: 0, monitoringService: '' },
      //     { product: "TelemPlus", variation: "4G GEO", count: 0, monitoringService: '' },
      //     { product: "TelemPlus", variation: "GEO EAGLE", count: 0, monitoringService: '' },
      //     { product: "TelemPlus", variation: "MOST", count: 0, monitoringService: '' },
      //     { product: "TelemPlus", variation: "2G TIVE", count: 0, monitoringService: '' },
      //     { product: "TelemPlus", variation: "5G TIVE", count: 0, monitoringService: '' },
      //     { product: "Void Plugs", variation: "Standard", count: 0, monitoringService: '' },
      //     { product: "Blast Freezing", variation: "Standard", count: 0, monitoringService: '' },
      //   ],
      //   this.availableProducts = {
      //   'Genset': false,
      //   'Otflow': false,
      //   'Zoono': false,
      //   'Dunnage_Bags': false,
      //   'Late_Arrival': false,
      //   'Fumigation': false,
      //   'monitoringService': null,

      // }
      this.temporaryProductModal = false
    },
    async getTemporaryProducts() {
      this.temporaryProductModal = true

    },
    selectQuantityType() {
      this.cargoItem.quantityTypeCode = this.selectedQuantityType.value
      this.cargoItem.quantityType = this.selectedQuantityType.text
    },
    addContainerCargoDetails() {
      this.cargoItem = {
        type: "container",
        index: this.containerItem.bookingCargoItems.length,
        quantityType: "Carton",
        quantityTypeCode: "CT",
      };
      this.selectedQuantityType = {
        value: 'CT',
        text: 'Carton'
      }
      let findContainer = this.containerTypes.find(
        (x) => x.typeCode == this.containerItem.containerTypeCode
      );
      if (findContainer) {
        this.cargoItem.volume = 25 * findContainer.teu;
      }
      this.cargoModal = true;
    },
    async addContainerDetails() {
      if (this.item.serviceType == "BREAKBULK") {
        this.containerItem = {
          quantity: 1,
          bookingCargoItems: [],
          type: "BREAKBULK",
          index: this.item.bookingContainerItems.length,
          verificationMethod:
            this.item.movementType == "EXPORT" ? "Method 2" : null,
          // dropOffPoint: findOriginTerminal ? findOriginTerminal.originTerminal : null
        };
        this.ventSettings = [
          "Closed",
          "5",
          "10",
          "15",
          "20",
          "25",
          "28",
          "30",
          "35",
          "40",
          "50",
          "Fully Open",
          "CA",
          "MA",
          "Auto",
        ];
        this.containerItemModal = true;
      } else {
        if (!this.item.bookingContainerItems) {
          this.item.bookingContainerItems = [];
        }
        this.containerItem = {
          type: "CONTAINER",
          quantity: 1,
          bookingCargoItems: [],
          containerTypeCode: "45R1",
          index: this.item.bookingContainerItems.length,
          verificationMethod:
            this.item.movementType == "EXPORT" ? "Method 2" : null,
        };
        // if (this.item.regimeCode) {
        //   let find = this.regimeCodes.find(
        //     (x) => x.code == this.item.regimeCode
        //   );
        //   if (find) {
        //     this.containerItem.setPointTemp = find.setPointTemp;
        //   }
        // }

        // if (
        //   this.booking.consigneeProfile &&
        //   this.booking.consigneeProfile.consigneeProfileProducts
        // ) {
        //   for (
        //     let i = 0;
        //     i < this.booking.consigneeProfile.consigneeProfileProducts.length;
        //     i++
        //   ) {
        //     this.cargoItem =
        //       this.booking.consigneeProfile.consigneeProfileProducts[i];
        //     this.cargoItem.type = "container";
        //     this.cargoItem.index = i;
        //     delete this.cargoItem.id;
        //     this.setCargoItemDetails();
        //     await this.saveCargoItem();
        //   }
        // }
        this.ventSettings = [
          "Closed",
          "5",
          "10",
          "15",
          "20",
          "25",
          "28",
          "30",
          "35",
          "40",
          "50",
          "Fully Open",
          "CA",
          "MA",
          "Auto",
        ];
        this.containerItemModal = true;
      }
    },
    addMilestone(item, type = null, autosave = true) {
      if (type && item[type]) {
        this.milestoneItem = item[type];
        this.milestoneItem.autosave = autosave;
      } else {
        this.milestoneItem = {
          type,
          bookingContainerItemId: item.id,
          pol: null,
          date: new Date(),
          autosave,
          isActual: false,
        };
      }

      this.addMilestoneModal = true;
      this.$nextTick(() => {
        this.milestoneKey++;
      });
    },
    infoStartGuide() {
      if (this.infoStart) {
        this.infoStart = false;
      } else {
        this.infoStart = true;
      }
    },
    startGuide(e) {
      this.infoStart = false;
      e.stopPropagation();
      this.driver.defineSteps(this.steps);
      this.driver.start();
    },

    openDocumentationPODModal(val) {
      this.documentationPODModal = true;
      this.getDocumentationPodPorts(val);
    },
    async getDocumentationPodPorts(val) {
      this.documentationPODLoading = true;
      this.ports = await this.$API.searchTowns({
        params: {
          search: val,
        },
      });
      console.log(this.ports);
      this.documentationPODLoading = false;
    },
    addEmail() {
      this.email = {
        index: this.item.consigneeProfileDistributionLists.length,
      };
      this.emailDialog = true;
    },
    addPayment() {
      this.incoTerm = {
        index: this.item.consigneeProfileIncoTerms.length,
        consigneeProfileShippingContracts: [],
      };
      this.setParties()
      this.incoTermDialog = true;
    },
    addProduct() {
      this.productItem = {};
      this.productDialog = true;
    },
    async addProductToProfile(product) {
      this.loadingProductId = product.id;
      this.loadingProduct = true;
      let findExisting = this.item.consigneeProfileProducts.find(
        (x) => x.productId == product.id
      );

      if (!this.item.filterProductId) {
        this.item.filterProductId = product.id;
      }

      if (!findExisting) {
        if (this.item.id) {
          await this.$API.createConsigneeProfileProduct({
            consigneeProfileId: this.item.id,
            productId: product.id,
          });
          this.$emit("refreshProduct");
        } else {
          // this.item.consigneeProfileProducts.push({
          //   productId: product.id,
          //   product: product
          // })
          this.$emit("addProduct", {
            productId: product.id,
            product: product,
          });
        }
      }
      this.loadingProductId = null;
      this.loadingProduct = false;
    },
    addShippingContract() {
      this.shippingContract = {
        index: this.incoTerm.consigneeProfileShippingContracts.length,
        contractOwner: this.incoTerm.contractOwner,
      };
      this.shippingContractModal = true;
    },
    addSubscriber() {
      this.subscriberModal = true;
    },
    // addNewfreightContract(item) {
    //   this.freightItem = item;
    //   if (!item.consigneeProfileId && this.item.id) {
    //     this.freightItem.consigneeProfileId = this.item.id;
    //   }
    //   this.freightContractModal = true;
    // },
    async addPort(type, port) {
      let obj = {
        type: type,
        name: port.name,
        code: port.locode,
      };
      this.item.consigneeProfilePorts.push(obj);

      if (this.item.id) {
        obj.consigneeProfileId = this.item.id;
        obj = await this.$API.createConsigneeProfilePort(obj);
        this.item.consigneeProfilePorts[
          this.item.consigneeProfilePorts.length - 1
        ] = obj;
      }
      this.entryPointSearch = "";
      this.entryPoints = [];
      this.entryPoint = null;
      this.destinationPointSearch = "";
      this.destinationPoints = [];
      this.destinationPoint = null;
      this.locationKey++;
    },

    async changePortType(type) {
      if (type == "origin") {
        this.item.allOriginPorts = !this.item.allOriginPorts;
      } else {
        this.item.allDestinationPorts = !this.item.allDestinationPorts;
      }
      if (this.item.id) {
        await this.$API.updateConsigneeProfile(this.item);
      }
    },
    clearDestinationPorts() {
      this.item.consigneeProfilePorts = this.item.consigneeProfilePorts.filter(
        (x) => x.type != "discharge"
      );
    },
    clearLoadingPorts() {
      this.item.consigneeProfilePorts = this.item.consigneeProfilePorts.filter(
        (x) => x.type != "loading"
      );
    },
    async deleteCargoItem(item) {
      if (item.id) {
        await this.$API.updateProfileCargoItem(
          item.id,
          {
            isActive: false, isDeleted: true
          }
        )
      }
      this.containerItem.bookingCargoItems.splice(item.index, 1);
    },
    async deleteContainerItem(item) {
      if (item.id) {
        await this.$API.updateProfileContainerItem(
          item.id,
          {
            isActive: false, isDeleted: true
          }
        )
      }
      this.item.bookingContainerItems.splice(item.index, 1);
    },
    editEmail(item) {
      this.email = item;
      this.emailDialog = true;
    },
    editCountry(type) {
      this.countryItemType = type;
      this.portModal = true;
    },
    editPayment(item) {
      this.incoTerm = item;
      this.incoTerm.index = this.item.consigneeProfileIncoTerms.indexOf(item);
      this.setParties()
      this.incoTermDialog = true;
    },
    editCargoItem(item) {
      this.cargoItem = item;
      this.cargoModal = true;
    },
    async editContainerItem(item) {
      this.bulkType = 'Edit'
      this.containerItem = item;
      console.log('containerItem', this.containerItem)
      if (this.containerItem.temporaryProducts) {
        let filteredDevices = this.containerItem.temporaryProducts.filter((x) => x.product == 'TelemPlus' || x.product == 'Dunnage Bags' || x.product == 'Blast Freezing')
        this.availableDevices.forEach(x => {
          filteredDevices.forEach(y => {
            if (x.product == y.product && x.variation == y.variation) {
              x.count = y.count,
                x.monitoringService = y.monitoringService
              x.checked = true
            }
          })
        })
        let filteredProducts = this.containerItem.temporaryProducts.filter((x) => x.product != 'TelemPlus' && x.product != 'Dunnage Bags' && x.product != 'Blast Freezing')
        Object.keys(this.availableProducts).forEach(x => {
          filteredProducts.forEach(y => {
            if (x == y.product) {
              this.availableProducts[x] = true
            }
          })
        })
        let filteredMonitoringService = this.containerItem.temporaryProducts.filter((x) => x.product == 'Monitoring Service')
        if (filteredMonitoringService.length) {
          this.availableProducts.monitoringService = filteredMonitoringService[0].monitoringService
        }
      }
      if (this.containerItem.regimeId) {
        let ventSettings = await this.$API.getRegimeVent(this.containerItem.regimeId)
        if (ventSettings.length > 0) {
          this.ventSettings = ventSettings.map(x => x.ventCode)
        } else {
          this.ventSettings = [
            "Closed",
            "5",
            "10",
            "15",
            "20",
            "25",
            "28",
            "30",
            "35",
            "40",
            "50",
            "Fully Open",
            "CA",
            "MA",
            "Auto",
          ]
        }
      } else {
        this.ventSettings = [
          "Closed",
          "5",
          "10",
          "15",
          "20",
          "25",
          "28",
          "30",
          "35",
          "40",
          "50",
          "Fully Open",
          "CA",
          "MA",
          "Auto",
        ]
      }
      if (!this.containerItem.vents && this.ventSettings.length == 1) {
        this.containerItem.vents = this.ventSettings[0];
      }
      if (this.availableProducts.Late_Arrival == true) {
        this.lateDescription = this.containerItem.temporaryProducts.find(x => x.product == 'Late_Arrival').variation
      }
      if (this.availableProducts.Early_Arrival == true) {
        this.earlyDescription = this.containerItem.temporaryProducts.find(x => x.product == 'Early_Arrival').variation
      }

      const telemPlusDevice = this.availableDevices.find(device => device.count > 0 && device.product === 'TelemPlus');
      if (telemPlusDevice) {
        this.availableProducts.monitoringService = telemPlusDevice.monitoringService;
      }


      for (let milestone of item.containerMilestones) {
        this.containerItem[milestone.type] = milestone
      }
      this.containerItemModal = true;
    },
    editShippingContract(item) {
      this.shippingContract = item;
      this.shippingContractModal = true;
    },
    editTeams() {
      this.selectedTeams = this.item.notificationSubscribers.teams
        .filter((x) => x.teamId)
        .map((team) => team.teamId);
      this.teamModal = true;
    },
    editUsers() {
      this.selectedUsers = this.item.notificationSubscribers.users
        .filter((x) => x.organisationUserId)
        .map((x) => x.organisationUser);
      this.userModal = true;
    },
    async loadProducts() {
      this.loadingProduct = true;
      this.products = await this.$API.getProducts({});
      this.loadingProduct = false;
    },
    formatDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd mmm, yyyy HH:MM");
      }
      return result;
    },
    async getCarrierContract() {
      this.shippingLines = await this.$API.getShippingLines();
      this.carrierContracts = await this.$API.getShippingContracts();
      this.carriers = await this.$API.listCarriers();
      this.incoTerms = await this.$API.getIncoTerms();
    },
    // async getProducts() {
    //   if (this.productSearchTimer) {
    //     clearTimeout(this.productSearchTimer);
    //   }
    //   this.loadingProduct = true;
    //   this.productSearchTimer = setTimeout(async () => {
    //     this.products = await this.$API.getProducts({
    //       params: {
    //         filter: {
    //           type: "Product",
    //         },
    //         search: this.productSearch,
    //       },
    //     });
    //     this.loadingProduct = false;
    //   }, 500);
    // },
    getTeamMemberCount(id) {
      let findTeam = this.allTeams.find((team) => team.id == id);
      return findTeam ? findTeam.members : 0;
    },
    formatTime(time) {
      let result = null;
      if (time) {
        result = dateFormat(new Date(time), "HH:MM dd mmmm yyyy");
      }
      return result;
    },
    async getOrganisationUsers() {
      this.loadingUsers = true;
      this.users = await this.$API.getOrganisationUsers();
      this.loadingUsers = false;
    },
    async getTeams() {
      this.allTeams = await this.$API.getTeams();
    },
    onOpen(key) {
      // this.items = key === "@" ? users : issues;
    },

    onApply(item, key, replacedWith) {
      console.log(item, `has been replaced with ${replacedWith}`);
      this.commentMentions.push({
        value: replacedWith.trim(),
        user: item,
        userId: item.id,
      });
    },
    loadUsers(searchText = null) {
      if (this.loadingUsers) {
        clearTimeout(this.userTimeout);
      }
      this.loadingUsers = true;
      this.users = [];
      this.userTimeout = setTimeout(() => {
        this.$API
          .searchOrganisationUser({
            params: { search: searchText },
          })
          .then((res) => {
            res = res.map((x) => ({
              ...x,
              value: x.firstname + " " + x.surname,
            }));
            this.users = res;
          })
          .finally(() => (this.loadingUsers = false));
      }, 500);
    },
    async removeProfile(item) {
      this.$confirm(
        "Are you sure you want to remove this deal term from the profile?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          if (item.id) {
            await this.$API.updateConsigneeProfileIncoTerm({
              id: item.id,
              isActive: false,
              isDeleted: true,
            });
          }
          this.item.consigneeProfileIncoTerms.splice(
            this.item.consigneeProfileIncoTerms.indexOf(item),
            1
          );
          this.contractKey++;
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch((e) => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    async removeContract(item) {
      this.$confirm(
        "Are you sure you want to remove this contract from the profile?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          if (item.id) {
            await this.$API.updateConsigneeProfileContract({
              id: item.id,
              isActive: false,
              isDeleted: true,
            });
          }
          this.incoTerm.consigneeProfileShippingContracts.splice(
            this.incoTerm.consigneeProfileShippingContracts.indexOf(item),
            1
          );
          this.updateContractIndex();
          this.contractKey++;
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch((e) => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    async removeEmail(item) {
      this.$confirm(
        "Are you sure you want to remove this email from the profile?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          if (item.id) {
            await this.$API.updateConsigneeProfileDistributionList({
              id: item.id,
              isActive: false,
              isDeleted: true,
            });
          }
          this.item.consigneeProfileDistributionLists.splice(
            this.item.consigneeProfileDistributionLists.indexOf(item),
            1
          );
          this.emailKey++;
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch((e) => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    async removeProduct(item) {
      this.$confirm(
        "Are you sure you want to remove this product from the profile?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          this.loadingProduct = true;
          if (item.id) {
            await this.$API.updateConsigneeProfileProduct({
              id: item.id,
              isActive: false,
              isDeleted: true,
            });
          }
          this.item.consigneeProfileProducts.splice(
            this.item.consigneeProfileProducts.indexOf(item),
            1
          );
          if (this.item.id) {
            this.$emit("refreshProduct");
          }
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
          this.loadingProduct = false;
        })
        .catch((e) => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    filterObject(item, queryText, itemText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
        -1 ||
        item.locode.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
        -1
      );
    },
    getContainerDescription(code) {
      let find = this.containerTypes.find((x) => x.typeCode == code);
      return find ? find.shortDescription : null;
    },
    async getRegimeCodes(modal = false) {
      this.regimeCodeModal = modal;
      this.regimeCodeLoading = true;
      let params = {};
      if (this.item.destinationCountry) {
        params.countryCode = this.item.destinationCountry.iso2;
      }
      if (
        this.containerItem &&
        this.containerItem.bookingCargoItems &&
        this.containerItem.bookingCargoItems.length > 0
      ) {
        params.productCode = this.containerItem.bookingCargoItems.map(
          (x) => x.productId
        );
      }
      this.regimeCodes = await this.$API.getRegimeCodes({
        params: params,
      });
      this.regimeCodeLoading = false;
    },
    async removePort(item) {
      this.item.consigneeProfilePorts.splice(
        this.item.consigneeProfilePorts.indexOf(item),
        1
      );
      if (item.id) {
        await this.$API.updateConsigneeProfilePort({
          id: item.id,
          isActive: false,
          isDeleted: true,
        });
      }
    },
    async saveShippingContract() {
      this.savingContract = true;
      this.shippingContract.shippingLine = this.shippingLines.find(
        (x) => x.id == this.shippingContract.shippingLineId
      );
      if (this.incoTerm.id) {
        if (this.shippingContract.id) {
          await this.$API.updateConsigneeProfileContract(this.shippingContract);
        } else {
          this.shippingContract.consigneeProfileIncoTermId = this.incoTerm.id;
          let result = await this.$API.createConsigneeProfileContract(
            this.shippingContract
          );
          this.shippingContract.id = result.id;
          this.incoTerm.consigneeProfileShippingContracts.push(
            this.shippingContract
          );
        }
      } else {
        let findIndex =
          this.incoTerm.consigneeProfileShippingContracts.findIndex(
            (x) => x.index == this.shippingContract.index
          );
        if (findIndex > -1) {
          this.incoTerm.consigneeProfileShippingContracts[findIndex] =
            this.shippingContract;
        } else {
          this.incoTerm.consigneeProfileShippingContracts.push(
            this.shippingContract
          );
        }
      }
      this.shippingContractModal = false;
      this.shippingContract = {};
      this.savingContract = false;
    },
    async saveEmail() {
      if (this.email.id) {
        await this.$API.updateConsigneeProfileDistributionList(this.email);
        this.item.consigneeProfileDistributionLists[
          this.item.consigneeProfileDistributionLists.findIndex(
            (x) => x.id == this.email.id
          )
        ] = this.email;
      } else {
        if (this.item.id) {
          this.email.consigneeProfileId = this.item.id;
          console.log('this.email', this.email)
          //check if , is in this.email.email
          if (this.email.email.includes(',')) {
            let emails = this.email.email.split(',')
            let index = this.email.index
            emails.forEach(async (x) => {
              this.email.email = x.trim()
              this.email.index = index
              this
              let result = await this.$API.createConsigneeProfileDistributionList(
                this.email
              );
              this.item.consigneeProfileDistributionLists.push(result);
              //add +1 toe index
              index++
            })
     
          } else {
            let result = await this.$API.createConsigneeProfileDistributionList(
              this.email
            );
            this.item.consigneeProfileDistributionLists.push(result);
          }

          // this.email = await this.$API.createConsigneeProfileDistributionList(
          //   this.email
          // );
        }
        if (this.item.consigneeProfileDistributionLists[this.email.index]) {
          this.item.consigneeProfileDistributionLists[this.email.index] =
            this.email;
        } else {
          this.item.consigneeProfileDistributionLists.push(this.email);
        }
        // this.item.consigneeProfileDistributionLists.push(this.email);
      }
      this.emailKey++;
      this.emailDialog = false;
      this.email = {};
    },
    async saveCargoItem() {
      this.savingCargoItem = true
      this.cargoItem.productId = this.cargoItem.product.id;
      this.cargoItem.hsCodeId = this.cargoItem.hsCode.id;
      if (this.item.id && this.containerItem.id) {
        if (!this.cargoItem.id) {
          let res = await this.$API.createProfileCargoItem(
            this.containerItem.id,
            this.cargoItem
          );
          res.product = this.cargoItem.product
          res.hsCode = this.cargoItem.hsCode
          this.containerItem.bookingCargoItems.push(res);
        } else {
          await this.$API.updateProfileCargoItem(
            this.cargoItem.id,
            this.cargoItem
          );
        }
      } else {
        if (this.cargoItem.type == "container") {
          let find = this.containerItem.bookingCargoItems.findIndex(
            (x) => x.index === this.cargoItem.index
          );
          this.cargoItem.productId = this.cargoItem.product.id;
          this.cargoItem.hsCodeId = this.cargoItem.hsCode.id;

          if (find > -1) {
            this.containerItem.bookingCargoItems[find] = this.cargoItem;
          } else {
            this.containerItem.bookingCargoItems.push(this.cargoItem);
          }
        } else {
          let find = this.item.bookingCargoItems.findIndex(
            (x) => x.index === this.cargoItem.index
          );
          this.cargoItem.productId = this.cargoItem.product.id;
          this.cargoItem.hsCodeId = this.cargoItem.hsCode.id;
          if (find > -1) {
            this.item.bookingCargoItems[find] = this.cargoItem;
          } else {
            this.item.bookingCargoItems.push(this.cargoItem);
          }
        }
      }

      this.cargoKey++;
      this.cargoModal = false;
      this.cargoItem = {};
      this.savingCargoItem = false
    },
    async saveContainerItem() {
      this.savingContainerItem = true

      //change the containerItem temporaryProducts to the availableProducts/Devices
      let temporaryProducts = []
      //check availalbeProducts monitoringService
      let monitoringService = this.availableProducts.monitoringService
      Object.keys(this.availableProducts).forEach(x => {

        console.log('currently x', x)
        console.log('this.availableProducts[x]', this.availableProducts[x])
        console.log('monitoringService', monitoringService)
        if (this.availableProducts[x] && x == 'Late_Arrival') {
          temporaryProducts.push({ product: x, variation: this.lateDescription, count: 1, monitoringService: '' })
        }
        if (this.availableProducts[x] && x == 'Early_Arrival') {
          temporaryProducts.push({ product: x, variation: this.earlyDescription, count: 1, monitoringService: '' })
        }
        else if (this.availableProducts[x] && x == 'monitoringService') {
          temporaryProducts.push({ product: 'Monitoring Service', variation: this.availableProducts[x], count: 1, monitoringService: monitoringService })
          // temporaryProducts.push({ product: 'Port Monitoring', variation: 'Standard', count: 1, monitoringService: monitoringService })

        }
        else if (this.availableProducts[x]) {
          temporaryProducts.push({ product: x, variation: 'Standard', count: 1, monitoringService: '' })
        }
      })
      this.availableDevices.forEach(x => {
        if (x.count > 0) {
          temporaryProducts.push({ product: x.product, variation: x.variation, count: x.count, monitoringService: monitoringService })
        }
      })
      // console.log('this.containerItem',this.containerItem)
      this.containerItem.temporaryProducts = temporaryProducts
      if (this.containerItem.verificationMethod == "Method 2") {
        this.containerItem.weighBridgeId = null;
        this.containerItem.verificationMethodOwner = null;
      }
      this.containerItem.containerMilestones = [];
      for (let milestone of this.availableMilestones) {
        if (this.containerItem[milestone.value]) {
          this.containerItem.containerMilestones.push(
            this.containerItem[milestone.value]
          );
        }
      }
      let find = this.item.bookingContainerItems.findIndex(
        (x) => x.index === this.containerItem.index
      );
      let findType = this.containerTypes.find(
        (x) => x.typeCode == this.containerItem.containerTypeCode
      );
      this.containerItem.containerTypeDescription = findType
        ? findType.shortDescription
        : null;
      if (this.item.id) {
        if (!this.containerItem.id) {
          let res = await this.$API.createProfileContainerItem(
            this.item.id,
            this.containerItem
          );
          this.item.bookingContainerItems.push(res);
        } else {
          await this.$API.updateProfileContainerItem(
            this.containerItem.id,
            this.containerItem
          );
        }
      } else {
        if (find > -1) {
          this.item.bookingContainerItems[find] = this.containerItem;
        } else {
          this.item.bookingContainerItems.push(this.containerItem);
        }
      }
      this.availableDevices = [
      { product: "TelemPlus", variation: "Escavox", count: 0, monitoringService: '', checked: false },

        { product: "TelemPlus", variation: "2G GEO", count: 0, monitoringService: '' },
        {
            product: "TelemPlus",
            variation: "4G PROBE",
            count: 0,
            monitoringService: "",
            checked: false,
          },
          {
            product: "TelemPlus",
            variation: "STD PROBE",
            count: 0,
            monitoringService: "",
            checked: false,
          },
        { product: "TelemPlus", variation: "3G GEO", count: 0, monitoringService: '' },
        { product: "TelemPlus", variation: "T4", count: 0, monitoringService: '' },
        { product: "TelemPlus", variation: "RF", count: 0, monitoringService: '' },
        { product: "TelemPlus", variation: "4G GEO", count: 0, monitoringService: '' },
        { product: "TelemPlus", variation: "GEO EAGLE", count: 0, monitoringService: '' },
        { product: "TelemPlus", variation: "MOST", count: 0, monitoringService: '' },
        { product: "TelemPlus", variation: "2G TIVE", count: 0, monitoringService: '' },
        { product: "TelemPlus", variation: "5G TIVE", count: 0, monitoringService: '' },
        { product: "Dunnage Bags", variation: "Standard", count: 0, monitoringService: '' },
        { product: "Blast Freezing", variation: "Standard", count: 0, monitoringService: '' },
      ],
        this.availableProducts = {
          'Genset': false,
          'Otflow': false,
          'Zoono': false,
          'Void_Plugs': false,
          'Late_Arrival': false,
          'Early_Arrival': false,
          'Fumigation': false,
          'monitoringService': null,

        },
        this.lateDescription = null,
        this.earlyDescription = null,

        this.containerItemModal = false;
      this.containerItem = {};
      this.savingContainerItem = false
    },
    async saveIncoTerm() {
      this.savingIncoTerm = true;
      if (this.incoTerm.id) {
        await this.$API.updateConsigneeProfileIncoTerm(this.incoTerm);
        this.item.consigneeProfileIncoTerms[
          this.item.consigneeProfileIncoTerms.findIndex(
            (x) => x.id == this.incoTerm.id
          )
        ] = this.incoTerm;
      } else {
        if (this.item.id) {
          this.incoTerm.consigneeProfileId = this.item.id;
          let createdIncoTerm = await this.$API.createConsigneeProfileIncoTerm(
            this.incoTerm
          );
          this.incoTerm.id = createdIncoTerm.id;
        }
        if (this.item.consigneeProfileIncoTerms[this.incoTerm.index]) {
          this.item.consigneeProfileIncoTerms[this.incoTerm.index] =
            this.incoTerm;
        } else {
          this.item.consigneeProfileIncoTerms.push(this.incoTerm);
        }
      }
      this.contractKey++;
      this.incoTermDialog = false;
      this.incoTerm = {};
      this.savingIncoTerm = false;
    },
    async saveTeams() {
      this.savingTeams = true;
      if (this.item.id) {
        if (
          this.item.notificationSubscribers &&
          this.item.notificationSubscribers.teams
        ) {
          let create = this.selectedTeams.filter(
            (x) =>
              !this.item.notificationSubscribers.teams
                .map((y) => y.teamId)
                .includes(x)
          );
          let remove = this.item.notificationSubscribers.teams.filter(
            (x) => !this.selectedTeams.includes(x.teamId)
          );
          if (create.length > 0) {
            await this.$API.createConsigneeProfileSubscriber({
              batch: create.map((x) => ({
                teamId: x,
                consigneeProfileId: this.item.id,
              })),
            });
          } else {
            for (let j = 0; j < remove.length; j++) {
              let relationshipTeam =
                this.item.notificationSubscribers.teams.find(
                  (x) => x.teamId == remove[j].teamId
                );
              await this.$API.updateConsigneeProfileSubscriber({
                id: relationshipTeam.id,
                isActive: false,
                isDeleted: true,
              });
            }
          }
        }
        this.$emit("refreshSubscribers");
      } else {
        let create = this.selectedTeams.filter(
          (x) =>
            !this.item.notificationSubscribers.teams
              .map((y) => y.teamId)
              .includes(x)
        );
        for (let i = 0; i < create.length; i++) {
          let findTeam = this.allTeams.find((x) => x.id == create[i]);
          this.item.notificationSubscribers.teams.push({
            teamId: create[i],
            team: findTeam,
          });
        }
        let remove = this.item.notificationSubscribers.teams.filter(
          (x) => !this.selectedTeams.includes(x.teamId)
        );
        for (let i = 0; i < remove.length; i++) {
          let index = this.item.notificationSubscribers.teams.findIndex(
            (x) => x.teamId == remove[i].teamId
          );
          this.item.notificationSubscribers.teams.splice(index, 1);
        }
      }

      this.savingTeams = false;
      this.teamModal = false;
    },
    async saveUsers() {
      this.savingUsers = true;
      if (this.item.id) {
        if (
          this.item.notificationSubscribers &&
          this.item.notificationSubscribers.users
        ) {
          let create = this.selectedUsers.filter(
            (x) =>
              !this.item.notificationSubscribers.users
                .filter((y) => y.organisationUserId)
                .map((y) => y.organisationUserId)
                .includes(x.id)
          );
          let remove = this.item.notificationSubscribers.users.filter(
            (x) =>
              !this.selectedUsers
                .map((y) => y.id)
                .includes(x.organisationUserId)
          );
          if (create.length > 0) {
            await this.$API.createConsigneeProfileSubscriber({
              batch: create.map((x) => ({
                organisationUserId: x.id,
                consigneeProfileId: this.item.id,
              })),
            });
          } else {
            for (let j = 0; j < remove.length; j++) {
              let relationshipTeam =
                this.item.notificationSubscribers.users.find(
                  (x) => x.organisationUserId == remove[j].organisationUserId
                );
              await this.$API.updateConsigneeProfileSubscriber({
                id: relationshipTeam.id,
                isActive: false,
                isDeleted: true,
              });
            }
          }
        }
        this.$emit("refreshSubscribers");
      } else {
        let create = this.selectedUsers.filter(
          (x) =>
            !this.item.notificationSubscribers.users
              .filter((y) => y.organisationUserId)
              .map((y) => y.organisationUserId)
              .includes(x.id)
        );
        for (let i = 0; i < create.length; i++) {
          this.item.notificationSubscribers.users.push({
            organisationUserId: create[i].id,
            organisationUser: create[i],
          });
        }
        let remove = this.item.notificationSubscribers.users.filter(
          (x) =>
            !this.selectedUsers.map((y) => y.id).includes(x.organisationUserId)
        );
        for (let i = 0; i < remove.length; i++) {
          this.item.notificationSubscribers.users.splice(
            this.item.notificationSubscribers.users.findIndex(
              (x) => x.organisationUserId == remove[i].organisationUserId
            ),
            1
          );
        }
      }

      this.savingUsers = false;
      this.userModal = false;
    },
    setCargoItemDetails() {
      if (this.cargoItem.product) {
        this.cargoItem.description = this.cargoItem.product.hsCode.explanation;
        this.cargoItem.hsCode =
          this.cargoItem.product.hsCode.alternateCode.replace(/ /g, "");
      }
    },
    setCountry(country) {
      if (this.countryItemType == "Origin") {
        this.item.originCountry = country;
        this.item.originCountryId = country.id;
      } else if (this.countryItemType == "Destination") {
        this.item.destinationCountry = country;
        this.item.destinationCountryId = country.id;
      }
      this.countryDialog = false;
      this.searchCountries = null;
      this.locationKey++;
    },
    setFinalDestination(item) {
      this.item.finalDestinationValue = item.locode;
      this.item.finalDestinationCity = item.name;
      let findCountry = this.countries.find(
        (x) => x.iso2 == this.item.finalDestinationValue.substring(0, 2)
      );
      this.item.destinationCountry = findCountry;
      this.item.destinationCountryId = findCountry.id;
      this.fdModal = false;
      this.locationKey++;
    },
    setMilestonePoint(item) {
      if (item.type) {
        this.containerItem[`${item.type}`] = item;
      }
      if (!this.containerItem.containerMilestones) {
        this.containerItem.containerMilestones = [];
      }
      let find = this.containerItem.containerMilestones.findIndex(
        (x) => x.type == item.type
      );
      if (find == -1) {
        this.containerItem.containerMilestones.push(item);
      } else {
        this.containerItem.containerMilestones[find] = item;
      }
      this.addMilestoneModal = false;
    },
    setHaulier(haulier) {
      let types = ["isPrivateHaulage", "isCarrierHaulage", "isRailage"];
      if (haulier.flag) {
        let setFalse = types.filter((x) => x != haulier.flag);
        for (let i = 0; i < setFalse.length; i++) {
          this.item[setFalse[i]] = false;
        }
        this.item[haulier.flag] = true;
        this.item.transportCoordinator = null;
        this.item.transportCoordinatorId = null;
      } else {
        for (let i = 0; i < types.length; i++) {
          this.item[types[i]] = false;
        }
        this.item.transportCoordinator = haulier;
        this.item.transportCoordinatorId = haulier.id;
      }
      this.haulierKey++;
    },
    async setRegimeCode(regime) {
      this.containerItem.regimeCode = regime.code;
      this.containerItem.regime = regime;
      this.containerItem.regimeId = regime.id;
      this.containerItem.setPointTemp = regime.setPointTemp;
      // if (regime.regimeVents && regime.regimeVents.length > 0) {
      //   this.ventSettings = [
      //     "Closed",
      //     "15",
      //     "20",
      //     "25",
      //     "28",
      //     "30",
      //     "35",
      //     "40",
      //     "50",
      //     "Fully Open",
      //     "CA",
      //     "MA",
      //     "Auto",
      //   ].filter((x) => regime.regimeVents.map((x) => x.ventCode).includes(x));
      // } else {
      //   this.ventSettings = [
      //     "Closed",
      //     "15",
      //     "20",
      //     "25",
      //     "28",
      //     "30",
      //     "35",
      //     "40",
      //     "50",
      //     "Fully Open",
      //     "CA",
      //     "MA",
      //     "Auto",
      //   ];
      // }
      if (this.containerItem.regimeId) {
        let ventSettings = await this.$API.getRegimeVent(this.containerItem.regimeId)
        if (ventSettings.length > 0) {
          this.ventSettings = ventSettings.map(x => x.ventCode)
        } else {
          this.ventSettings = [
            "Closed",
            "5",
            "10",
            "15",
            "20",
            "25",
            "28",
            "30",
            "35",
            "40",
            "50",
            "Fully Open",
            "CA",
            "MA",
            "Auto",
          ]
        }
      } else {
        this.ventSettings = [
          "Closed",
          "5",
          "10",
          "15",
          "20",
          "25",
          "28",
          "30",
          "35",
          "40",
          "50",
          "Fully Open",
          "CA",
          "MA",
          "Auto",
        ]
      }
      if (this.ventSettings.length == 1) {
        this.containerItem.vents = this.ventSettings[0];
      }
      this.regimeCodeModal = false;
    },
    setSelectableParties() {
      let keys = [
        "forwarder",
        "shipper",
        "firstNotifyParty",
        "secondNotifyParty",
      ];
      let result = [];
      for (let i = 0; i < keys.length; i++) {
        if (this.item[keys[i] + "Id"]) {
          result.push(this.item[keys[i]]);
        }
      }
      if (this.item.customerId) {
        let findCustomer = result.find((x) => x.id == this.item.customerId);
        if (!findCustomer) {
          result.unshift(this.item.customer);
        }
      }
      let findCurrentOrg = result.find(
        (x) => x.id == this.$store.state.currentOrg.id
      );
      if (!findCurrentOrg) {
        result.unshift(this.$store.state.currentOrg);
      }
      this.selectableParties = result;
    },
    setTransporter() {
      if (this.item.transportCoordinator) {
        this.item.transportCoordinatorId = this.item.transportCoordinator.id;
      } else {
        this.item.transportCoordinatorId = null;
      }
      if (this.item.transportCoordinatorId == this.$store.state.currentOrg.id) {
        this.item.preAdviseRequired = true;
      }
      this.preadviseKey++;
    },
    updateContractIndex() {
      this.incoTerm.consigneeProfileShippingContracts.forEach(
        async (x, index) => {
          x.index = index;
          if (x.id) {
            await this.$API.updateConsigneeProfileContract({
              id: x.id,
              index: x.index,
            });
          }
        }
      );
    },
    setParties() {
      for (let i = 0; i < this.chargeOptions.length; i++) {
        if (this.chargeOptions[i].value == 'bank') {
          this.chargeOptions[i].friendlyName = this.item.bank ? this.item.bank.name : null
        } else if (this.chargeOptions[i].value == 'shipper') {
          this.chargeOptions[i].friendlyName = this.item.shipper ? this.item.shipper.name : null
        } else if (this.chargeOptions[i].value == 'forwarder') {
          this.chargeOptions[i].friendlyName = this.item.forwarder ? this.item.forwarder.name : null
        } else if (this.chargeOptions[i].value == 'consignee') {
          this.chargeOptions[i].friendlyName = this.item.consignee ? this.item.consignee.name : null
        } else if (this.chargeOptions[i].value == 'buyer') {
          this.chargeOptions[i].friendlyName = this.item.buyer ? this.item.buyer.name : null
        } else if (this.chargeOptions[i].value == 'firstNotify') {
          this.chargeOptions[i].friendlyName = this.item.firstNotify ? this.item.firstNotify.name : null
        } else if (this.chargeOptions[i].value == 'secondNotify') {
          this.chargeOptions[i].friendlyName = this.item.secondNotify ? this.item.secondNotify.name : null
        } else if (this.chargeOptions[i].value == 'courierParty') {
          this.chargeOptions[i].friendlyName = this.item.courierParty ? this.item.courierParty.name : null
        } else if (this.chargeOptions[i].value == 'additionalCourierParty') {
          this.chargeOptions[i].friendlyName = this.item.additionalCourierParty ? this.item.additionalCourierParty.name : null
        } else if (this.chargeOptions[i].value == 'onBehalfShipper') {
          this.chargeOptions[i].friendlyName = this.item.onBehalfShipper ? this.item.onBehalfShipper.name : null
        } else if (this.chargeOptions[i].value == 'onBehalfForwarder') {
          this.chargeOptions[i].friendlyName = this.item.onBehalfForwarder ? this.item.onBehalfForwarder.name : null
        } else if (this.chargeOptions[i].value == 'onBehalfConsignee') {
          this.chargeOptions[i].friendlyName = this.item.onBehalfConsignee ? this.item.onBehalfConsignee.name : null
        } else if (this.chargeOptions[i].value == 'onBehalfBuyer') {
          this.chargeOptions[i].friendlyName = this.item.onBehalfBuyer ? this.item.onBehalfBuyer.name : null
        }else if (this.chargeOptions[i].value == 'customer') {
          this.chargeOptions[i].friendlyName = this.customers[0].organisation ? this.customers[0].organisation.name : null
        } else if(this.chargeOptions[i].value == 'contractParty'){
          if(this.incoTerm && this.incoTerm.contractOwner && this.item[this.incoTerm.contractOwner]){
            this.chargeOptions[i].friendlyName = this.item[this.incoTerm.contractOwner].name
          } else {
            this.chargeOptions[i].friendlyName = "Contract Party"
          }
        }
      }

      // console.log('chargeOptions', this.chargeOptions)
    },
    async saveUCRNumber(){
      this.savingUCR = true;
      let obj = {
        id: this.item.id, 
        ucrNo: this.item.ucrNo
      }
      await this.$API.saveProfileUCR(obj);
      this.savingUCR = false;
      this.$message.success("Successfully updated UCR number!");
    }
  },
};
</script>
<style scoped>
.reverseorder {
  height: 100%;
  bottom: 0;
}

.mention-item {
  padding: 4px 10px;
  border-radius: 4px;
}

.mention-selected {
  background: rgb(192, 250, 153);
}

.infoButton {
  /* position: relative; */
  right: 280px;
}
</style>
